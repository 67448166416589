import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Section1 from "./Section1";
import Section2 from "./Section2";



export default function PrivacyPolicy() {
    return (
        <Root>
            <div className="container p-4 pb-2 pt-3 md-p-5" id="privacy-policy">
                <Section1 />
                <Section2 />
            </div>
        </Root>
    );
}

const Root = styled.section`
   background-color: #fefefe;
  .container{
  width:95%;
  }
  
 `;
