import React from 'react'
import Section2 from './Section2'


export default function UniqueRingDetails(){
  return (
    <div>
       <Section2/>
    </div>
  )
}
    