import React from "react";
import styled from "styled-components";
import Reinvent from "../../Images/new-about-sec4-1.webp";
import Order from "../../Images/new-about-sec4-2.webp";
import Dreams from "../../Images/new-about-sec4-3.webp";

export default function Section4() {
  return (
    <Root>
      <div class="row align-items-stretch ">
        <div class="col-12 col-md-6 order-md-1 p-0">
          <img
            alt="Reinventing jewelry shopping"
            src={Reinvent}
            class="img-fluid object-cover ig "
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center section-padding-md">
          <div class="text-black  mx-5 ">
            <span class="d-block text-xl text-capitalize font-light mb-2">
              Our mission
            </span>
            <h2 class="pt-1.5 pb-3 text-2xl font-medium text-capitalize">
              Reinventing jewelry shopping
            </h2>
            <p class="text-base">
              Customizing your jewelry should be an easy process. All the
              knowledge you need to create the perfect piece is at your
              fingertips! Though most of our products are made to order,
              production is fast – thanks to our incorporation of modern
              technologies at every step of production.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-stretch ">
        <div class="col-12 col-md-6  p-0">
          <img
            alt="Reinventing jewelry shopping"
            src={Order}
            class="img-fluid object-cover  ig "
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center section-padding-md">
          <div class="text-black  mx-5 ">
            <span class="d-block text-xl text-capitalize font-light mb-2">
              Sustainability &amp; Craftsmanship
            </span>
            <h2 class="pt-1.5 pb-3 text-2xl font-medium text-capitalize">
              Made to order, every time
            </h2>
            <p class="text-base">
              At The Precious Earth, we believe that sustainability and
              craftsmanship go hand in hand. Besides using eco-friendly
              materials in every aspect of our business, we strive to reduce our
              waste as much as possible.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-stretch ">
        <div class="col-12 col-md-6 order-md-1 p-0">
          <img
            alt="Reinventing jewelry shopping"
            src={Dreams}
            class="img-fluid object-cover  ig "
          />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center section-padding-md">
          <div class="text-black  mx-5 ">
            <span class="d-block text-xl text-capitalize font-light mb-2">
              Our Vision
            </span>
            <h2 class="pt-1.5 pb-3 text-2xl font-medium text-capitalize">
              Chasing childhood dreams
            </h2>
            <p class="text-base">
              We aspire for The Precious Earth Jewelry to be synonymous with all
              of your monumental occasions. We want to be a place where our
              customers find the best designs and our employees can express
              their creativity and dedicate their work towards making many happy
              moments.
            </p>
            <p>
              We are proof that with hard work and dedication — even in an
              industry that is typically passed down from father to child — you
              can become a world-class leader!
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #f8f9fa;

  .ig {
    width: 100%;
    padding: 0 !important;
  }
  .object-cover {
    object-fit: cover;
  }
  .section-padding {
    padding: 3rem 1rem;
  }
  .section-padding-md {
    padding: 3.5rem 1rem;
  }
  .section-padding-lg {
    padding: 4rem 1.25rem;
  }
  .section-padding-xl {
    padding: 4.5rem 2.25rem;
  }
  .section-padding-2xl {
    padding: 5rem 3rem;
  }
  .font-light {
    font-weight: 300;
  }
  .font-medium {
    font-weight: 500;
  }
  .text-capitalize {
    text-transform: capitalize;
  }

  @media (max-width: 567px) {
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .ig {
      height: 50vh;
    }
  }
`;
