import React from "react";
import styled from "styled-components";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import edubanner from "../../Images/EducationBanner.jpg";
import eduDia from "../../Images/Diamondweqw.jpg";
import diamond from "../../Images/Diamond-2.jpg";

export default function Education() {
  return (
    <Edu>
      <div className="banner_section">
        <img src={edubanner} alt="banner_img" />
      </div>
      <div className="diamond_ring">
        <div className="sec1">
          <img src={diamond} alt="img" />

          <h2>DIAMONDS</h2>
          <p>
            Discover the enchanting world of diamonds, where timeless elegance
            and enduring brilliance await. Uncover the secrets of their origin,
            marvel at their natural beauty, and understand the 4 Cs—that
            determine their quality. Welcome to a realm of unmatched sparkle and
            sophistication.
          </p>
          <span>
            4 C’s | Clarity | Carat | weight | Color | Cut | Shapes | Round |
            Princess | Cushion | Oval | Radiant | Asscher | Marquise | Pear |
            Emerald | Elongated Cushion | Diamond Types | Lab-Grown Diamond |
            Natural Diamond | Anatomy | Sparkle | Symmetry |
          </span>
          <div className="lnk">
            <a href="education">Learn More</a>
            <HiOutlineArrowNarrowRight />
          </div>
        </div>
        <div className="sec1">
          <img src={eduDia} alt="img" />
          <h2>ENGAGEMENT RINGS</h2>
          <p>
            Step into the mesmerizing world of moissanite. Discover the
            brilliance and eco-friendly allure of these extraordinary gems.
            Explore their origins and unique qualities on this captivating
            journey.
          </p>
          <span>
            Ring | Styles | Solitaire | Vintage | Pave | Side stones | Three |
            stone | Hidden halo | Halo | Setting Types | Bezel | Prongs | Metals
            | White Gold | Yellow Gold | Rose Gold | Platinum |
          </span>
          <div className="lnk">
            <a href="education">Learn More</a>
            <HiOutlineArrowNarrowRight />
          </div>
        </div>
      </div>
    </Edu>
  );
}
const Edu = styled.section`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .banner_section {
    img {
      width: 100%;
    }
  }
  .diamond_ring {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    /* margin: 0px 55px; */

    .sec1 {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      border-radius: 20px;
      box-shadow: 1px 3px 6px 1px lightgray;
      margin: 20px;
      img {
        border-top-left-radius: 20px;
        width: 100%;
        height:50%;
        border-top-right-radius: 20px;
      }
      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        color: #000;
        padding: 0px 30px;
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 1.25;
      }
      span {
        font-size: 13px;
        line-height: 1.5;

        color: #999999;
        word-spacing: 5px;
      }
      .lnk {
        display: flex;
        justify-content: space-between;
        padding: 0px 30px;
        margin-bottom: 20px;

        svg {
          width: 50px;
          height: 25px;
        }
      }
      p,
      span {
        padding: 0px 30px;
      }
    }
  }
  @media (max-width: 1024px) {
    .diamond_ring {
      padding: 5px;
    }
  }
`;
// const handleInputChange = (index, event) => {
//   const inputName = event.target.name;
//   let newValue = Number(event.target.value);

//   // Clear any existing timeout
//   if (event.target.timeoutId) {
//     clearTimeout(event.target.timeoutId);
//   }

//   if (event.target.value === "") {
//     // Set a timeout to update the state after 1 second if the field is cleared
//     event.target.timeoutId = setTimeout(() => {
//       switch (inputName) {
//         case "budget":
//           newValue = 1000;
//           setBudget(
//             index === 0
//               ? [Math.min(newValue, budget[1]), budget[1]]
//               : [budget[0], Math.max(newValue, budget[0])]
//           );
//           break;
//         case "caratRange":
//           newValue = 0.1;
//           setCaratRange(
//             index === 0
//               ? [Math.min(newValue, caratRange[1]), caratRange[1]]
//               : [caratRange[0], Math.max(newValue, caratRange[0])]
//           );
//           break;

//         case "width":
//           newValue = 1;
//           setWidth(
//             index === 0
//               ? [Math.min(newValue, width[1]), width[1]]
//               : [width[0], Math.max(newValue, width[0])]
//           );
//           break;
//         case "length":
//           newValue = 1;
//           setLength(
//             index === 0
//               ? [Math.min(newValue, length[1]), length[1]]
//               : [length[0], Math.max(newValue, length[0])]
//           );
//           break;
//         case "depth":
//           newValue = 1;
//           setDepth(
//             index === 0
//               ? [Math.min(newValue, depth[1]), depth[1]]
//               : [depth[0], Math.max(newValue, depth[0])]
//           );
//           break;

//         default:
//           break;
//       }
//     }, 1000); // 1-second delay

//     return; // Exit early to prevent immediate state updates
//   }

//   // Apply immediate changes if input is not empty
//   if (
//     event.target.name === "caratRange" &&
//     caratRange &&
//     caratRange.length >= 2
//   ) {
//     if (index === 0) {
//       setCaratRange([Math.min(newValue, caratRange[1]), caratRange[1]]);
//     } else {
//       setCaratRange([caratRange[0], Math.max(newValue, caratRange[0])]);
//     }
//   } else if (event.target.name === "budget" && budget && budget.length >= 2) {
//     if (index === 0) {
//       setBudget([Math.min(newValue, budget[1]), budget[1]]);
//     } else {
//       setBudget([budget[0], Math.max(newValue, budget[0])]);
//     }
//   } else if (event.target.name === "width" && width && width.length >= 2) {
//     if (index === 0) {
//       setWidth([Math.min(newValue, width[1]), width[1]]);
//     } else {
//       setWidth([width[0], Math.max(newValue, width[0])]);
//     }
//   }
// };