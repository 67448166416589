import React, { useState } from "react";
import styled from "styled-components";
// import Bag from "../Images/new-svgviewer-png-output.png";

import Bag from "../../Images/new-svgviewer-png-output.png";
// import Couple1 from "../../Images/couple1.webp";
import Couple1 from "../../Images/couples1.jpg";
// import Couple_2 from "../../Images/Couple_2.webp";
import Couple_2 from "../../Images/couples2.jpg";

export default function Section1() {
  const [visibleSection, setVisibleSection] = useState(1);

  const handleShowMoreClick = () => {
    setVisibleSection((prev) => prev + 1);
  };

  return (
    <Root>
      <div className="bg">
        <div className="text-center my-5">
          <p className="text-uppercase mb-2">Meet the couple</p>
          <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
        </div>

        <div className="row align-items-stretch">
          <div className="col-md-6 p-0 position-relative">
            <div className="image-aspect-container">
              <img
                src={Couple1}
                alt="Love story desktop"
                className="img-fluid"
              />
              <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                <div className="custom-button-container py-3 ps-3 pe-0">
                  <button
                    type="button"
                    className="expandable-button p-2 rounded-5 d-flex align-items-center"
                  >
                    <div>
                      <img src={Bag} alt="Bag icon" className="me-2 fill" />
                    </div>
                    <span className="expandable-button-text">
                      Shop the Look
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
            <div className="container  ima_content ">
              <h2 className="text-uppercase mb-3">Love story</h2>
              <p>
                A few months into our relationship, we took a trip together from
                Utah for valentines and his birthday. Fast forward to November
                2022, we had another family trip planned there.
              </p>
              <p>
                Little did I know, Jay had bought the ring, and planned to get
                in one knee in our very special spot in Pismo. I wanted
                something private, so it was just the two of us and his phone
                propped up to record- a normal thing we did to get pictures and
                memories together. And this was, and will forever be, our
                favorite memory caught on camera together. Of course, I said
                yes! And afterwards we took some photos on my camera to document
                the very special night. We couldn’t have imagined a more perfect
                night to promise a lifetime together.
              </p>
              <a href="##" className="btn custom-btn mt-4">
                Shop the Ring
              </a>
            </div>
          </div>{" "}
          <div className="row align-items-stretch">
            <div className="col-md-6 order-md-2 p-0 position-relative">
              <div className="image-aspect-container">
                <img
                  src={Couple_2}
                  alt="The Preciuous Earth experience review desktop"
                  className="img-fluid"
                />
                <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                  <div className="custom-button-container py-3 pe-2">
                    <button
                      type="button"
                      className="expandable-button p-2 rounded-5 d-flex align-items-center"
                    >
                      <div>
                        <img src={Bag} alt="Bag icon" className="me-2" />
                      </div>
                      <span className="expandable-button-text">
                        Shop the Look
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
              <div className="container  ima_content ">
                <h4 className="text-uppercase mb-3">
                  The Preciuous Earth experience review
                </h4>
                <p>
                  ״The Preciuous Earth is a fantastic company to work with, my
                  Fiancé said the ordering process was easy and the ring was
                  delivered in a couple of weeks with ease. The ring itself is
                  stunning! When I found The Preciuous Earth, I wanted a
                  delicate and dainty ring I could one day see myself passing
                  down to my kids. When we saw the Twig setting with a Radiant
                  cut, I immediately fell in love with it.״
                </p>
                <a href="##" className="btn custom-btn mt-4 rounded-5">
                  Shop the Ring
                </a>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        {visibleSection >= 2 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content ">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content ">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 3 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>


            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container ima_content">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 4 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content ">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 5 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content ">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container px-5">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 6 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content ">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 7 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container ima_content">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 8 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 9 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {visibleSection >= 10 && (
          <div>
            <div className="text-center my-5">
              <p className="text-uppercase mb-2">Meet the couple</p>
              <h2 className="text-uppercase mb-0">Sarah & Jalen Bailey</h2>
            </div>

            <div className="row align-items-stretch">
              <div className="col-md-6 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple1}
                    alt="Love story desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 ps-3 pe-0">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2 fill" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg p-4">
                <div className="container  ima_content">
                  <h2 className="text-uppercase mb-3">Love story</h2>
                  <p>
                    A few months into our relationship, we took a trip together
                    from Utah for valentines and his birthday. Fast forward to
                    November 2022, we had another family trip planned there.
                  </p>
                  <p>
                    Little did I know, Jay had bought the ring, and planned to
                    get in one knee in our very special spot in Pismo. I wanted
                    something private, so it was just the two of us and his
                    phone propped up to record- a normal thing we did to get
                    pictures and memories together. And this was, and will
                    forever be, our favorite memory caught on camera together.
                    Of course, I said yes! And afterwards we took some photos on
                    my camera to document the very special night. We couldn’t
                    have imagined a more perfect night to promise a lifetime
                    together.
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-6 order-md-2 p-0 position-relative">
                <div className="image-aspect-container">
                  <img
                    src={Couple_2}
                    alt="The Preciuous Earth experience review desktop"
                    className="img-fluid"
                  />
                  <div className="position-absolute bottom-0 start-0 mx-4 my-2">
                    <div className="custom-button-container py-3 pe-2">
                      <button
                        type="button"
                        className="expandable-button p-2 rounded-5 d-flex align-items-center"
                      >
                        <div>
                          <img src={Bag} alt="Bag icon" className="me-2" />
                        </div>
                        <span className="expandable-button-text">
                          Shop the Look
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center custom-bg-light p-4">
                <div className="container  ima_content ">
                  <h4 className="text-uppercase mb-3">
                    The Preciuous Earth experience review
                  </h4>
                  <p>
                    ״The Preciuous Earth is a fantastic company to work with, my
                    Fiancé said the ordering process was easy and the ring was
                    delivered in a couple of weeks with ease. The ring itself is
                    stunning! When I found The Preciuous Earth, I wanted a
                    delicate and dainty ring I could one day see myself passing
                    down to my kids. When we saw the Twig setting with a Radiant
                    cut, I immediately fell in love with it.״
                  </p>
                  <a href="##" className="btn custom-btn mt-4 rounded-5">
                    Shop the Ring
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="text-center py-5">
          {visibleSection < 10 && (
            <button
              type="button"
              className="btn custom-btn mt-4 rounded-5"
              onClick={handleShowMoreClick}
            >
              Show More Stories
            </button>
          )}
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`

.ima_content{
    padding: 0 3rem;
}
  .bg {
    background-color: #f8f9fa;
  }

  .custom-bg {
    background-color: #fff;
  }
  .custom-bg-light {
    background-color: #ffecd6;
  }
  .custom-btn {
    background-color: #000;
    color: #fff;
  }
  .custom-btn:hover {
    background-color: #333;
  }
  .image-aspect-container {
    position: relative;
    overflow: hidden;
  }
  .image-aspect-container img {
    width: 100%;
    height: auto;
  }
  .custom-button-container {
    position: relative;
    padding: 0;
    overflow: hidden;
  }
  .expandable-button {
    background-color: #000;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    transition: width 0.3s ease;
    width: 40px; /* Initial width, just enough to fit the icon */
    position: relative;
  }
  .expandable-button:hover {
    width: 175px; /* Expanded width to reveal text */
  }
  .expandable-button img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    transition: margin-right 0.3s ease;
  }
  .expandable-button:hover img {
    margin-right: 20px; /* Adjust margin to create space for text */
  }
  .expandable-button-text {
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
  }
  .expandable-button:hover .expandable-button-text {
    opacity: 1;
  }
  .btn {
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
  }
  img {
    fill: #fff;
  }

  @media (min-width: 567px) and (max-width: 992px) {

    .crpb-title {
      font-size: 2rem;
    }

 .ima_content {
    padding: 0 1rem;
}

.image-aspect-container img {
    width: 100%;
    height: 600px;
}
  }
`;
