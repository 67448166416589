import React from "react";
import styled from "styled-components";
import pro from "../Images/stock.webp";
 

export const NoProduct = () => {
  return (
    <Root>
      <div className="head_div">
        <img src={pro} alt="no_product"/>
        <h3>None of the Products Were Found</h3>
        {/* <p>Change the results of your output in the filter.</p> */}
      </div>
    </Root>
  );
};

const Root = styled.section`

  .head_div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    height: 200px;
    gap: 15px;
    width: 100vw;
    img {
      width: 100px!important;
      height: 100px;
    }
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
      margin-bottom: 0;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      color: #666666;
    }
  }


  @media (max-width: 567px){
    .head_div {
  h3 {
        font-size:14px;
        text-align:center;
    }
    p {
      font-size:15px;
    } 
}

.head_div img {
    width:100px!important;
    height:94px!important;
}

.head_div {
    height:unset; 
    gap:unset; 
}

}
`;
