import React from "react";
import styled from "styled-components";

export default function Section5() {
    return (
        <Root>
            <div id="professional-appraisal" className="d-flex flex-column gap-8 pb-5 pt-5">
                <h2>Professional Appraisal</h2>
                <div className="d-flex flex-column gap-8">
                    <p>A jewelry appraisal is a document that verifies the approximate retail value of an item. The main benefit to having an appraisal is that you get a fair price when insuring or selling your item(s). We offer free appraisals on all our purchases.</p>
                </div>
                <div className="d-flex flex-column gap-8">
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">What does an appraisal include?</h3>
                        <p>An appraisal includes the cut, color, clarity, and carat weight of a diamond or a gem stone. In some cases, the carat weight can be replaced with metric dimensions of a stone. In addition to the diamond’s qualities, an appraisal includes the craftsmanship, dimensions, and weight of the entire piece.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">Why do I need an appraisal?</h3>
                        <p>Our <a href="#">lifetime warranty</a> covers any damage that happens due to manufacturing faults. But if you want to be protected no matter what happens, you may want to get insured. An appraisal guarantees your insurer will replace your item with one of equal value - or a cash equivalent - should anything happen. Without an appraisal, you may not get a fair replacement for your item while making a claim.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">Why appraise the whole item and not just the stone?</h3>
                        <p>The more information your appraisal has, the likelier you are to get fair value for your item. Unless the metal part of your jewelry piece has no value - e.g., it’s made of copper - it’s best to appraise the whole purchase, not just the center stone.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">How can I get my jewelry piece appraised?</h3>
                        <p>We’ll send in your free appraisal once you’ve completed your purchase. If you want to get an appraisal on an item you already own, <a href="#">let us know here</a>.</p>
                    </div>
                </div>
            </div>
        </Root>
    )
}

const Root = styled.section`
  font-family: ProximaNova, sans-serif;
  background-color: #f8f9fa;

  h2 {
    font-size: 36px;
    line-height: 1.375;
    font-weight: 500;
    color: black;
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: black;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  p {
    color: black;
    font-size: 14px;
    font-weight:500;
  }

  .border-b {
    border-bottom: 1px solid #ddd;
  }

  .border-borders {
    border-color: #ddd;
  }
     p,h2,h3{
     margin:0;
    }
  .gap-8{
    gap:2rem;
    }
`;
