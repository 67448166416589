import React from "react";
import styled from "styled-components";
import Email from "../../Images/new-email-icon.svg";
import Call from "../../Images/new-call-icon.svg";
import Talk from "../../Images/new-talk-icon.svg";
import Test from "../../Images/girl.webp";

export default function Section1() {
    return (
        <Root>
            <div className="container my-5">

                <div className="container my-5">
                    <div className="text-center my-4 my-md-5">
                        <h2 className="font-weight-normal text-4xl text-md-5xl tracking-wide mb-4 ">Need Assistance?</h2>
                        <p className="  text-lg mb-4">
                            If you experience any problems with our website or your order placed on this website, for example, editing the details of an order, including shipping, sizing, addresses, or cancellation, please feel free to send us a message by clicking the button below. You can also contact us by email at <a href="mailto:customerservice@xyz.com">customerservice@xyz.com</a>.
                        </p>
                    </div>
                    <div className="row my-5">
                        <div className="col-12 col-md-4 d-flex flex-column align-items-stretch mb-4">
                            <div className="card border rounded-4 p-4 bg-white text-center d-flex flex-column justify-content-between h-100">
                                <div className="mb-3">
                                    <img alt="Email" className="img-fluide rounded-circle" src={Email} />
                                </div>
                                <h4 className="text-2xl font-normal text-dark mb-2">Email Us</h4>
                                <p className="text-base text-muted mb-3">Email Our team</p>
                                <a className="btn btn-custom w-100" href="##">Contact Us</a>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column align-items-stretch mb-4">
                            <div className="card border rounded-4 p-4 bg-white text-center d-flex flex-column justify-content-between h-100">
                                <div className="mb-3">
                                    <img src={Call} className="img-fluide rounded-circle" alt="Call" />
                                </div>
                                <h4 className="text-2xl font-normal text-dark mb-2">Support</h4>
                                <p className="text-base text-muted mb-3">+1 (234) 567-8901</p>
                                <a className="btn btn-custom w-100" href="tel:+18883460787">Give us a Call</a>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex flex-column align-items-stretch mb-4">
                            <div className="card border rounded-4 p-4 bg-white text-center d-flex flex-column justify-content-between h-100">
                                <div className="mb-3">
                                    <img src={Talk} className="img-fluide rounded-circle" alt="Live Chat" />
                                </div>
                                <h4 className="text-2xl font-normal text-dark mb-2">Live Chat</h4>
                                <p className="text-base text-muted mb-3">Start a conversation</p>
                                <a href="#chat" className="btn btn-custom w-100">Open Live Chat</a>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card border-0 rounded-4 overflow-hidden">
                    <div className="row g-0 border rounded">
                        <div className="col-md-6">
                            <img alt="Virtual Appointment" className="img-fluid rounded-start" src={Test} />
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center p-4 p-md-5">
                            <h2 className="text-2xl text-center text-md-start mb-3">Virtual Appointment</h2>
                            <p className="text-muted text-center text-md-start mb-4">See The Precious Earth's jewelry up close with a personal appointment. Explore engagement rings, diamonds, and fine jewelry in person through your device.</p>
                            <button className="btn btn-custom w-100">Book Appointment</button>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    )
}

const Root = styled.section`
  .btn {
    border-radius: 25px;
  }

  .ig {
    width: 49px; 
    height: 49px;
  }

  .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow */
  }

  .btn-custom {
    background-color: white;
    color: black;
    border: 2px solid black;
    transition: background-color 0.3s, color 0.3s;
  }

  .btn-custom:hover {
    background-color: black;
    color: white;
    border: 2px solid black; /* Maintain border color on hover */
  }

  .text-4xl {
    font-size: 2.5rem; /* 40px */
  }

  .text-md-5xl {
    font-size: 3rem; /* 48px */
  }

  .font-weight-normal {
    font-weight: 400;
  }

  .tracking-wide {
    letter-spacing: 0.05em;
  }

  .mb-4 {
    margin-bottom: 1.5rem; /* 24px */
  }

  .mb-md-5 {
    margin-bottom: 3rem; /* 48px */
  }

  .img-fluide {
    width:50px;
  height: auto;
  }

  .overflow-hidden {
    overflow: hidden; /* Ensure content does not overflow */
  }

  /* Custom Media Queries */
  @media (max-width: 767.98px) {
    .text-4xl {
      font-size: 2rem; /* Adjust for smaller screens */
    }

    .text-md-5xl {
      font-size: 2rem; /* Adjust for smaller screens */
    }

    .btn-custom {
      padding: 0.5rem 1rem; /* Adjust padding for smaller screens */
    }
  }

  @media (min-width: 768px) {
    .text-md-5xl {
      font-size: 2rem; /* 48px */
    }
  }
  
  @media (min-width: 992px) {
    .text-4xl {
      font-size: 2.5rem; /* Ensure consistent size on medium screens */
    }
    
    .btn-custom {
      padding: 0.75rem 1.5rem; /* Adjust padding for medium screens */
    }
  }

  @media (min-width: 1200px) {
    .text-md-5xl {
      font-size: 2rem; /* Ensure larger font size on large screens */
    }
  }

  @media (max-width: 567px){
    .btn-custom {
 font-size:12px;
}
  }

  @media (min-width: 567px) and (max-width: 992px){

    .btn-custom {
 font-size:9px;
}

.container {
    width:100%;
}

.p-md-5 {
    padding: 1rem!important;
}


  }
`;
