import React from "react";
import styled from "styled-components";

export default function Section3() {
    return (
        <Root>
            <div id="unique-shopping-experience" className="d-flex flex-column gap-8 pb-5 pt-5 border-b border-borders">
                <h2>Unique Shopping Experience</h2>
                <div>
                    <p>The Precious Earth is all about making your jewelry purchases easy and fun. Here’s what your shopping experience with us will look like.</p>
                </div>
                <div className="d-flex flex-column gap-8">
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">A 30-Day Money-Back Guarantee</h3>
                        <p>Received an item you didn’t like? No problem. We’ll help you ship it back to us and process a refund after reviewing the item. At The Precious Earth we want to do our best to ensure you’ll only ever pay for the items you fall in love with. You can learn more by visiting our Return policy.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">Each Purchase Comes with Free Extras</h3>
                        <p>We include 2 extra items with every purchase. First, an appraisal that you can show to an insurer (or jeweler) to verify the ring’s worth. Second, a state-of-the-art box to surprise and delight yourself or someone special.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">A Lifetime Warranty</h3>
                        <p>We will care for your jewelry for a lifetime. You can learn more about visiting our lifetime warranty page. Included services range from rhodium plating to free prong re-tightening.</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">Free Shipping & Free Shipping Insurance</h3>
                        <p>We use world-class companies like FedEx and DHL to process and ship your purchases, so any item you buy is safe in transit on its way to you. We also insure your items free of charge to give you extra peace of mind. You can learn more about our free shipping policy .</p>
                    </div>
                    <div className="d-flex flex-column gap-8">
                        <h3 className="fs-6 font-weight-semibold">Make Any Jewelry You Want</h3>
                        <p>Use our custom jewelry tools to make your own jewelry using tens of thousands of possible combinations. Choose your own metal, stone shape and size, setting, and more – and we’ll make the jewelry you want for you.</p>
                    </div>
                </div>
            </div>
        </Root>
    )
}

const Root = styled.section`
  font-family: ProximaNova, sans-serif;
  background-color: #f8f9fa;

  h2 {
    font-size: 36px;
    line-height: 1.375;
    font-weight: 500;
    color: black;
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: black;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  p {
    color: black;
    font-size: 14px;
    font-weight:500;
  }

  .border-b {
    border-bottom: 1px solid #ddd;
  }

  .border-borders {
    border-color: #ddd;
  }
      .gap-8{
    gap:2rem;
    }
     p,h2,h3{
     margin:0;
    }

`;
