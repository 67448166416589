import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { EXCHANGE_URLS } from "../URLS"; // Assuming you have a file where this constant is defined
import { toast } from "react-toastify";

export default function Section2() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${EXCHANGE_URLS}/contatc-us`, {
        name,
        email,
        message,
      });

      if (response.status === 201) {
        toast.success("Thank you for contacting us!");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setResponseMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {}
  };

  return (
    <Root>
      <div className="container py-2">
        <div className="container py-5 px-3 text-center">
          <h4 className="fs-4 fw-medium text-uppercase text-dark mb-4">
            LEAVE A MESSAGE
          </h4>
          <p className="fs-5 mb-2 text-dark">
            Fill out our Contact Form or email us and we will get back to you
            within 24 hours.
          </p>
          <div className="fs-6 text-custom-gray">
            <p>
              We are available Sunday through Thursday, 10am to 5pm ET,
              excluding holidays.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 col-lg-10 container">
            <form onSubmit={handleSubmit} id="leave-message">
              <div className="row mb-4">
                <div className="col-md-6 mb-3 mb-md-0">
                  <input
                    className="form-control rounded-3 custom-border custom-text-gray"
                    type="text"
                    name="name"
                    placeholder="Your Name*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    className="form-control rounded-3 custom-border custom-text-gray"
                    type="email"
                    name="email"
                    placeholder="Your Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="mb-4">
                <textarea
                  className="form-control rounded-3 custom-border custom-text-gray custom-textarea"
                  name="message"
                  cols="30"
                  rows="7"
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>

              <div className="text-center">
                <input
                  className="btn custom-button px-4 py-2 rounded-pill"
                  type="submit"
                  value="Send Message"
                />
              </div>
              {responseMessage && (
                <p className="response-message">{responseMessage}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: white;
  .row {
    max-width: 90%;
    margin: 0 auto;
  }

  .custom-text-gray {
    color: #262626;
  }

  .custom-border {
    border-color: #d1d1d1;
  }

  .custom-button {
    background-color: #000;
    color: #fff;
    border: 2px solid #000;
  }

  .custom-button:hover {
    background-color: #fff;
    color: #000;
  }

  .custom-textarea {
    height: 220px;
  }

  @media (max-width: 768px) {
    .custom-textarea {
      height: 165px;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .py-5 {
      padding-top: 1rem !important;
      padding-bottom: 3rem !important;
    }
  }
`;
