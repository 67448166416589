import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BsQuestionCircleFill } from "react-icons/bs";
import Slider from "@mui/material/Slider";
import Section4 from "./Section4";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { useDispatch } from "react-redux";
import { IoFilterOutline } from "react-icons/io5";
import {
  setDiamondIds,
  setDiamondType,
  setSelectedShapeImage,
} from "../../redux/users/action";
import ROUND from "../../Images/round-removebg-preview.png";
import EMERALD from "../../Images/emerald-removebg-preview.png";
import HEART from "../../Images/heart-removebg-preview.png";
import MARQUISE from "../../Images/Marquise-removebg-preview.png";
import OVAL from "../../Images/oval-removebg-preview.png";
import PEAR from "../../Images/Pear-removebg-preview.png";
import PRINCESS from "../../Images/Princess-removebg-preview.png";
import RADIANT from "../../Images/Radiant-removebg-preview.png";
import CUSHION from "../../Images/cushionremovebg.png";
import ASSCHER from "../../Images/ECusion-removebg-preview.png";
import { useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { EXCHANGE_URLS } from "../URLS";
import { useLoading } from "../LoadingContext";
import { debounce } from "@mui/material";

const shapesList = [
  { name: "ROUND", imgUrl: ROUND },
  { name: "PRINCESS", imgUrl: PRINCESS },
  { name: "OVAL", imgUrl: OVAL },
  { name: "EMERALD", imgUrl: EMERALD },
  { name: "PEAR", imgUrl: PEAR },
  { name: "HEART", imgUrl: HEART },
  { name: "MARQUISE", imgUrl: MARQUISE },
  { name: "CUSHION", imgUrl: CUSHION },
  { name: "ASSCHER", imgUrl: ASSCHER },
  { name: "RADIANT", imgUrl: RADIANT },
];

const colorOptions = ["D", "E", "F", "G", "H", "I", "J"];
const clarityOptions = [
  "FL",
  "IF",
  "VVS1",
  "VVS2",
  "VS1",
  "VS2",
  "I2",
  "I3",
  "SI2",
  "SI1",
];
const cutOptions = ["GD", "VG", "EX"];
const polishOptions = ["GD", "VG", "EX"];
const symmetryOptions = ["GD", "VG", "EX"];

export default function Section2() {
  const [selectedShapes, setSelectedShapes] = useState(["ROUND"]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedClarity, setSelectedClarity] = useState([]);
  const [caratRange, setCaratRange] = useState([0.1, 1]);
  const minCarat = caratRange[0] || 0.1;
  const maxCarat = caratRange[1] || 1;
  const [selectedCut, setSelectedCut] = useState([]);
  const [budget, setBudget] = useState([0, 1100000]); // Example initial values
  const minPrice = budget?.[0] || 1000;
  const maxPrice = budget?.[1] || 100000000;

  const [width, setWidth] = useState([1.0, 10.5]);
  const minWidth = width?.[0] || 1.0;
  const maxWidth = width?.[1] || 10.5;

  const [length, setLength] = useState([1.0, 10.5]);
  const minLength = length?.[0] || 1.0;
  const maxLength = length?.[1] || 10.5;

  const [depth, setDepth] = useState([1.0, 10.5]);
  const minDepth = depth?.[0] || 1.0;
  const maxDepth = depth?.[1] || 10.5;

  const [selectedCertificate, setSelectedCertificate] = useState({
    1: false,
    2: false,
  });

  const [selectedPolish, setSelectedPolish] = useState([]);
  const [selectedSymmetry, setSelectedSymmetry] = useState([]);
  const [value, setValue] = useState([]);
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const typelabgrown = state ? state.labgrownValue : false;

  const diamondApi = async (params) => {
    try {
      const query = new URLSearchParams();
  
      if (params.selectedShapes?.length) {
        query.append("shapes", params.selectedShapes.join(","));
      }
      if (params.typelabgrown !== undefined && params.typelabgrown !== null) {
        query.append("typelabgrown", params.typelabgrown);
      }
      if (params.selectedColors?.length) {
        query.append("color", params.selectedColors.join(","));
      }
      if (params.selectedClarity?.length) {
        query.append("clarity", params.selectedClarity.join(","));
      }
      if (params.selectedCut?.length) {
        query.append("cut", params.selectedCut.join(","));
      }
      if (params.selectedPolish?.length) {
        query.append("polish", params.selectedPolish.join(","));
      }
      if (params.selectedSymmetry?.length) {
        query.append("symmetry", params.selectedSymmetry.join(","));
      }
      if (params.caratRange?.[0] !== undefined && params.caratRange?.[0] !== null) {
        query.append("minCarat", params.caratRange[0]);
      }
      if (params.caratRange?.[1] !== undefined && params.caratRange?.[1] !== null) {
        query.append("maxCarat", params.caratRange[1]);
      }
      if (params.budget?.[0] !== undefined && params.budget?.[0] !== null) {
        query.append("minPrice", params.budget[0]);
      }
      if (params.budget?.[1] !== undefined && params.budget?.[1] !== null) {
        query.append("maxPrice", params.budget[1]);
      }
      if (params.width?.[0] !== undefined && params.width?.[0] !== null) {
        query.append("minWidth", params.width[0]);
      }
      if (params.width?.[1] !== undefined && params.width?.[1] !== null) {
        query.append("maxWidth", params.width[1]);
      }
      if (params.length?.[0] !== undefined && params.length?.[0] !== null) {
        query.append("minLength", params.length[0]);
      }
      if (params.length?.[1] !== undefined && params.length?.[1] !== null) {
        query.append("maxLength", params.length[1]);
      }
      if (params.depth?.[0] !== undefined && params.depth?.[0] !== null) {
        query.append("minDepth", params.depth[0]);
      }
      if (params.depth?.[1] !== undefined && params.depth?.[1] !== null) {
        query.append("maxDepth", params.depth[1]);
      }
  
      const queryString = query.toString();
      setLoading(true);
      
      const resp = await axios.get(`${EXCHANGE_URLS}/nivodafilter?${queryString}`);
      
      if (resp?.status === 200) {
        setValue(resp?.data?.items);
        const diamondIds = resp?.data?.items?.map((item) => item);
        dispatch(setDiamondIds(diamondIds));
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
    }
  };
  
  const debouncedDiamondApi = useCallback(
    debounce((params) => {
      diamondApi(params);
    }, 500),
    []
  );

  useEffect(() => {
    if (typelabgrown) {
      dispatch(setDiamondType(typelabgrown));
    }

    const params = {
      typelabgrown,
      selectedShapes,
      selectedColors,
      selectedClarity,
      selectedCut,
      selectedPolish,
      selectedSymmetry,
      caratRange,
      budget,
      minCarat,
      maxCarat,
      minPrice,
      maxPrice,
      minWidth,
      maxWidth,
      maxLength,
      minLength,
      maxDepth,
      minDepth,
      depth,
      length,
      width,
    };

    debouncedDiamondApi(params);
  }, [
    selectedShapes,
    selectedColors,
    selectedClarity,
    selectedCut,
    selectedPolish,
    selectedSymmetry,
    typelabgrown,
    caratRange,
    budget,
    width,
    length,
    depth,
  ]);

  const handleShapeClick = (shapeName, shapeImageUrl) => {
    dispatch(setSelectedShapeImage(shapeImageUrl));
    setSelectedShapes([shapeName]); // Set the selected shape
  };

  const handleColorClick = (color) => {
    setSelectedColors((prevColors) =>
      prevColors.includes(color)
        ? prevColors.filter((c) => c !== color)
        : [...prevColors, color]
    );
  };

  const handleButtonClarity = (clarity) => {
    setSelectedClarity((prevClarity) =>
      prevClarity.includes(clarity)
        ? prevClarity.filter((c) => c !== clarity)
        : [...prevClarity, clarity]
    );
  };

  const handleButtonCut = (cut) => {
    setSelectedCut((prevCut) =>
      prevCut.includes(cut)
        ? prevCut.filter((c) => c !== cut)
        : [...prevCut, cut]
    );
  };

  const handleButtonPolish = (polish) => {
    setSelectedPolish((prevPolish) =>
      prevPolish.includes(polish)
        ? prevPolish.filter((p) => p !== polish)
        : [...prevPolish, polish]
    );
  };

  const handleButtonSymmetry = (symmetry) => {
    setSelectedSymmetry((prevSymmetry) =>
      prevSymmetry.includes(symmetry)
        ? prevSymmetry.filter((s) => s !== symmetry)
        : [...prevSymmetry, symmetry]
    );
  };

  const handleButtonCertificate = (index) => {
    setSelectedCertificate((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

 
  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const handleChangeCarat = (e, index) => {
    const newCarat = [...caratRange];
    newCarat[index] = parseFloat(e.target.value);
    setCaratRange(newCarat);
  };

  const increaseMinimum = () => {
    setCaratRange([caratRange[0] + 0.1, caratRange[1]]);
  };

  const decreaseMinimum = () => {
    if (caratRange[0] > 0.1) {
      setCaratRange([caratRange[0] - 0.1, caratRange[1]]);
    }
  };

  const increaseMaximum = () => {
    setCaratRange([caratRange[0], caratRange[1] + 0.1]);
  };

  const decreaseMaximum = () => {
    if (caratRange[1] > caratRange[0]) {
      setCaratRange([caratRange[0], caratRange[1] - 0.1]);
    }
  };
  const handleInputChanges = (index, e) => {
    const newBudget = [...budget];
    newBudget[index] = parseInt(e.target.value, 10);
    setBudget(newBudget);
  };

  const increaseBudMinimum = () => {
    setBudget([budget[0] + 1, budget[1]]);
  };

  const decreaseBudMinimum = () => {
    if (budget[0] > 0) {
      setBudget([budget[0] - 1, budget[1]]);
    }
  };

  const increaseBudMaximum = () => {
    setBudget([budget[0], budget[1] + 1]);
  };

  const decreaseBudMaximum = () => {
    if (budget[1] > budget[0]) {
      setBudget([budget[0], budget[1] - 1]);
    }
  };
  const handleLength = (index, e) => {
    const newLength = [...length];
    newLength[index] = parseInt(e.target.value, 10);
    setLength(newLength);
  };

  const increaseLemgthMIn = () => {
    setLength([length[0] + 1, length[1]]);
  };

  const decreaseLengthMin = () => {
    if (length[0] > 0) {
      setLength([length[0] - 1, length[1]]);
    }
  };

  const increaseLengthMax = () => {
    setLength([length[0], length[1] + 1]);
  };

  const decreaseLengthMax = () => {
    if (length[1] > length[0]) {
      setLength([length[0], length[1] - 1]);
    }
  };

  const handlewidth = (index, e) => {
    const newWidthss = [...width];
    newWidthss[index] = parseInt(e.target.value, 10);
    setWidth(newWidthss);
  };

  const increaseWidthMinimum = () => {
    setWidth([width[0] + 1, width[1]]);
  };

  const decreaseWidthMinimum = () => {
    if (width[0] > 0) {
      setWidth([width[0] - 1, width[1]]);
    }
  };

  const increaseWidthMaximum = () => {
    setWidth([width[0], width[1] + 1]);
  };

  const decreaseWidthMaximum = () => {
    if (width[1] > width[0]) {
      setWidth([width[0], width[1] - 1]);
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  

  const handleDepth = (index, e) => {
    const newDepthss = [...depth];
    newDepthss[index] = parseInt(e.target.value, 10);
    setDepth(newDepthss);
  };

  const increaseDepthMinimum = () => {
    setDepth([depth[0] + 1, depth[1]]);
  };

  const decreaseDepthMinimum = () => {
    if (depth[0] > 0) {
      setDepth([depth[0] - 1, depth[1]]);
    }
  };

  const increaseDepthMaximum = () => {
    setDepth([depth[0], depth[1] + 1]);
  };

  const decreaseDepthMaximum = () => {
    if (depth[1] > depth[0]) {
      setDepth([depth[0], depth[1] - 1]);
    }
  };

  const drawerContent = (
    <>
      <div className="ring_types mt-4">
        {shapesList.map((shape) => (
          <button
            key={shape.name}
            className={`btn_shapes ${
              selectedShapes.includes(shape.name) ? "selected" : ""
            }`}
            onClick={() => handleShapeClick(shape.name, shape.imgUrl)}
          >
            <img src={shape.imgUrl} alt={shape.name} />
            {shape.name}
          </button>
        ))}
      </div>
      <div className="section3">
        <div className="variation">
          <div className="var_kind">
            <div className="head_icon">
              <h5>Color</h5>
              <BsQuestionCircleFill />
            </div>
            <section>
              {colorOptions.map((color) => (
                <button
                  key={color}
                  className={`btn_icons ${
                    selectedColors.includes(color) ? "selected" : ""
                  }`}
                  onClick={() => handleColorClick(color)}
                >
                  {color}
                </button>
              ))}
            </section>
          </div>
          <div className="var_kind">
            <div className="head_icon">
              <h5>Clarity</h5>
              <BsQuestionCircleFill />
            </div>
            <section>
              {clarityOptions.map((clarity) => (
                <button
                  key={clarity}
                  className={`btn_icons ${
                    selectedClarity.includes(clarity) ? "selected" : ""
                  }`}
                  onClick={() => handleButtonClarity(clarity)}
                >
                  {clarity}
                </button>
              ))}
            </section>
          </div>
          <div className="var_kind">
            <div className="head_icon">
              <h5>Cut</h5>
              <BsQuestionCircleFill />
            </div>
            <section>
              {cutOptions.map((cut) => (
                <button
                  key={cut}
                  className={`btn_icons ${
                    selectedCut.includes(cut) ? "selected" : ""
                  }`}
                  onClick={() => handleButtonCut(cut)}
                >
                  {cut}
                </button>
              ))}
            </section>
          </div>
        </div>
        <div className="carat_budget_certificate">
          <div className="carat_div">
            <h5>Carat</h5>
            <Slider
              value={caratRange}
              onChange={handleChangeCarat}
              valueLabelDisplay="auto"
              min={0.1}
              max={1}
              step={0.1}
            />
            <div className="carat_value_div">
              <div className="carat_min_max_div">
                <div className="value">
                  <input
                    type="number"
                    value={caratRange[0]}
                    onChange={(e) => {
                      // handleInputChange(0, e);
                      handleChangeCarat(e, 0);
                    }}
                  />
                  <h6>Minimum</h6>
                </div>
                <div className="carat_btn_div">
                  <button onClick={increaseMinimum}>
                    <IoIosArrowUp />
                  </button>
                  <button onClick={decreaseMinimum}>
                    <IoIosArrowDown />
                  </button>
                </div>
              </div>
              <hr />
              <div className="carat_min_max_div">
                <div className="value">
                  <input
                    type="number"
                    value={caratRange[1]}
                    onChange={(e) => {
                      handleChangeCarat(e, 1);
                    }}
                  />
                  <h6>Maximum</h6>
                </div>
                <div className="carat_btn_div">
                  <button onClick={increaseMaximum}>
                    <IoIosArrowUp />
                  </button>
                  <button onClick={decreaseMaximum}>
                    <IoIosArrowDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="budget_div">
            <h5>Budget</h5>
            <div className="budget_value_div">
              <div className="min_max_div">
                <div className="value_div">
                  <input
                    type="number"
                    value={budget[0]}
                    onChange={(e) => handleInputChanges(0, e)}
                  />
                  <h6>Minimum</h6>
                  <p>{formatNumber(budget[0])} GBP</p>
                </div>
                <div className="btn_divv">
                  <button onClick={increaseBudMinimum}>
                    <IoIosArrowUp />
                  </button>
                  <button onClick={decreaseBudMinimum}>
                    <IoIosArrowDown />
                  </button>
                </div>
              </div>
              <hr />
              <div className="min_max_div">
                <div className="value_div">
                  <input
                    type="number"
                    value={budget[1]}
                    onChange={(e) => handleInputChanges(1, e)}
                  />
                  <h6>Maximum</h6>
                  <p>{formatNumber(budget[1])} GBP</p>
                </div>
                <div className="btn_divv">
                  <button onClick={increaseBudMaximum}>
                    <IoIosArrowUp />
                  </button>
                  <button onClick={decreaseBudMaximum}>
                    <IoIosArrowDown />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carat_budget_certificate">
          <div className="certificate_div">
            <h5> Lab Certificate</h5>
            <div className="btn">
              <button
                className={selectedCertificate[1] ? "selected" : ""}
                onClick={() => handleButtonCertificate(1)}
              >
                IGI
              </button>
              <button
                className={selectedCertificate[2] ? "selected" : ""}
                onClick={() => handleButtonCertificate(2)}
              >
                GIA
              </button>
              <button
                className={selectedCertificate[3] ? "selected" : ""}
                onClick={() => handleButtonCertificate(3)}
              >
                HRD
              </button>
            </div>
          </div>
        </div>

        <div className="advance_quality">
          <Accordion>
            <AccordionSummary
              expandIcon={<FaPlus />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography className="quality_content">
                Advanced Quality Specs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div className="symmetry_polish_div">
                  <div className="symmetry_var_kind">
                    <div className="symmetry_head_icon">
                      <h5>Symmetry</h5>
                    </div>
                    <section>
                      {symmetryOptions.map((symmetry) => (
                        <button
                          key={symmetry}
                          className={`btn_icons ${
                            selectedSymmetry.includes(symmetry)
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleButtonSymmetry(symmetry)}
                        >
                          {symmetry}
                        </button>
                      ))}
                    </section>
                  </div>
                  <div className="symmetry_var_kind">
                    <div className="symmetry_head_icon">
                      <h5>Polish</h5>
                    </div>
                    <section>
                      {polishOptions.map((polish) => (
                        <button
                          key={polish}
                          className={`btn_icons ${
                            selectedPolish.includes(polish) ? "selected" : ""
                          }`}
                          onClick={() => handleButtonPolish(polish)}
                        >
                          {polish}
                        </button>
                      ))}
                    </section>
                  </div>
                </div>
                <div className="Length_Width_Depth_div">
                  <h5 style={{ fontWeight: "600", fontSize: "16px" }}>
                    Parameters
                  </h5>
                  <div className="length_kind">
                    <div className="budget_div">
                      <h5>Width</h5>
                      <div className="budget_value_div">
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={width[0]}
                              onChange={(e) => handlewidth(0, e)}
                            />
                            <h5>min</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseWidthMinimum}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseWidthMinimum}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={width[1]}
                              onChange={(e) => handlewidth(1, e)}
                            />
                            <h5>max</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseWidthMaximum}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseWidthMaximum}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="budget_div">
                      <h5>Length</h5>
                      <div className="budget_value_div">
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={length[0]}
                              onChange={(e) => handleLength(0, e)}
                            />
                            <h5>min</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseLemgthMIn}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseLengthMin}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={length[1]}
                              onChange={(e) => handleLength(1, e)}
                            />
                            <h5>max</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseLengthMax}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseLengthMax}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="budget_div">
                      <h5>Depth</h5>
                      <div className="budget_value_div">
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={depth[0]}
                              onChange={(e) => handleDepth(0, e)}
                            />
                            <h5>min</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseDepthMinimum}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseDepthMinimum}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="min_max_divv">
                          <div className="value_div">
                            <input
                              type="number"
                              value={depth[1]}
                              onChange={(e) => handleDepth(1, e)}
                            />
                            <h5>max</h5>
                          </div>
                          <div className="btn_divv">
                            <button onClick={increaseDepthMaximum}>
                              <IoIosArrowUp />
                            </button>
                            <button onClick={decreaseDepthMaximum}>
                              <IoIosArrowDown />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );

  return (
    <Root>
      <button className="drawer-toggle-button" onClick={toggleDrawer}>
        <IoFilterOutline /> Filter
      </button>
      <div
        className={`drawer-content ${
          isOpen && screenWidth <= 876 ? "open" : ""
        }`}
      >
        {screenWidth > 876 ? (
          drawerContent
        ) : (
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="bottom"
            className="bla"
          >
            {drawerContent}
          </Drawer>
        )}
      </div>

      <Section4 value={value} />
    </Root>
  );
}
const Root = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 10px;
  .drawer-content {
    padding: 20px 0px;
    width: 100%;
  }
  .drawer-toggle-button {
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
  }
  @media (min-width: 877px) {
    .drawer-toggle-button {
      display: none;
    }
    .drawer-content {
      display: block;
    }
  }
  .EZDrawer__container {
    overflow-y: scroll !important;
    height: 50vh !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    padding-bottom: 40px;
  }

  @media (max-width: 876px) {
    .drawer-toggle-button {
      display: block;
    }
    .drawer-content {
      .ring_types {
        justify-content: left;
        gap: 4px;
        margin: 0px 10px;
        width: 100%;
        .btn_shapes {
          width: 93px !important;
          border: 1px solid #d1d1d1;
          background-color: rgba(247, 247, 247);
          padding: 12px 42px;
        }
      }
    }
  }
  img {
    width: 45px;
    height: 45px;
  }
  .ring_types {
    display: flex;
    width: 100%;
    overflow-x: auto;
    justify-content: center;
    gap: 20px;

    .btn_shapes {
      width: 93px !important;
      border: 2px solid transparent;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      align-items: center;
      padding: 12px 0;
      font-size: 12px;
      line-height: 25px;
      font-weight: 550;
      &.selected {
        border: 2px solid black;
        border-radius: 10px;
      }

      &:hover {
        background-color: rgba(247, 247, 247);
      }
    }
  }
  .section3 {
    padding: 0 20px;
    width: 100%;

    .variation {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      gap: 20px;

      .var_kind {
        flex: 1;

        .head_icon {
          display: flex;
          align-items: center;
          gap: 5px;

          h5 {
            font-size: 15px;
            font-weight: 600;
            color: #000;
          }
          svg {
            height: 14px;
            margin-top: -9px;
            color: #d7d4d4;
          }
        }
        section {
          display: flex;
          padding: 0;
          background-color: rgba(247, 247, 247, 1);
          border: 0.3px solid #d1d1d1;
          border-radius: 7px;
          overflow-x: auto;
          button {
            border: none;
            flex: 1;
            padding: 10px;
            font-size: 14px;
            background-color: transparent;
            font-weight: 600;
            color: #7e7676;
            &.selected {
              border: 2px solid black;
              background-color: #fff;
              border-radius: 7px;
            }
          }
        }
      }
    }

    .carat_budget_certificate {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      .carat_div {
        display: flex;
        flex-direction: column;
        width: 35vw;
        h5 {
          font-size: 15px;
          font-weight: 600;
          color: #000;
          margin-bottom: 0;
          width: 100%;
        }

        .css-188mx6n-MuiSlider-root {
          color: #000000;
          padding: 9px 0;
        }

        .css-188mx6n-MuiSlider-root {
          height: 2px;
        }

        .carat_value_div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .carat_min_max_div {
            border: 1px solid #ededed;
            height: 40px;
            border-radius: 5px;
            display: flex;
            width: 40%;
            margin: 10px 0px;
            .value {
              width: 100%;
              padding: 6px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;
              h6 {
                color: rgba(102, 102, 102);
                margin-bottom: 0;
                font-size: 10px;
              }
              input {
                color: #000000;
                font-size: 11px;
                margin: 0;
                outline: none;
                border: none;
                width: 90%;
              }
            }
            .carat_btn_div {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              button {
                border: 1px solid #ededed;
                background-color: transparent;
                height: 19px;
                padding: 0;
                display: flex;
                justify-content: center;
                svg {
                  color: #7e7676;
                }
              }
            }
          }
          hr {
            width: 57px;
            color: #bbb5b5;
          }
        }
      }

      .budget_div {
        display: flex;
        flex-direction: column;
        width: 35vw;
        h5 {
          font-size: 15px;
          font-weight: 600;
          color: #000;
        }
        .budget_value_div {
          display: flex;
          align-items: center;
          width: 100%;
          .min_max_div {
            border: 1px solid #ededed;
            height: 55px;
            display: flex;
            width: 40%;
            margin-top: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            .value_div {
              padding: 6px;
              h6 {
                color: rgba(102, 102, 102);
                margin-bottom: 14px;
                font-size: 10px;
              }
              input {
                color: #000000;
                font-size: 11px;
                margin: 0;
                outline: none;
                border: none;
                width: 90%;
              }
            }
            .btn_div {
              display: flex;
              /* flex-direction: column; */
              button {
                border: 1px solid #ededed;
                background-color: transparent;
                width: 27px;
                height: 27px;
                font-weight: 600;
                color: #7e7676;
                svg {
                  color: #7e7676;
                }
              }
            }
          }
          hr {
            width: 57px;
            color: #bbb5b5;
          }
        }
      }

      .certificate_div {
        display: flex;
        flex-direction: column;
        flex: 1;
        h5 {
          font-size: 15px;
          font-weight: 600;
          color: #000;
        }
        .btn {
          display: flex;
          gap: 20px;
          padding: 0;
          border: none;
          button {
            background-color: rgba(247, 247, 247, 1);
            border: 0.3px solid #d1d1d1;
            border-radius: 7px;
            width: 100px;
            padding: 20px 0;
            font-size: 14px;
            font-weight: 600;
            color: #7e7676;

            &.selected {
              border: 2px solid black;
              background-color: #fff;
              border-radius: 7px;
            }
          }
        }
      }
    }

    .advance_quality {
      margin-top: 30px;

      .css-1086bdv-MuiPaper-root-MuiAccordion-root {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      }

      .quality_content {
        font-size: 13px;
        font-weight: 700;
        color: #000000;
      }

      .img,
      svg {
        color: black;
        height: 13px;
      }
      .Length_Width_Depth_div {
        display: flex;
        margin: 20px 0px;
        padding: 10px 0px;
        flex-direction: column;
        border-top: 1px solid #dedede;

        .length_kind {
          display: flex;
          flex: 1;
          flex-wrap: wrap;
          justify-content: space-between;
          .d-flex {
            gap: 20px;
          }
          .length {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
          }
          h5 {
            font-size: 14px;
            font-weight: 600;
            color: #000;
          }
          input {
            width: 120px;
            outline: none;
            padding: 10px;
            font-size: 14px;
            background-color: transparent;
            border: 0.3px transparent;
            border-radius: 7px;
            font-weight: 600;
            color: #7e7676;
          }
          p {
            padding: 0px 10px;
            font-weight: 600;
            color: #7e7676;
            font-size: 13px;
            letter-spacing: 1px;
          }
        }
      }
      .symmetry_polish_div {
        display: flex;
        gap: 40px;
        .symmetry_var_kind {
          flex: 1;

          .symmetry_head_icon {
            display: flex;
            align-items: center;
            gap: 5px;

            h5 {
              font-size: 15px;
              font-weight: 600;
              color: #000;
            }
            svg {
              height: 14px;
              margin-top: -9px;
              color: #d7d4d4;
            }
          }

          section {
            display: flex;
            padding: 0;
            background-color: rgba(247, 247, 247, 1);
            border: 0.3px solid #d1d1d1;
            border-radius: 7px;
            button {
              flex: 1;
              border: none;
              padding: 10px 0;
              font-size: 14px;
              background-color: transparent;
              font-weight: 600;
              color: #7e7676;
              &.selected {
                border: 2px solid black;
                background-color: #fff;
                border-radius: 7px;
              }
            }
          }
        }
      }

      .css-eqpfi5-MuiAccordionSummary-content {
        -webkit-flex-grow: unset;
        flex-grow: unset;
      }

      .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(128deg);
      }

      .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
        gap: 4px;
      }
    }
  }

  @media only screen and (max-width: 567px) {
    .section3 .variation {
      gap: 30px;
    }

    .section3 .variation .var_kind {
      width: 100%;
      flex: unset;
    }

    .section3 .carat_budget_certificate .carat_div {
      gap: 15px;
      width: 100%;
    }

    .section3 .carat_budget_certificate .budget_div {
      flex: unset;
      width: 100%;
    }

    .section3 .carat_budget_certificate .budget_div .budget_value_div {
      justify-content: center;
    }

    .section3 .carat_budget_certificate .certificate_div {
      flex: unset;
      width: 100%;
    }

    .symmetry_polish_div {
      flex-direction: column;
    }
    .section3 .carat_budget_certificate .certificate_div .btn button {
      width: 50vw;
      padding: 10px 0px;
    }

    /* .main_div .subdiv {
      width: 373px;
    }

    .main_div .subdiv:hover .hov_content {
      width: 373px;
    } */
  }
  span.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-cp2j25-MuiSlider-thumb {
    color: white;
    border: 2px solid black;
  }

  .budget_value_div {
    display: flex;
    gap: 8px;
  }

  .min_max_div,
  .min_max_divv {
    display: flex;
  }
  .min_max_divv {
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 0px 10px;
  }
  .btn_divv {
    display: flex;
    /* background-color: #7e7676;
    border: 1px solid #7e7676; */
    flex-direction: column;
    button {
      border: 1px transparent;
      background-color: transparent;
      width: 25px;
      height: 25px;
      font-weight: 600;
      color: #7e7676;
      svg {
        color: #7e7676;
      }
    }
  }
`;
