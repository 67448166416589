import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";

export default function ProductPage() {
  return (
    <div>
      <Section1 />
      <Section2 />
    </div>
  );
}
