import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";


export default function Terms_of_use() {
    return (
        <Root>
            <div className="container p-4 pb-2 pt-3 md-p-5" id="terms_of_use">
                <Section1 />
                <Section2 />
                <Section3 />
            </div>
        </Root>
    );
}

const Root = styled.section`
    background-color: #fefefe;
  
  .container{
width:90%;  }
  
 `;
