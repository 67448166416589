import React from "react";
import styled from "styled-components";


export default function Section2() {
    return (
        <Root>
            <div className="KeyzarInfo__article-content"></div>
            <h2>
                <span >
                    <b>THE PRECIOUS EARTH TERMS OF USE</b>
                </span>
            </h2>
            <p>
                <i>
                    <span >Last Updated: April 2023</span>
                </i>
            </p>
            <p>&nbsp;</p>
            <table className="col-lg-12">
                <tbody>
                    <tr >
                        <td className="col-lg-6">
                            <ul>
                                <li>
                                    <a href="#link_to_access_and_use">
                                        <span >Access and Use of the Website</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_intellectual">
                                        <span >Intellectual Property Rights</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_third_party">
                                        <span >Third Party Materials</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_merchandise">
                                        <span >Merchandise and Orders</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_price">
                                        <span >Price and Payment</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_product">
                                        <span >Product Warranties</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_returns">
                                        <span >Returns</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_exchanges">
                                        <span >Exchanges</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_resizing">
                                        <span >Re-Sizing</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_user_contributions">
                                        <span >User Contributions</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_social_media">
                                        <span >Social Media Features</span>
                                    </a>
                                </li>
                            </ul>
                        </td>
                        <td className="col-lg-6">
                            <ul>
                                <li >
                                    <a href="#link_to_messaging">
                                        <span >Messaging Terms &amp; Conditions</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_restrictions_on_use">
                                        <span >Restrictions on Use</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_disclaimer">
                                        <span >Disclaimer</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_limitation">
                                        <span >Limitation on Liability</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_indemnification">
                                        <span >Indemnification</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_governing">
                                        <span >Governing Law &amp; Jurisdiction</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_arbitration">
                                        <span >Arbitration</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_class">
                                        <span >Class Action Waiver</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_waiver">
                                        <span >Waiver and Severability</span>
                                    </a>
                                </li>
                                <li >
                                    <a href="#link_to_entire">
                                        <span >Entire Agreement</span>
                                    </a>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Root>
    )
}
const Root = styled.section`

a{
font-size:14px
}

h2 {
  span {
    b {
    font-size: 20px;
    text-decoration:underline;
    }
  }
}

a {
  color: black;
}

.KeyzarInfo__article-content ul {
  margin-left: 1.25rem;
  list-style-type: disc;
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

.KeyzarInfo__article-content ul li {
  position: relative;
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 1em;
}

.KeyzarInfo__article-content tbody th, 
.KeyzarInfo__article-content tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

tbody {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

ul li::marker {
    color: #f38da4;
}

    `