import React from "react";
import styled from "styled-components";
import location from '../../Images/new-location-icon.png';

export default function Section3() {
    return (
        <Root>
            <div className="container py-5">
                <h2 className="header-text">We are here</h2>
            </div>
            <div className="container px-3 px-md-0">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <Card>
                            <div className="mb-4 d-flex flex-column align-items-center text-center">
                                <div className="d-flex justify-content-center mb-3">
                                    <img
                                        alt="Location Icon"
                                        src={location}
                                        width="48"
                                        height="48"
                                    />
                                </div>
                                <h2 className="text-center mb-3 font-weight-normal text-uppercase">Our Office</h2>
                                <div className="text-custom-gray-500 text-base mx-auto">
                                    <p>123 Street, House Number #1234, Dummy City, Dummy Country 123456 XYZ</p>
                                </div>
                            </div>
                            <div>
                                <iframe
                                    className="rounded-bottom w-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.482894148877!2d-122.42193468468117!3d37.77926127975811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808eae9a0e4f%3A0x15b1d7ac8f53a009!2s1%20Market%20St%2C%20San%20Francisco%2C%20CA%2094121!5e0!3m2!1sen!2sus!4v1604627414845!5m2!1sen!2sus"
                                    width="100%"
                                    height="400"
                                    style={{ border: 0 }}
                                    loading="lazy"
                                    title="Map"
                                ></iframe>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </Root>
    );
}

const Root = styled.section`
  margin-bottom: 2.5rem; /* Space below the section */
  width: 100%;
  text-align: center;

  @media (min-width: 768px) {
    margin-bottom: 3.75rem; /* Space below the section for larger screens */
  }

  .header-text {
    margin: 0;
    font-size: 2.25rem; /* Font size for smaller screens */
    font-weight: normal;
    color: #000; /* Black text color */
    text-transform: uppercase;
    line-height: 1.2; /* Line height for header text */
    
    @media (min-width: 768px) {
      font-size: 2rem; /* Font size for larger screens */
    }
  }

  .text-custom-gray-500 {
    color: #6c757d; /* Custom gray color for text */
  }

  .rounded-bottom {
    border-radius: 0 0 0.375rem 0.375rem; /* Rounded bottom corners */
  }
`;

const Card = styled.div`
  border: 1px solid #d1d1d1; /* Border color */
  padding: 1rem; /* Padding inside the card */
  background-color: #fff; /* White background color */
  border-radius: 0.75rem; /* Rounded corners */

  @media (min-width: 576px) {
    padding: 1.5rem; /* More padding for larger screens */
  }
  
  @media (min-width: 768px) {
    padding: 2rem; /* Even more padding for larger screens */
  }
`;
