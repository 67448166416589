import {
  SET_DIAMOND_BY_ID,
  SET_DIAMOND_IDS,
  SET_DIAMOND_TYPE,
  SET_PRODUCT_IDS,
  SET_SELECTED_RING_SVG,
  SET_SELECTED_SHAPE,
  SET_SELECTED_SHAPE_IMAGE,
  SET_SELECTED_VARIANT_ID,
  SET_UNIQUE_DATA,
  SET_UNIQUE_PRODUCT,
  SET_UNIQUE_PRODUCT_GEM,
  SET_SELECTED_MATERIAL_IMAGE,
  SET_SELECTED_RING_SHAPE,
  SET_SELECTED_SHAPE_IMAGE_GEM,
  SET_FOR_HER_HIM,
  SET_SELECTED_HIM_IMG_TITLE,
  SET_SELECTED_HER_IMG_TITLE,
  SET_HER_HIM_PRODUCT_IDS,
  SET_WEDDING_IDS,
  SET_SELECTED_METAL_IDS,
  SET_SELECTED_RING_METAL_IDS,
  SET_AVAILABLE_VARIANTS,
  SET_VARIANT_FOR_HER,
  SET_VARIANT_FOR_HIM,
} from "./type";

const initialState = {
  user: {},
  userCheck: false,
  role: "",
  appDetails: {},
  isLoading: false,
  productIds: [],
  diamondIds: [],
  selectedVariantId: [],
  diamondType: "",
  selectedShapeImage: null,
  selectedShapeImageGem: null,
  selectedRingShape: null,
  selectedRingSvg: null,
  diamondById: null,
  selectedShape: "null",
  uniqueData: [],
  uniqueProduct: null,
  uniqueProductGem: null,
  productGemId: null,
  selectedOptions: {
    uniqueProduct: null,
    preDefineData:[],
    carat: null,
    color: null,
    material: null,
    selectedMaterial:null,
    selectedCarat:null,
    selectedShape:null
  },
  collectionIds: null,
  selectedMaterialImage: {
    shape: null,
    imageUrl: null,
  },
  selectedCollectionId: "",
  selectedShapeNames: null,
  predefineData: null,
  herHimProductIds: null,
  her: {
    selectedTitle: "",
    selectedImg: "",
  },
  him: {
    selectedTitle: "",
    selectedImg: "",
  },
  weddingIds: [],
  selectedMetalIds: [],
  selectedRingMetalIds: [],
  availableMaterials: [],
  availableCarats: [],
  variantForHer: {},
  variantForHim: {},
  productDetail:{}
};
const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_DETAILS_RING":
      return {
        ...state,
        productDetail: action.payload,
      }; 
    case SET_VARIANT_FOR_HIM:
      return {
        ...state,
        variantForHim: action.payload,
      };
    case SET_VARIANT_FOR_HER:
      return {
        ...state,
        variantForHer: action.payload,
      };
    case SET_AVAILABLE_VARIANTS:
      return {
        ...state,
        availableMaterials: action.payload.materials,
        availableCarats: action.payload.carats,
      };
    case SET_SELECTED_RING_METAL_IDS:
      return {
        ...state,
        selectedRingMetalIds: action.payload,
      };
    case SET_SELECTED_METAL_IDS:
      return {
        ...state,
        selectedMetalIds: action.payload,
      };
    case SET_WEDDING_IDS:
      return {
        ...state,
        weddingIds: action.payload,
      };
    case SET_HER_HIM_PRODUCT_IDS:
      return {
        ...state,
        herHimProductIds: action.payload,
      };
    case SET_SELECTED_HER_IMG_TITLE:
      return {
        ...state,
        her: {
          selectedTitle: action.payload.selectedTitle,
          selectedImg: action.payload.selectedImg,
        },
      };
    case SET_SELECTED_HIM_IMG_TITLE:
      return {
        ...state,
        him: {
          selectedTitle: action.payload.selectedTitle,
          selectedImg: action.payload.selectedImg,
        },
      };
    case "FETCH_PREDEFINE_DATA":
      return {
        ...state,
        predefineData: action.payload,
      };
    case "SET_PRODUCT_GEM_ID":
      return {
        ...state,
        productGemId: action.payload,
      };
    case SET_SELECTED_RING_SHAPE: // Add this case
      return {
        ...state,
        selectedRingShape: action.payload,
      };
    case "SET_SELECTED_COLLECTION_ID":
      return {
        ...state,
        selectedCollectionId: action.payload,
      };
    case "SET_SELECTED_SHAPE_GEM":
      return {
        ...state,
        selectedShapeGem: action.payload,
      };
    case SET_SELECTED_MATERIAL_IMAGE:
      return {
        ...state,
        selectedMaterialImage: {
          shape: action.payload.shape,
          imageUrl: action.payload.imageUrl,
        },
      };
    case "SET_SELECTED_OPTIONS":
      return {
        ...state,
        selectedOptions: {
          uniqueProduct: action.payload.uniqueProduct,
          preDefineData: action.payload.preDefineData,
          carat: action.payload.carat,
          color: action.payload.color,
          material: action.payload.material,
          selectedShape: action.payload.selectedShape,
          selectedCarat: action.payload.selectedCarat,
          selectedMaterial: action.payload.selectedMaterial,
        },
      };
    case SET_UNIQUE_PRODUCT:
      return {
        ...state,
        uniqueProduct: action.payload,
      };
    case SET_UNIQUE_PRODUCT_GEM:
      return {
        ...state,
        uniqueProductGem: action.payload,
      };
    case "SET_COLLECTION_IDS":
      return {
        ...state,
        collectionIds: action.payload,
      };
    case SET_UNIQUE_DATA:
      return {
        ...state,
        uniqueData: action.payload,
      };
    case SET_SELECTED_SHAPE:
      return {
        ...state,
        selectedShape: action.payload,
      };
    case SET_DIAMOND_BY_ID:
      return {
        ...state,
        diamondById: action.payload,
      };
    case SET_SELECTED_RING_SVG:
      return {
        ...state,
        selectedRingSvg: action.payload,
      };
    case SET_SELECTED_SHAPE_IMAGE:
      return {
        ...state,
        selectedShapeImage: action.payload,
      };
    case SET_SELECTED_SHAPE_IMAGE_GEM:
      return {
        ...state,
        selectedShapeImageGem: action.payload,
      };
    case SET_DIAMOND_TYPE:
      return {
        ...state,
        diamondType: action.payload,
      };
    case SET_FOR_HER_HIM:
      return {
        ...state,
        forHerHim: action.payload,
      };
    case SET_SELECTED_VARIANT_ID:
      return {
        ...state,
        selectedVariantId: action.payload,
      };
    case SET_DIAMOND_IDS:
      return {
        ...state,
        diamondIds: action.payload,
      };
    case SET_PRODUCT_IDS:
      return {
        ...state,
        productIds: action.payload,
      };
    
    case "USER_DATA":
      return {
        ...state,
        user: action.payload,
      };
    case "LOADING_DATA":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "USER_CHECK":
      return {
        ...state,
        userCheck: action.payload,
      };
    case "APP_DETAILS":
      return {
        ...state,
        appDetails: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
