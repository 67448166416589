import React from "react";
import styled from "styled-components";

export default function Section2() {
  return (
    <Root>
        <div id="free-shipping-worldwide" className="d-flex flex-column justify-content-between gap-8 pb-5 border-b border-borders">
          <h2>Free Shipping Worldwide*</h2>
          <div>
            <p>If you have a special request regarding shipping, please contact us prior to placing an order at 
              <a href="#"> contact@xyz.com</a>.</p>
          </div>
          <div className="d-flex flex-column gap-8">
            <div className="d-flex flex-column gap-8"> 
            <h3 className="fs-6 font-weight-semibold">Lead Time</h3>
              <p>Most of our products are made to order, and require approximately 3-5 weeks to craft. Ready to Ship items can usually be shipped within four (4) business days (excluding certain holidays) of your approved order. Please note that severe weather conditions may impact these timeframes. You agree that The Precious Earth is not liable for any delay caused by weather conditions or similar events outside of The Precious Earth or its shipping providers’ reasonable control.</p>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Customs</h3>
              <p>Customs processing and import duties are managed by our shipping service providers, such as FedEx, DHL, USPS, etc. They may reach out to you to let you know about any customs charges you have to pay. If you need help or documents to facilitate custom processing, please contact us; we’re happy to offer our assistance.</p>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Expedited Shipping</h3>
              <p>We ship most of our orders using Overnight Priority Shipping. Once your item has been shipped, use the provided tracking number to track your purchase.</p>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Insured</h3>
              <span>
              <p>Once you’ve made an order, you can rest calm knowing your purchase is protected. The Precious Earth insures each and every shipped item for its full value while on its way to you. Your piece is protected against theft, losses, and damages – all at no extra cost to you.</p>
              <p>We recommend you only utilize The Precious Earth’s shipping services when shipping your order. The Precious Earth is not responsible for any shipments that go missing or are stolen if you use shipment services not insured by The Precious Earth.</p>
              </span>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Delivered to Your Door</h3>
              <p>Your purchase will ship directly to your address so you can receive it from the comfort of your home. For your protection, orders require a signature upon delivery.</p>
            </div>
            <div  className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Change of Shipping Address</h3>
              <p>We can change your shipping address only until the point that your order is ready to ship. Your order is ready to ship when you receive a tracking email. Once your order is ready to ship, we cannot guarantee that your request to change your shipping address can be honored. Depending on the change in shipping address, you may incur additional tax fees that will be invoiced to you. In the event that the difference in taxes is less than the original charge for shipping taxes, you will receive a refund for the difference.</p>
            </div>
          </div>
          <div>
            <p>* The Precious Earth free shipping does not cover any Taxes and Fees that may be applicable to your purchase. Please carefully review our Price and Payment policy .</p>
          </div>
        </div>
    </Root>
  )
}

const Root = styled.section`
  font-family: ProximaNova, sans-serif;
  background-color: #f8f9fa;
  line-height:1.5;
  h2 {
    font-size: 36px;
    line-height: 1.375;
    font-weight: 500;
    color: black;
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: black;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  p {
    color: black;
    font-size: 14px;
    font-weight:500;
  }

  .border-b {
    border-bottom: 1px solid #ddd;
  }

  .border-borders {
    border-color: #ddd;
  }
    .gap-8{
    gap:2rem;
    }
     p,h2,h3{
     margin:0;
    }

`;
