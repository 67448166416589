import React from "react";
import ww from "../../Images/ww.webp";
import styled from "styled-components";
import Slider from "react-slick";
import a from "../../Images/a.jpg";
import { useNavigate } from "react-router-dom";

export const Section4 = ({ pro }) => {
  const navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>
      <div className="slider-container">
        <Slider {...settings}>
          {pro?.images?.edges?.map((image) => (
            <div onClick={navigate("/engagementring")}>
              <img src={image?.node?.originalSrc} alt="img" />
            </div>
          ))}

         
        </Slider>
      </div>
    </Root>
  );
};

const Root = styled.section`


  .slick-prev {
    display: none !important;
  }

  .slick-next {
    display: none !important;
  }
`;
