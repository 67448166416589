import React from "react";

export default function Svgsvg3() {
  return (
    <div>
      <svg style={{ width: "45px" }}
        class="block mx-auto w-13.5 h-13.5 mb-2 md:w-12 md:h-12"
        viewBox="0 0 48 48"
      >
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.1438 18.2038L27.5398 20.8198L29.9398 23.8558L28.3318 19.9438L24.1438 18.2038ZM18.0958 23.0518L19.7158 28.2598L24.5398 30.1318L20.7118 26.8798L18.0958 23.0518ZM31.7518 20.7838L31.7638 20.7958L31.7518 27.2158L27.2158 31.7518L27.2038 31.7638L20.7838 31.7518L16.2478 27.2158L16.2358 27.2038L16.2478 20.7838L20.7838 16.2478L20.7958 16.2358L27.2158 16.2478L31.7518 20.7838Z"
            fill="#4EAF90"
          ></path>
          <path
            d="M31.7518 27.2158L36.1918 29.0518C34.8598 32.2798 32.2798 34.8598 29.0518 36.1918L27.2158 31.7518L31.7518 27.2158Z"
            fill="#4EAF90"
          ></path>
          <path
            d="M20.7836 31.7518L18.9476 36.1918C15.7196 34.8598 13.1396 32.2798 11.8076 29.0518L16.2476 27.2158L20.7836 31.7518Z"
            fill="#4ADA95"
          ></path>
          <path
            d="M29.0518 36.192C27.5038 36.84 25.7878 37.2 23.9998 37.2C22.2118 37.2 20.4958 36.84 18.9478 36.192L20.7838 31.752L27.2038 31.764L27.2158 31.752L29.0518 36.192Z"
            fill="#9BE7C9"
          ></path>
          <path
            d="M23.9998 10.7998C25.7878 10.7998 27.5038 11.1598 29.0518 11.8078L27.2158 16.2478L20.7958 16.2358L20.7838 16.2478L18.9478 11.8078C20.4958 11.1598 22.2118 10.7998 23.9998 10.7998Z"
            fill="#4EAF90"
          ></path>
          <path
            d="M10.7998 23.9998C10.7998 22.2118 11.1598 20.4958 11.8078 18.9478L16.2478 20.7838L16.2358 27.2038L16.2478 27.2158L11.8078 29.0518C11.1598 27.5038 10.7998 25.7878 10.7998 23.9998Z"
            fill="#088426"
          ></path>
          <path
            d="M36.1918 18.9476L31.7518 20.7836L27.2158 16.2476L29.0518 11.8076C32.2798 13.1396 34.8598 15.7196 36.1918 18.9476Z"
            fill="#1E7646"
          ></path>
          <path
            d="M18.9476 11.8076L20.7836 16.2476L16.2476 20.7836L11.8076 18.9476C13.1396 15.7196 15.7196 13.1396 18.9476 11.8076Z"
            fill="#044E35"
          ></path>
          <path
            d="M36.192 18.9478C36.84 20.4958 37.2 22.2118 37.2 23.9998C37.2 25.7878 36.84 27.5038 36.192 29.0518L31.752 27.2158L31.764 20.7958L31.752 20.7838L36.192 18.9478Z"
            fill="#044E35"
          ></path>
          <path
            d="M16.2478 27.2158L16.2358 27.2038L16.2478 20.7838L20.7838 16.2478L20.7958 16.2358L27.2158 16.2478L31.7518 20.7838L31.7638 20.7958L31.7518 27.2158L27.2158 31.7518L27.2038 31.7638L20.7838 31.7518L16.2478 27.2158Z"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M36.1918 18.9478C36.8398 20.4958 37.1998 22.2118 37.1998 23.9998C37.1998 25.7878 36.8398 27.5038 36.1918 29.0518C34.8598 32.2798 32.2798 34.8598 29.0518 36.1918C27.5038 36.8398 25.7878 37.1998 23.9998 37.1998C22.2118 37.1998 20.4958 36.8398 18.9478 36.1918C15.7198 34.8598 13.1398 32.2798 11.8078 29.0518C11.1598 27.5038 10.7998 25.7878 10.7998 23.9998C10.7998 22.2118 11.1598 20.4958 11.8078 18.9478C13.1398 15.7198 15.7198 13.1398 18.9478 11.8078C20.4958 11.1598 22.2118 10.7998 23.9998 10.7998C25.7878 10.7998 27.5038 11.1598 29.0518 11.8078C32.2798 13.1398 34.8598 15.7198 36.1918 18.9478Z"
            stroke="black"
            stroke-width="0.91667"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M36.192 18.9478L31.752 20.7838"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M11.8076 29.0518L16.2476 27.2158"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M18.9478 11.8076L20.7838 16.2476"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M27.2158 31.752L29.0518 36.192"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M29.0518 11.8076L27.2158 16.2476"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M18.9478 36.192L20.7838 31.752"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M31.752 27.2158L36.192 29.0518"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M11.8076 18.9478L16.2476 20.7838"
            stroke="black"
            stroke-width="0.75"
            stroke-miterlimit="10"
          ></path>
          <path
            d="M28.3316 19.9441L29.9396 23.8561L27.5396 20.8201L24.1436 18.2041L28.3316 19.9441Z"
            fill="black"
          ></path>
          <path
            d="M24.5397 30.1318L19.7157 28.2598L18.0957 23.0518L20.7117 26.8798L24.5397 30.1318Z"
            fill="black"
          ></path>
        </svg>
      </svg>
    </div>
  );
}
