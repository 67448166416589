export const SET_PRODUCT_IDS = "SET_PRODUCT_IDS";
export const SET_DIAMOND_IDS = "SET_DIAMOND_IDS";
export const SET_SELECTED_VARIANT_ID = "SET_SELECTED_VARIANT_ID";
export const SET_DIAMOND_TYPE = "SET_DIAMOND_TYPE";
export const SET_FOR_HER_HIM = "SET_FOR_HER_HIM";
export const SET_SELECTED_SHAPE_IMAGE = "SET_SELECTED_SHAPE_IMAGE";
export const SET_SELECTED_RING_SVG = "SET_SELECTED_RING_SVG";
export const SET_DIAMOND_BY_ID = "SET_DIAMOND_BY_ID";
export const SET_SELECTED_SHAPE = "SET_SELECTED_SHAPE";
export const SET_UNIQUE_DATA = "SET_UNIQUE_DATA";
export const SET_UNIQUE_PRODUCT = "SET_UNIQUE_PRODUCT";
export const SET_SELECTED_MATERIAL_IMAGE = "SET_SELECTED_MATERIAL_IMAGE";
export const SET_SELECTED_RING_SHAPE = 'SET_SELECTED_RING_SHAPE';
export const SET_SELECTED_SHAPE_IMAGE_GEM = 'SET_SELECTED_SHAPE_IMAGE_GEM';
export const SET_UNIQUE_PRODUCT_GEM = 'SET_UNIQUE_PRODUCT_GEM';
export const SET_SELECTED_HER_IMG_TITLE = 'SET_SELECTED_HER_IMG_TITLE';
export const SET_SELECTED_HIM_IMG_TITLE = 'SET_SELECTED_HIM_IMG_TITLE';
export const SET_HER_HIM_PRODUCT_IDS = 'SET_HER_HIM_PRODUCT_IDS';
export const SET_WEDDING_IDS = 'SET_WEDDING_IDS';
export const SET_SELECTED_METAL_IDS = 'SET_SELECTED_METAL_IDS';
export const SET_SELECTED_RING_METAL_IDS = 'SET_SELECTED_RING_METAL_IDS';
export const SET_AVAILABLE_VARIANTS = 'SET_AVAILABLE_VARIANTS';
export const SET_VARIANT_FOR_HER = 'SET_VARIANT_FOR_HER';
export const SET_VARIANT_FOR_HIM = 'SET_VARIANT_FOR_HIM';
