import React from "react";
import styled from "styled-components";
import Exceptionality from '../../Images/new-expectations.svg';
import Growth from '../../Images/new-Growth.svg';

export default function Section3() {
    return (
        <Root>
            <div className="container img_spac">
                <div className="d-flex flex-row flex-wrap gap-3 overflow-hidden overflow-auto hidden-scroll justify-content-center mx-3 mx-md-0 px-1 px-md-0 cont_mar">
                    <div className="position-relative flex-shrink-0" style={{ minWidth: '300px', maxWidth: '30.3333%' }}>
                        <img src={Exceptionality} className="img-fluid card-image" alt="Exceptionality" />
                        <span className="text-large text-light bg rounded-4">Exceptionality</span>
                        <div className="gradient-overlay">
                            <span className="text-large">Exceptionality</span>
                            <p className="text-small">We have worked in every level of the jewelry industry and know what it takes to create meaningful, long lasting jewelry. Our team is composed of high-achieving, creative thinkers, determined to make every piece with the utmost precision and care.</p>
                        </div>
                    </div>
                    <div className="position-relative flex-shrink-0" style={{ minWidth: '300px', maxWidth: '30.3333%' }}>
                        <img src={Growth} className="img-fluid card-image" alt="Growth" />
                        <span className="text-large text-light bg rounded-4">Growth</span>
                        <div className="gradient-overlay">
                            <span className="text-large">Growth</span>
                            <p className="text-small">We have worked in every level of the jewelry industry and know what it takes to create meaningful, long lasting jewelry. Our team is composed of high-achieving, creative thinkers, determined to make every piece with the utmost precision and care.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    );
}

const Root = styled.section`
  background-color: white;
  width:100%;


  .cont_mar{
    margin: 0 0 40px;
  }

    
.img_spac{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
  .hidden-scroll {
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: thin;
  }

  .hidden-scroll::-webkit-scrollbar {
    display: none;
  }

  .card-image {
    border-radius: 0.75rem;
    display: block;
    width: 100%;
    backface-visibility: hidden;
  }

  .position-relative {
    position: relative;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.75rem;
    color: white;
    padding: 3rem 1rem 1rem 1rem;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0%) 30%, rgba(0, 0, 0, 0.51) 99%);
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease, padding 0.3s ease;
  }

  .position-relative:hover .gradient-overlay {
    opacity: 1; 
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.46) 49%, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 1) 99%);
    padding: 1rem; 
  }

  .gradient-overlay p {
    transform: translateY(100%); 
    transition: transform 0.3s ease; 
    margin: 0;
  }

  .position-relative:hover .gradient-overlay p {
    transform: translateY(0); 
  }

  .text-large {
    font-size: 1.75rem;
    position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    padding: 1rem; 
    z-index: 1; 
  }

  
  .text-small {
    font-size: 0.8125rem;
    display: block; 
    text-align: center; 
  }

  .bg {
    background: linear-gradient(to top, rgb(0 0 0 / 1%) 30%, rgb(0 0 0 / 1%) 30%, rgb(0, 0, 0 / 86%) 99%);
  }

  @media (max-width: 992px) {
    .d-flex {
      flex-direction: column; /* Switch to column layout on screens 992px and below */
    }
    .position-relative {
      max-width: 100%; /* Ensure images use full width available */
      margin-bottom: 1rem; /* Add spacing between items */
    }
  }

  @media (max-width: 768px) {
    .text-large {
      font-size: 1.5rem; /* Adjust font size for smaller screens */
    }
    .text-small {
      font-size: 0.75rem; /* Adjust font size for smaller screens */
    }
    .gradient-overlay {
      padding: 2rem 0.5rem 0.5rem 0.5rem; /* Adjust padding for smaller screens */
    }
  }

  @media (max-width: 576px) {
    .text-large {
      font-size: 1.25rem; /* Further adjust font size for extra small screens */
    }
    .text-small {
      font-size: 0.6875rem; /* Further adjust font size for extra small screens */
    }
    .gradient-overlay {
      padding: 1.5rem 0.5rem 0.5rem 0.5rem; /* Further adjust padding for extra small screens */
    }
  }

  @media (max-width: 567px) {
    .img_spac{
  padding: 5px 0;
}


.cont_mar{
    margin: 0;
  }





  }
`;
