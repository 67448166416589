import React, { useEffect, useState } from "react";
import styled from "styled-components";
import bgg from "../../Images/eternity.jpg";
import { RxCross1 } from "react-icons/rx";
import WhiteGold14 from "../../Images/fourone.png";
import YellowGold14 from "../../Images/fourtwo.png";
import RoseGold14 from "../../Images/fourthree.png";
import WhiteGold18 from "../../Images/eightone.png";
import YellowGold18 from "../../Images/eighttwo.png";
import RoseGold18 from "../../Images/eightthree.png";
import Platinum from "../../Images/pt.png";
import { IoIosArrowDown } from "react-icons/io";
import ROUND from "../../Images/round-removebg-preview.png";
import EMERALD from "../../Images/emerald-removebg-preview.png";
import HEART from "../../Images/heart-removebg-preview.png";
import MARQUISE from "../../Images/Marquise-removebg-preview.png";
import OVAL from "../../Images/oval-removebg-preview.png";
import PEAR from "../../Images/Pear-removebg-preview.png";
import PRINCESS from "../../Images/Princess-removebg-preview.png";
import RADIANT from "../../Images/Radiant-removebg-preview.png";
import CUSHION from "../../Images/cushionremovebg.png";
import ASSCHER from "../../Images/ECusion-removebg-preview.png";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { IoFilterOutline } from "react-icons/io5";
import axios from "axios";
import { EXCHANGE_URLS } from "../URLS";
import { useLoading } from "../LoadingContext";
import { NoProduct } from "../NoProduct";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// tag
const metals = [
  { id: 1, label: "White Gold", name: "14kwhite", imgUrl: WhiteGold14 },
  { id: 2, label: "Yellow Gold", name: "14kyellow", imgUrl: YellowGold14 },
  { id: 3, label: "Rose Gold", name: "14krose", imgUrl: RoseGold14 },
  { id: 4, label: "White Gold.", name: "18kwhite", imgUrl: WhiteGold18 },
  { id: 5, label: "Yellow Gold.", name: "18kyellow", imgUrl: YellowGold18 },
  { id: 6, label: "Rose Gold.", name: "18krose", imgUrl: RoseGold18 },
  { id: 7, label: "Platinum", name: "14kplatinum", imgUrl: Platinum },
];
// shape
const shapes = [
  { id: 1, type: "ROUND", name: "EternityRound", imgUrl: ROUND },
  { id: 2, type: "PRINCESS", name: "EternityPrincess", imgUrl: PRINCESS },
  { id: 3, type: "OVAL", name: "EternityOval", imgUrl: OVAL },
  { id: 4, type: "EMERALD", name: "EternityEmerald", imgUrl: EMERALD },
  { id: 5, type: "PEAR", name: "EternityPear", imgUrl: PEAR },
  { id: 6, type: "HEART", name: "EternityHeart", imgUrl: HEART },
  { id: 7, type: "MARQUISE", name: "EternityMarquise", imgUrl: MARQUISE },
  { id: 8, type: "CUSHION", name: "EternityCushion", imgUrl: CUSHION },
  { id: 9, type: "E.Cusion", name: "EternityECusion", imgUrl: ASSCHER },
  { id: 10, type: "RADIANT", name: "EternityRadiant", imgUrl: RADIANT },
];
export const Eternity = () => {
  const [tags, setTags] = useState([]);
  const [eternityProduct, setEternityProduct] = useState();
  const [show, setShow] = useState(false);
  const [shape, setShape] = useState(false);
  const defaultMetal = metals[0];
  const defaultShape = shapes[0];

  const [selectedMetal, setSelectedMetal] = useState(defaultMetal);
  const [selectedShape, setSelectedShape] = useState(defaultShape);

  const [selectedDropButton, setSelectedDropButton] = useState(
    defaultMetal.name
  );
  const [selectedOption, setSelectedOption] = useState(defaultShape.name);
  const [isOpen, setIsOpen] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { setLoading } = useLoading();

  useEffect(() => {
    const fetchProductsDetails = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${EXCHANGE_URLS}/collectionEternity?tag=${selectedDropButton}&shape=${selectedOption}`
        );
        if (res.status === 200) {
          setEternityProduct(res.data.products);
          const productIds = res.data.products.map((product) => product);
          console.log("productid", productIds);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductsDetails();
  }, [
    selectedShape,
    selectedMetal,
    setLoading,
    selectedDropButton,
    selectedOption,
  ]);

  const handleButtonDropClick = (selectedName) => {
    const selectedMetal = metals.find((metal) => metal.name === selectedName);
    setSelectedMetal(selectedMetal);
    setSelectedDropButton(selectedName);
    setTags([selectedMetal.label, selectedShape.type]);
  };

  const handleButtonOption = (shapeName) => {
    const shape = shapes.find((shape) => shape.name === shapeName);
    setSelectedShape(shape);
    setSelectedOption(shapeName);
    setTags([selectedMetal.label, shape.type]);
  };
  function toggleShowName() {
    setShow((prevState) => !prevState);
  }

  function toggleShapeName() {
    setShape((prevState) => !prevState);
  }

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const drawerContent = (
    <>
      <div className="ring_types"></div>
      <StyledSection>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="select_div">
                <div className="select_opt">
                  <div className="head_icon" onClick={toggleShowName}>
                    <h3>Select Metal</h3>
                    <IoIosArrowDown />
                  </div>

                  {show && (
                    <div className="select_metal">
                      <div className="first_row">
                        <div className="d-flex align-items-center">
                          <h5>Metal : {selectedMetal.label}</h5>
                        </div>
                        <RxCross1
                          className="icon"
                          onClick={() => setShow(false)}
                        />
                      </div>

                      <div className="btn_row">
                        {metals.map((metal) => (
                          <button
                            key={metal.name}
                            className={
                              selectedDropButton === metal.name
                                ? "selected"
                                : ""
                            }
                            onClick={() => handleButtonDropClick(metal.name)}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={metal.imgUrl}
                              alt={metal.name}
                            />
                            <h5>{metal.label}</h5>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="head_icon" onClick={toggleShapeName}>
                    <h3>Select Shape</h3>
                    <IoIosArrowDown />
                  </div>

                  {shape && (
                    <div className="select_shape">
                      <div className="first_row">
                        <div className="d-flex align-items-center">
                          <h5>Shape : {selectedShape.type}</h5>
                        </div>
                        <RxCross1
                          className="icon"
                          onClick={() => setShape(false)}
                        />
                      </div>

                      <div className="btn_row">
                        {shapes.map((shape) => (
                          <button
                            key={shape.name}
                            className={
                              selectedOption === shape.name ? "selected" : ""
                            }
                            onClick={() => handleButtonOption(shape.name)}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={shape.imgUrl}
                              alt={shape.name}
                            />
                            <h5>{shape.type}</h5>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* <select>
                  <option value="low-high">Pricing (low-to-high)</option>
                  <option value="high-low">Pricing (high-to-low)</option>
                  <option value="best-selling">Best Selling</option>
                </select> */}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <TagsInput value={tags} onChange={setTags} />
            </div>
          </div>
        </div>
      </StyledSection>
    </>
  );

  return (
    <Root>
      <div className="bg_img"></div>
      <button className="drawer-toggle-button" onClick={toggleDrawer}>
        <IoFilterOutline /> Filter
      </button>
      <div
        className={`drawer-content ${
          isOpen && screenWidth <= 567 ? "open" : ""
        }`}
      >
        {screenWidth > 567 ? (
          drawerContent
        ) : (
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="bottom"
            className="bla"
          >
            {drawerContent}
          </Drawer>
        )}
      </div>
      <div className="main_div">
        {eternityProduct &&
          eternityProduct?.map((product, index) => {
            const productIds = product?.id;
            return (
              <div key={index} className="subdiv">
                <div className="slider-container">
                  <Slider {...settings}>
                    {product?.images?.edges?.length > 0 ? (
                      product.images.edges.map((image) => (
                        <div key={image.node.id}>
                          {" "}
                          {/* Separate each image into its own div */}
                          <img
                            src={image?.node?.originalSrc}
                            alt={image?.node?.altText || "img"}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "https://via.placeholder.com/283";
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#ccc",
                        }}
                      >
                        <NoProduct />
                      </div>
                    )}
                  </Slider>
                </div>

                <div className="hov_content">
                  <div className="d-flex   flex-column">
                    <div className="d-flex flex justify-content-between">
                      <h5 className="prd_name">{product?.title}</h5>
                      <div className="d-flex">
                        <span className="white_color"></span>
                        <span className="golden_color"></span>
                        <span className="red_color"></span>
                      </div>
                    </div>
                    <>
                      <p className="prd_price pt-1 pb-1">
                        ${product?.priceRange?.minVariantPrice?.amount}
                      </p>
                    </>
                  </div>

                  <div className="btn_div">
                    <button
                      className="info_btn"
                      onClick={() =>
                        navigate("/eternity-details", {
                          state: {
                            eternityProduct: { id: productIds }, // Use the extracted product ID here
                          },
                        })
                      }
                    >
                      More Info
                    </button>

                    <button
                      className="add_btn"
                      onClick={() =>
                        navigate("/eternity-details", {
                          state: {
                            eternityProduct: { id: productIds }, // Use the extracted product ID here
                          },
                        })
                      }
                    >
                      Add to Cart
                    </button>
                  </div>

                  <div className="note">
                    <p className="note">
                      Pay in 12 interest-free installments of $
                      <span>Learn more</span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Root>
  );
};

const Root = styled.section`

input.react-tagsinput-input {
    display: none;
  }

  .react-tagsinput {
    background-color: #fff;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
    border-right:none;
    border-left:none;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
   padding-bottom:20px;
   @media (max-width: 567px){
    display:flex;
    justify-content:center;

   }
  }

  .react-tagsinput-tag {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 1px solid #f7f7f7;
    color: #212529;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right:5px;
    padding:4px 15px;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    margin-left: 20px;
    font-size: 25px;
    text-decoration: none;
    color: #999;
    font-weight:400;
  }

  
  .bg_img {
    background-image: url(${bgg});
    height: 400px;
  }

  .main_div {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 4px;
    padding: 0 20px;
    .subdiv {
      width: 24vw;
      height: 57vh; /* slider changes */
      border: 3px solid #f7f7f7;
      border-radius: 20px;
      padding: 5px;
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;
      padding: 15px;

      /* slider changes */
      .slick-track {
        /* height:220px; */
        /* height: 88%; */
      }
      /* slider changes */
      .slick-prev {
        left: -2px;
        z-index: 111;
      }

      .slick-next {
        right: -2px;
        z-index: 111;
      }

      /* slider changes */
      .slick-prev:before,
      .slick-next:before {
        background: rgb(185 179 179);
        border-radius: 50px;
      }

      img {
        width: 100%;
        height: 88%;
        /*height: 220px;  slider changes */
        object-fit: cover;
      }

      &:hover {
        border: 3px solid black;
        overflow: unset;
        /* transform: scale(1.1); */
        /* z-index: 1; */
        padding: 15px;
      }
      .hov_content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .flex-column {
          display: flex;
          width: 100%;
        }
      }

      &:hover .hov_content {
        width: 24vw;
        z-index: 1;
        position: absolute;
        background-color: white;
        border: 3px solid black;
        padding: 0 20px 0;
        left: -3px;
        overflow: hidden;
        border-top: none;
        border-radius: 0 0 20px 20px;
        .prd_name {
          font-size: 14px;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
        }
      }

      .prd_name {
        font-size: 14px;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .prd_price {
        font-size: 13px;
        width: 100%;
      }
      .white_color,
      .golden_color,
      .red_color {
        height: 18px;
        width: 18px;
        text-align: center;
        border-radius: 50px;
        margin-left: 10px;
      }

      .white_color {
        background-color: #ebebeb;
      }

      .golden_color {
        background-color: #ffdfb0;
      }

      .red_color {
        background-color: #efcbcb;
      }

      .btn_div {
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        gap: 10px;
        .info_btn {
          flex: 1;
          padding: 12px 21px;
          border-radius: 25px;
          font-size: 13px;
          background-color: #fff;
          border: 2px solid black;
        }
        .add_btn {
          /* flex: 1; */
          background-color: black;
          border: 2px solid black;
          color: white;
          padding: 5px 17px;
          border-radius: 25px;
          font-size: 13px;
          flex: 1;
        }
      }

      .note {
        display: flex;
        justify-content: center;
        width: 100%;
        p {
          font-size: 10px;
          margin-top: 20px;
          text-align: center;
          margin-top: 8px;
        }
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .drawer-content {
    padding: 20px 0px;
    width: 100%;
  }

  .drawer-toggle-button {
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
  }

  @media (min-width: 877px) {
    .drawer-toggle-button {
      display: none;
    }
    .drawer-content {
      display: block;
    }
  }

  .EZDrawer__container {
    overflow-y: scroll !important;
    height: 67vh !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    padding-bottom: 40px;
  }
  @media (max-width: 567px) {
    .select_div .select_opt .select_metal .btn_row{
      display:flex;
      justify-content:center;
    }

    .select_div .select_opt .select_shape .btn_row{
      display:flex;
      justify-content:center;
    }

    .bg_img {
      background-image: url(${bgg});
      height: 160px;
      background-size: 100% 100%;
    }

    .drawer-toggle-button {
      display: block;
      margin-top: 20px;
      margin-left: 20px;
    }

    .drawer-content {
      /* display: none; */
      .ring_types {
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px;
        margin: 20px 0px 0px;
        width: 100%;
        button {
          width: 85px;
          border: 1px solid #d1d1d1;
          background-color: rgba(247, 247, 247);
          padding: 0px 3px;
        }
      }
    }

    .main_div {
      gap: 15px;
      margin-top: 0;
      z-index: 1;
      padding: 0;
      justify-content: center;
      .subdiv {
        width: 45vw;
        max-height: 207px;
        &:hover .hov_content {
          width: 45vw;
          padding: 0px 10px;
        }

        .prd_name {
          font-size: 12px;
          margin-bottom: 9px;
        }
        .prd_price {
          font-size: 11px;
          margin-bottom: 10px;
        }
        .white_color,
        .golden_color,
        .red_color {
          height: 10px;
          width: 10px;
        }
        .btn_div .info_btn,
        .btn_div .add_btn {
          flex: 1;
          font-size: 11px;
          padding: 3px 10px;
          border-radius: 15px;
        }
      }
    }

    .main_div .subdiv img {
      width: 100%;
      height: 133px;
      object-fit: unset;
    }

    .main_div .subdiv .note {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .bg_img {
      background-image: url(${bgg});
      height: 300px;
      background-size: 100% 100%;
    }

    .main_div {
      padding: 0 20px;
      gap: 15px;
      .subdiv {
        width: 45vw;
        max-height: 410px;

        &:hover .hov_content {
          width: 45vw;
          padding: 0px 10px;
        }
        .prd_name {
          font-size: 12px;
          margin-bottom: 9px;
        }
        .prd_price {
          font-size: 11px;
          margin-bottom: 10px;
        }
        .white_color,
        .golden_color,
        .red_color {
          height: 10px;
          width: 10px;
        }
        .btn_div .info_btn,
        .btn_div .add_btn {
          flex: 1;
          font-size: 11px;
          padding: 10px 10px;
          border-radius: 15px;
        }
      }
    }

    .main_div .subdiv img {
      width: 100%;
      height: 92%;
    }

    .drawer-toggle-button {
      display: none;
    }

    .main_div .subdiv img {
      width: 100%;

      height: 340px;
      object-fit: unset;
    }
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    .main_div {
      display: flex;
      justify-content: center;
      .subdiv {
        width: 24vw;
        max-height: 232px;
      }
    }

    .main_div .subdiv img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
`;

const StyledSection = styled.section`
  padding: 20px;
  @media (max-width: 567px) {
    padding: 0px;
  }
  .select_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 567px) {
      margin-top: 10px;
      gap: 30px;
      justify-content: space-around;
    }
    .select_opt {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .head_icon {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(247, 247, 247);
        border-radius: 0.375rem;
        padding: 10px 10px;
        border: 1px solid transparent;
        width: 150px;
        position: relative;
        h3 {
          font-size: 14px;
        }
      }

      .select_metal {
        position: absolute;
        left: 3%; /*tags*/
        bottom: -45%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 8px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 11px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 567px) {
          left: 7%;
          bottom: 15%;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        }

        /* @media (min-width: 567px) and (max-width: 1000px){
          left: 7%;
          bottom: 32%;
          border: 1px solid #fff;
          width: 51%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        } */
        @media (min-width: 567px) and (max-width: 1000px) {
          position: absolute;
          left: unset;
          bottom: unset;
          width: 55%;
          z-index: 11111;
          top: 50%;
        }
        /* @media (max-width: 1000px) {
          width: 90%;
          left: 3%;
          bottom: 6%;
        } */
      }

      .select_shape {
        position: absolute;
        left: 6%; /*tags*/
        bottom: -58%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 8px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 11px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }

        @media (max-width: 567px) {
          left: 7%;
          bottom: 0;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        }

        @media (min-width: 567px) and (max-width: 1000px) {
          position: absolute;
          left: unset;
          bottom: unset;
          width: 55%;
          z-index: 11111;
          top: 50%;
        }
      }
    }

    select {
      background-color: rgba(247, 247, 247);
      border-radius: 0.375rem;
      font-size: 14px;
      padding: 10px 10px;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
`;
