import React from "react";
import styled from "styled-components";


export default function Section3() {
    return (
        <Root>
            <div className="KeyzarInfo__article-content">
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                    <span >These terms of use (the “Terms”) are entered into between you and B H THE PRECIOUS EARTH INC. d/b/a THE PRECIOUS EARTH Jewelry, its parents, affiliates, and related companies ("THE PRECIOUS EARTH   ,” “we,” “us,” “our”). The following Terms govern your access to and use of the THE PRECIOUS EARTH website ThePreciousEarthjewelry.com, its microsites, mobile websites, mobile applications, and your use of interactive features, widgets, plug-ins, content, products, assets, downloads and/or other online services that we own and control or that post a link to these Terms (collectively, the “Website”).</span>
                </p>
                <p> 
                    <span >Please read these Terms carefully before you start to use the Website. BY USING THE WEBSITE YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS AND OUR PRIVACY POLICY FOUND </span>
                    <span >HERE</span>
                    <span  > WHICH IS INCORPORATED HEREIN BY REFERENCE. If you do not agree to these Terms or our Privacy Policy you must not access our Website.&nbsp;</span>
                </p>
                <p>
                    <span ></span>
                    <span >We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them. Your continued use of the Website following the posting of any revised Terms or Privacy Policy means you agree to the changes.</span>
                </p>
                <p>&nbsp;</p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_access_and_use" href="#" className="text-dark ">
                            <b>Access and Use of the Website.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <a id="link_to_access_and_use" href="#" className="text-dark texte">We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.&nbsp;</a>
                </p>
                <p>&nbsp;</p>
                <p className="g">
                    <span >To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or use of any interactive features on the Website, is governed by our Privacy Policy and you consent to all actions we take with respect to your information consistent with our Privacy Policy.&nbsp;</span>
                </p>
                <p >
                    <span >If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You acknowledge that your account is personal to you and agree not to provide any other person with access to this Website or portions of it using your user name, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security.&nbsp;</span>
                </p>
                <p>
                    <span >We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time, if, in our opinion, you have violated any provision of these Terms of Service.</span>
                </p>
                <p>
                    <span >The Precious Earth does not control or endorse the content, messages, or information found in any Interactive Feature and specifically disclaims any liability with regard to the Interactive Feature and any actions resulting from your use of the Interactive Feature. The Precious Earth reserves the right to review content posted to an Interactive Feature and to remove any content at any time without notice to you for any reason whatsoever.</span>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_intellectual" href="#" className="text-dark ">
                            <b>Intellectual Property Rights.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >All contents, features, and functionality (including but not limited to all information, software, text, trademarks, logos, displays, images, video, and audio, and the design, selection, and arrangement thereof) included in the Website are owned by The Precious Earth, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</span>
                </p>
                <p>
                    <span >You may not copy distribute, reproduce, mirror, frame, publicly display, publicly perform, translate, create derivative works, republish or transmit the Website, in whole or in part, or in any way without our prior written consent.&nbsp;</span>
                </p>
                <p>
                    <span >If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of these Terms, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website is transferred to you, and all rights not expressly granted are reserved by The Precious Earth. Any use of the Website not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.</span>
                </p>
                <p>
                    <span >You may not use any name, logo, tagline or other mark of The Precious Earth without our prior written consent.&nbsp;</span>
                </p>
                <p>&nbsp;</p>
                <p> <a id="link_to_third_party"href="#" className=" text-dark"> </a></p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_third_party" href="#" className="text-dark">
                            <b>Third Party Materials.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >The Website may contain certain content, features, links, and functionality that is provided by third parties (collectively, “Third Party Materials”) and is not under the control of The Precious Earth nor is The Precious Earth responsible for the accuracy, completeness, timeliness, or reliability of Third Party Materials. Your use of and interaction with such Third Party Materials may be subject to separate terms and conditions of that third party and The Precious Earth is not responsible for any form of transmission received from any Third Party Materials. The inclusion of Third Party Materials does not imply an endorsement by The Precious Earth or any association with the third party.</span>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_merchandise" href="#" className="text-dark">
                            <b>Merchandise and Orders.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >Among other things, The Precious Earth provides information concerning various products and services as well as the opportunity to obtain additional information regarding those products or purchases or to purchase them. Such information may contain errors, omissions, typographical errors or may be out of date and is provided for informational purposes only and is not binding on The Precious Earth in any way.&nbsp;</span>
                </p>
                <p>
                    <span >All descriptions, images, references, features, content, specifications, products, and prices of products and services mentioned or depicted on the Website are subject to change at any time without notice. Unless expressly stated otherwise, all prices displayed on the Website are quoted in U.S. dollars unless a change in currency is selected.&nbsp;</span>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_price" href="#" className="text-dark">
                            <b>Price and Payment.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >The Precious Earth will add shipping and handling fees, and applicable sales/use tax as necessary </span>
                    <b>in the United States only</b>
                    <span >. UNLESS OTHERWISE EXPRESSLY STATED IN WRITING YOU AGREE IT IS YOUR RESPONSIBILITY TO PAY ALL FEES AND CHARGES INCURRED IN CONNECTION WITH YOUR PURCHASE, INCLUDING ANY APPLICABLE TAXES (INCLUDING WITHOUT LIMITATION, INDIRECT TAXES, VALUE ADDED TAXES, FEES, DUTY, IMPORT/EXPORT CHARGES, OR ANY SIMILAR GOVERNMENTAL CHARGES NOT INCLUDED IN THE PRICE OF OUR PRODUCTS (COLLECTIVELY, “TAXES”), SHIPPING, HANDLING FEES, AND SURCHARGES AT THE RATES IN EFFECT WHEN THE CHARGES WERE INCURRED (THESE FEES, TOGETHER WITH “TAXES” ARE “FEES”).&nbsp;</span>
                </p>
                <p>
                    <span >CERTAIN FEES MAY NOT BE INCLUDED IN THE PRICE OF OUR PRODUCTS AND YOU ARE SOLELY RESPONSIBLE FOR PAYING ANY SUCH FEE IMPOSED ON YOUR PURCHASE. YOU AGREE TO INDEMNIFY AND HOLD HARMLESS THE PRECIOUS EARTH FROM ANY TAX LIABILITY OR PENALTIES THAT MAY ARISE FROM YOUR FAILURE TO FULFILL YOUR OBLIGATIONS HEREUNDER. YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT BE ENTITLED TO ANY REFUND OR CREDIT AS A RESULT OF YOUR FAILURE OR UNWILLINGNESS TO PAY THE FEES. WITHOUT LIMITING THE FOREGOING, THIS INCLUDES THE PRECIOUS EARTH’S THIRTY (30) DAY RETURN POLICY.&nbsp;</span>
                </p>
                <p>
                    <span >We reserve the right without notice to you to discontinue or change specifications, availability, and prices on products and services offered on the Website without any obligation to you. We reserve the right, for any reason and at any time, to accept or decline your order, or any portion thereof, even after your receipt of an order confirmation from us. If your credit card has already been charged for a purchase and we need to cancel your order, we will issue a credit to your credit card in a reasonable time frame.</span>
                </p>
                <p>
                    <span >We reserve the right without notice to you to discontinue or change specifications, availability, and prices on products and services offered on the Website without any obligation to you. We reserve the right, for any reason and at any time, to accept or decline your order, or any portion thereof, even after your receipt of an order confirmation from us. If your credit card has already been charged for a purchase and we need to cancel your order, we will issue a credit to your credit card in a reasonable time frame.</span>
                </p>
                <p>
                    <span >When ordering merchandise from The Precious Earth, you may be able to further personalize your purchase with an engraving or personal message (“Custom Content”). The Precious Earth reserves the right to omit, edit, and/or modify any and all Custom Content in our sole discretion. We are not liable for any damage or expense resulting from any errors, modifications, or omissions made in transcribing Custom Content.</span>
                </p>
                <a id="link_to_product" href="#" className="text-dark">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>

                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <a id="link_to_price" href="#" className="text-dark">
                                <b>Product Warranties.</b>
                            </a>
                        </span>
                    </h3>
                </a>
                <p class="warranty-text">
                    <span>The Precious Earth is proud to offer a lifetime warranty on all&nbsp;</span>
                    <b>complete</b>
                    <span>&nbsp;jewelry purchased in-store and online.</span>
                    We stand behind our products and will happily replace your item or offer you a refund equal to the original price (the “The Precious Earth Warranty”).
                </p>
                <p>
                    <span >The Precious Earth provides free inspection and refurbishment, as well as plating, during the natural lifetime of its products. The Precious Earth will advise customers about any parts that have been damaged or worn to the point of being beyond repair and restoration.&nbsp;</span>
                </p>
                <p>
                    <span >By purchasing a The Precious Earth product, you agree to the The Precious Earth Warranty. No signature or further action is required. The The Precious Earth Warranty applies from the date you receive a product for the first time and applies to common defects including loose prongs, cleaning, rhodium plating, etc. It does not apply to the application of abrasive materials, exposure to damaging temperatures and environments, third party repairs and alterations, misuse, negligence, or any materials provided by you.&nbsp;</span>
                </p>
                <p>
                    <span >The The Precious Earth Warranty does not cover cases of unusual, extreme, a wantonly damaging process, or gratuitous wear and tear. In these cases, you shall be liable for all damage caused. THE PRECIOUS EARTH CANNOT BE HELD LIABLE FOR JEWELRY THAT REQUIRES REPAIRS DUE TO NATURAL AND EXTRAORDINARY WEAR AND TEAR OR UNUSUAL, EXTREME, OR A WANTONLY DAMAGING PROCESS. Certain types of items are not covered by the THE PRECIOUS EARTH Warranty, such as, pendants, necklaces, bracelet chains, ear-backs, nose rings, and other non-standard body jewelry.</span>
                </p>
                <p>
                    <span >At the start of any warranty case, we start by inspecting a returned product with the help of a The Precious Earth jewelry expert. We appraise the repairs required and price any that aren’t covered by the The Precious Earth Warranty. We will then inform you about any paid repairs that may be necessary. We will not charge nor make paid alteration or repairs without your express permission. Once an order is made, we will cover shipping and insurance costs, however, all subsequent shipping and insurance costs are your responsibility.</span>
                </p>
                <p>
                    <span >If a defect is discovered, the jewelry must be returned to The Precious Earth for inspection. Any attempts to repair the jewelry can lead to a void of warranty. Any evaluations done on your own or by third parties may not be upheld by a The Precious Earth expert.</span>
                </p>
                <p>
                    <span >Any resizing, customizing, and otherwise altering to jewelry via third party will automatically void the The Precious Earth warranty.&nbsp;</span>
                </p>
                <p>
                    <span >The loss and/or theft of center stones are not covered by the The Precious Earth Warranty. To avoid disappointment, we strongly recommend returning jewelry to us for inspection at the first sign of possible misplacement. </span>
                </p>
                <p>
                    <a id="link_to_returns" href="#" className="text-dark"> </a>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <b>Returns.&nbsp;</b>
                    </span>
                </h3>
                <p>
                    <span >Most The Precious Earth products purchased on the Website may be returned for a full refund within thirty (30) days from the shipping confirmation date of your order and in accordance with our instructions. It is your responsibility to follow our instructions to best ensure that your The Precious Earth purchase is returned to us in perfect condition. Except for any items that we agree to insure, we are not responsible for any lost returns. Items sent for return 1) must be intact and in perfect condition, 2) if the jewelry contains a center diamond with a corresponding diamond certificate, the original certificate must be included, 3) the corresponding The Precious Earth Jewelry Appraisal must be sent back as well.&nbsp;</span>
                    Additionally, in order to qualify for full refund, customers are required to ship the order back up to 1 week after receiving a prepaid shipping label. Upon our receipt of your proper return, which The Precious Earth may determine in its sole and reasonable discretion whether you are eligible for a refund, if so, you will receive a full refund in your original form of payment. Monetary refunds will only be processed while the return is in our possession, and cannot be issued once your order is out for shipping or if it’s on its way back to our office. Please allow approximately fourteen (14) business days for us to process your return.&nbsp;
                </p>
                <p></p>

                <p>
                    <span >Any custom-made The Precious Earth orders, including engraved items, are not entitled to this return policy and are non-refundable.</span>
                </p>
                <p>&nbsp;</p>
                <h3 id="link_to_exchanges">
                    <span style={{ textDecoration: 'underline' }}>
                        <b>Exchanges</b>
                        <span >.</span>
                    </span>
                </h3>
                <p>
                    <span >Most The Precious Earth products purchased on the Website may be exchanged within thirty (30) days from the THE PRECIOUS EARTH shipping confirmation date of your order and in accordance with our instructions. Any custom-made The Precious Earth orders, including engraved items, are not entitled to this exchange policy. Items returned for exchange 1) must be intact and in perfect condition, 2) if the jewelry contains a center diamond with a corresponding diamond certificate, the original certificate must be included, 3) the corresponding The Precious Earth Jewelry Appraisal must be sent back as well.&nbsp;</span>
                    <span>Additionally, in order to qualify for full refund, customers are required to ship the order back up to 1 week after receiving a prepaid shipping label.&nbsp;</span>
                    Upon our receipt of your proper exchange, which The Precious Earth may determine in its sole and reasonable discretion, we will begin production of your new item. A refund or invoice will be issued according to the difference in price between your original and the exchanged items. Any monetary refund will only be processed while the exchanged item is in our possession, and cannot be issued once it is out for shipping or if your order is on its way back to our office. Exchanges past thirty (30) days from the shipping confirmation date are subject to be approved at the sole discretion of The Precious Earth.
                </p>
                <p>
                    <span >Please note: only one exchange is permitted on a purchase. All exchanges are final.&nbsp;&nbsp;</span>
                </p>
                <p>
                    <span >If you’ve placed a new order that has not yet been shipped, you may only change to a different item once. All changes to your order are final. Changes to your order cannot be made once the order is ready to ship, which is when you receive an email confirming the shipment of your order.</span>
                </p>
                <p>&nbsp;</p>
                <h3 id="link_to_resizing">
                    <span style={{ textDecoration: 'underline' }}>
                        <b>Re-Sizing.</b>
                        <span >&nbsp;</span>
                    </span>
                </h3>
                <p>
                    <span >Any rings bought directly from The Precious Earth are eligible for the first re-size free of charge (including related shipping and insurance costs) within twelve (12) months after the THE PRECIOUS EARTH shipping confirmation date of your order. You will be responsible for the costs associated with any subsequent re-sizing requests.</span>
                </p>
                <p>
                    <span >Any re-sizing must happen directly via The Precious Earth or an approved service center, and only after consulting with a The Precious Earth specialist. It is up to The Precious Earth to determine, in our sole and reasonable discretion, the degree to which a ring can be re-sized, and whether the alteration can happen under the lifetime warranty or otherwise.</span>
                </p>
                <p>
                    <span >Subsequent re-sizings, as well as difficult alterations, are chargeable. The specific costs are determined by our specialists on a case-by-case basis and relayed to the customer before any work is done.&nbsp;</span>
                </p>
                <p>
                    <span >Re-sizing a ring via a third party without consulting with The Precious Earth will automatically void the warranty. The Precious Earth cannot be held for any costs or damages incurred as a result of third party alterations.</span>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_user_contributions" href="#" className="text-dark">
                            <b>User Contributions.</b>
                        </a>&nbsp;</span>
                </h3>
                <p>
                    <span >The Website may contain reviews, testimonials, discussion forums, and other types of user generated content where you or other users may create, post, or store any content, messages, or other material on the Website (e.g. images, audio, video, logos, emails, texts etc.) (collectively, “User Contributions”). You understand and acknowledge that you are responsible and assume any risk for any User Contributions, including the legality, reliability, accuracy, and appropriateness of such content. The Precious Earth neither endorses nor is responsible for any User Contributions and will not be liable for any loss or damage caused by your reliance on such User Contributions.&nbsp;</span>
                </p>
                <p>
                    <span >By contributing User Contributions on the Website, you grant The Precious Earth, its service providers, licensees, successors and assigns, a non-exclusive, sub-licensable, fully paid, royalty free, perpetual, irrevocable, transferable right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties your User Contributions for any purpose. We reserve the right (but have no obligation), in our sole discretion, to: (i) monitor User Contributions; (ii) alter, remove, or refuse to post or allow to be posted any User Contributions; (iii) disclose any User Contributions, and the circumstances surrounding its transmission; and (iv) take appropriate legal action against any illegal or unauthorized User Contributions.&nbsp;</span>
                </p>
                <p>
                    <span >You agree that any User Contributions by you will not contain material that:&nbsp;</span>
                </p>
                <ol className="list-unstyled">
                    <li >
                        <span >is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, discriminatory or otherwise objectionable;&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >is false, misleading or inaccurate;&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >promotes sexually explicit content;&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >violates the legal rights (including the rights of publicity and privacy) of others or contains any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and our Privacy Policy;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >poses a reasonable threat to personal or public safety;&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >infringes any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person;&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li>
                        <span >contains any unsolicited or unauthorized advertising or promotional materials (e.g. spam, junk mail, or any other form of solicitation that is in violation of the CAN-SPAM Act, GDPR, or any other law; or&nbsp;</span>
                    </li>
                </ol>
                <ol className="list-unstyled">
                    <li >
                        <span >contains any virus or other harmful component.&nbsp;</span>
                    </li>
                </ol>
                <p><span >YOU SHALL BE SOLELY LIABLE FOR ANY HARM RESULTING FROM ANY OF THE ABOVE. YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS THE PRECIOUS EARTH FROM ANY CLAIMS, ACTIONS, LOSSES, HARM, DAMAGE, INJURY, COST, OR EXPENSE ARISING OUT OF OR RESULTING FROM YOUR USER CONTRIBUTIONS.</span></p>
                <p>
                    <a id="link_to_social_media" href="#" className="text-dark"> </a>
                </p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_social_media" href="#" className="text-dark">
                            <b>Social Media Features.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >This Website may provide certain social media features that enable you to:</span>
                </p>
                <ul>
                    <li >
                        <span >Link from your own or certain third-party websites;</span>
                    </li>
                    <li >
                        <span >Send emails or other communications with certain content, or links to certain content on this Website; or</span>
                    </li>
                    <li >
                        <span >Cause limited portions of this Website to be displayed or appear to be displayed on your own or certain third-party websites.</span>
                    </li>
                </ul>
                <p>
                    <span >You agree not to:</span>
                </p>
                <ul>
                    <li >
                        <span >Establish a link from any website that is not owned by you;</span>
                    </li>
                    <li >
                        <span >Cause the Website or portions thereof, to be displayed, or appear to be displayed on any other website; or</span>
                    </li>
                    <li >
                        <span >Otherwise take any action with respect to the content on this Website that is in violation of these Terms.</span>
                    </li>
                </ul>
                <p>
                    <span >You may use these features solely as they are provided by us and you must not take any action that is inconsistent with these Terms. We may disable all or any social media features and any links at any time without notice in our sole discretion. If you decide to use or access any of the third-party websites linked to this Website you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</span>
                </p>
                <h3>
                    <span >
                        <a id="link_to_messaging" href="#" className="text-dark">
                            <b>Messaging/Phone Calls Terms &amp; Conditions.</b>
                        </a>
                    </span>
                </h3>
                <p>
                    <span >We use a text messaging platform which is subject to the following terms and conditions. By opting-in for our text marketing and notifications (when you enter your phone number (i) in the checkout to initiate a purchase, (ii) via our subscription form, or (iii) when creating an account) you agree to these terms and conditions. You acknowledge that your consent is not a condition of any purchase.</span>
                </p>
                <p>
                    <span >By providing us with your phone number and/or subscribing to The Precious Earth text notifications, you agree to receive promotional phone calls and automated marketing text messages from us about our products and services, including (for your order, including abandoned cart reminders) and text marketing offers, at that that phone number. Messages may be sent via an automatic telephone dialing system or other technology and message frequency is recurring. Message and data rates may apply.&nbsp;</span>
                </p>
                <p>
                    <span >You certify that any phone number(s) you provide is true, accurate, and you are the current owner. You agree to immediately notify us of any change in ownership of such phone number(s). If we discover that this information is false or inaccurate, we may suspend or terminate your account at any time.</span>
                </p>
                <p>
                    <span >We reserve the right to revise our messaging program at any time. Any changes will be effective immediately upon posting to this Website. Your continued enrollment following such changes shall constitute your acceptance of such changes.</span>
                </p>
                <p>
                    <span style={{ textDecoration: 'underline' }}>
                        <b>Message and Data Rates May Apply.&nbsp;</b>
                    </span>
                </p>
                <p>
                    <span >There is no fee to receive The Precious Earth text notifications, however, you may incur a charge for these text messages from your telephone carrier which is your sole responsibility.&nbsp;</span>
                </p>
                <p>
                    <span >We reserve the right to alter the frequency of messages sent at any time and reserve the right to change the short code or phone number from which messages are sent.</span>
                </p>
                <p>
                    <span >Not all mobile devices or handsets may be supported and our messages may not be deliverable in all areas. The Precious Earth, its service providers, and mobile carriers supported by this program are not liable for delayed or undelivered messages.</span>
                </p>
                <p>
                    <span style={{ textDecoration: 'underline' }}><b>EU Residents&nbsp;</b>
                    </span>
                </p>
                <p>
                    <span >Your phone number, name, and purchase information will be shared with our SMS platform “SMSBump Inc.,” a European Union company with an office is Sofia, Bulgaria, EU. This data will be used for sending you targeted marketing messages and notifications. Upon sending the text messages, your phone number will be passed to a text messages operator to fulfill their delivery.</span>
                </p>
                <p>
                    <span >If you wish to unsubscribe from receiving text marketing messages and notifications reply with STOP to any mobile message sent from us or use the unsubscribe link we provide you within any of our messages. You understand and agree that alternative methods of opting-out, such as using alternative words or requests will not be accounted as a reasonable means of opting-out. For any questions please text “HELP” to the number you received the messages from. You can also contact us for more information. If you wish to opt-out please follow the procedures above.</span>
                </p>
                <p>
                    <span style={{ textDecoration: 'underline' }}>
                        <b>US Residents</b>
                    </span>
                </p>
                <p>
                    <span >Reply STOP, END, CANCEL, UNSUBSCRIBE or QUIT to opt-out and HELP for customer support. You may receive an additional text message confirming your decision to opt-out. You understand and agree that attempting to opt-out by any means other than texting the opt-out commands above is not a reasonable means of opting-out.</span></p>
                <h3>
                    <span style={{ textDecoration: 'underline' }}>
                        <a id="link_to_restrictions_on_use" href="#" className="text-dark">
                            <b>Restrictions on Use.</b>
                        </a>&nbsp;</span>
                </h3>
                <p>
                    <span >You agree not to access or use the Website for any purpose that is unlawful or prohibited by these Terms, including without limitation, to:</span>
                </p>
                <ol className="list-unstyled">
                    <li>
                        <span >transmit any information or message that contains unlawful, infringing, threatening, fraudulent, libelous, defamatory, obscene or abusive information or language;</span>
                    </li>
                    <li>
                        <span >falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</span>
                    </li>
                    <li>
                        <span >impersonate any person or entity without proper authorization from that person or entity;</span>
                    </li>
                    <li >
                        <span >falsely state or misrepresent your affiliation with any person or entity, including the origin of any information you provide;</span>
                    </li>
                    <li >
                        <span >reproduce, duplicate, copy, download, store, further transmit, disseminate, distribute, transfer, or otherwise exploit the Website, or any portion thereof without the prior written consent of The Precious Earth, except that content on the Website may be viewed, reproduced, and downloaded solely for your own authorized use as a paying customer and is not in use or shared in any way that is commercially prejudicial to or competitive with The Precious Earth.&nbsp;&nbsp;</span>
                    </li>
                    <li>
                        <span >use any device, software, or routine to interfere or attempt to interfere with the proper working of the Website, or attempt to probe, scan, test the vulnerability of, or breach the security of any The Precious Earth system or network;</span>
                    </li>
                    <li>
                        <span >interfere with or attempt to interfere with any software making up a part of the Website, by way of circumvention, reverse engineering, deciphering, decompiling, disassembling, decrypting, or otherwise;</span>
                    </li>
                    <li>
                        <span >introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technically harmful;</span>
                    </li>
                    <li>
                        <span >use any robot, spider, intelligent agent, other automatic device or manual process to search, monitor, or copy the Website, or the reports, data, information, content, software, products, or other materials on, generated by or obtained from the Website or any other unauthorized automated means to compile information without The Precious Earth’s permission, with the exception of generally available third-party web browsers;</span>
                    </li>
                    <li>
                        <span >obtain or attempt to gain unauthorized access to other computer systems, materials, information, or any services available on or through the Website;</span>
                    </li>
                    <li >
                        <span >use the Website in any manner that could damage or overburden any The Precious Earth server, or any network connected to any The Precious Earth server, as all servers have limited capacity and are used by many people;</span>
                    </li>
                    <li >
                        <span >upload or transmit any communication, software, or material that contains a virus or is otherwise harmful to The Precious Earth or our users’ computers and/or systems;</span>
                    </li>
                    <li >
                        <span >engage in any other conduct that restricts or inhibits any person from using or enjoying the Website, or that in our sole discretion, exposes us or any of our users, employees, affiliates, or any other third-party to any liability, damage, or detriment of any type;</span>
                    </li>
                    <li >
                        <span >&nbsp;advertise, offer to sell or buy any goods or services for any business purpose, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation; or</span>
                    </li>
                    <li>
                        <span >violate any applicable laws or regulations.</span>
                    </li>
                </ol>
                <h3>
                    <a id="link_to_disclaimer" href="#" className="text-dark">
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Disclaimer.</b>
                        </span>
                    </a>
                </h3>
                <p>
                    <span >YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE PRECIOUS EARTH NOR ANY PERSON ASSOCIATED WITH THE PRECIOUS EARTH MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, TIMELINESS, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. THE PRECIOUS EARTH DOES NOT WARRANT OR REPRESENT THAT THE WEBSITE WILL BE UNINTERUPTED, THAT DEFECTS WILL BE CORRECTED, OR THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.&nbsp;</span>
                </p>
                <p>
                    <span >TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span>
                </p>
                <p>
                    <span >TO THE FULLEST EXTENT PROVIDED BY LAW, THE PRECIOUS EARTH HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE, TITLE, SECURITY, COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE.</span>
                </p>
                <p>
                    <span >THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span>
                </p>
                <a id="link_to_limitation" href="#" className="text-dark">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Limitation on Liability.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE PRECIOUS EARTH, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, OR THE SERVICES INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR MALFUNCTION, ANY ALLEGED FAILURE OR PERFORMANCE ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OR DELAY IN SERVICE, OPERATION, OR TRANSMISSION OF THE WEBSITE, OR ANY ALLEGED TRANSMISSION OF COMPUTER VIRUS, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. IN NO EVENT SHALL THE PRECIOUS EARTH’S LIABIILTY EXCEED THE AMOUNT(S) PAID FOR THE PRODUCT OR SERVICE THAT IS THE SUBJECT OF THE CLAIM.</span>
                </p>
                <a id="link_to_indemnification" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Indemnification.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >You agree to defend, indemnify, and hold harmless The Precious Earth, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms or your use of the Website, including, but not limited to, your User Contributions, any use of the Website's content, services, and products other than as expressly authorized in these Terms, or your use of any information obtained from the Website.</span>
                </p>
                <a id="link_to_governing" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Governing Law and Jurisdiction.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >All matters relating to the Website and/or these Terms, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of the State of New York without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).</span>
                </p>
                <p>
                    <span >Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of New York. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span>
                </p>
                <a id="link_to_arbitration" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Arbitration.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >At our sole discretion, we may require you to submit any disputes arising from these Terms or use of the Website, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying New York law.</span>
                </p>
                <a id="link_to_class" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Class Action Waiver.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >You expressly waive any ability to maintain any class action in any forum. Any arbitration, claim, or other proceedings between you and The Precious Earth shall be conducted on an individual basis and not in any class action, mass action, or on a consolidated or representative basis.&nbsp;</span>
                </p>
                <a id="link_to_waiver" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Waiver and Severability.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >No waiver by The Precious Earth of any term or condition set out in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of The Precious Earth to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.</span>
                </p>
                <p>
                    <span >If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.&nbsp;</span>
                </p>
                <a id="link_to_entire" href="#" className=" text-dark">
                    <p>&nbsp;</p>
                    <h3>
                        <span style={{ textDecoration: 'underline' }}>
                            <b>Entire Agreement.</b>
                        </span>
                    </h3>
                </a>
                <p>
                    <span >These Terms, our Privacy Policy, and any operating rules for the Website or The Precious Earth services established by us constitute the entire agreement between you and The Precious Earth with respect to the subject matter hereof and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.&nbsp;</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </div>
        </Root >
    )
}
const Root = styled.section`
.KeyzarInfo__article-content p {
    margin-bottom: 2rem;
    font-size: 13px;
    line-height: 1.6;
}
    .g{
    margin-bottom:5px;
    }
    h3 span a b{
    font-size:18px;
    }
    .texte{
    font-weight:500;
    }
        .warranty-text {
            font-weight: 400;
        }
        .warranty-text b {
            font-weight: bold;
        }

  h3 span b{
    font-size:18px;
    }
   ul ol {
        font-size: 13px;
margin:0
    }
ul li::marker {
    color: #f38da4;
}
    li span{
        font-size: 13px;
    }
`;