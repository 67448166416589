import styled from "styled-components";
import React from "react";
import aeroplane from "../../Images/aeroplane.png";
import badgess from "../../Images/badgess.png";
import moneyinhand from "../../Images/moneyinhand.png";
import certifiedd from "../../Images/certifiedd.png";
import pinkimg from "../../Images/pink.PNG";
import modimg from "../../Images/modimg.PNG";
import noimg from "../../Images/eligantPacking.png";
import { useLocation, useNavigate } from "react-router-dom";
import RingShipReturn from "../DiamondDetails/RingShipReturn";
import Slider from "react-slick";
// import ww from "../../Images/ww.webp";
import { Section4 } from "./Section4";
import { useSelector } from "react-redux";

export default function Section2() {
  const location = useLocation();
  const { products } = location.state || {};
  const navigate = useNavigate();
  const productDetail = useSelector((state) => state.users.productDetail);
  console.log(productDetail, "productDetail");
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Root>
      <div className="main_div">
        <div className="image_div">
          <img src={products?.mainImage || noimg} alt="img" />
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button className="button"></button>
          </div>
        </div>

        <div className="des_div">
          <div className="title">
            <h2>{products?.title}</h2>
            <h4>{products?.priceRange?.maxVariantPrice?.amount}{products?.priceRange?.maxVariantPrice?.currencyCode}</h4>
            <p>    
              {products?.description && products.description}
              {!products?.description && "No Description About Product"}
            </p>
          </div>

          <div className="product_btn">
            <button
              className="btn"
              onClick={() => {
                navigate("/naturaldiamond");
              }}
            >
              Add Central Stone
            </button>
          </div>

          <div className="policy">
            <div className="policy_type">
              <img src={aeroplane} alt="aeroplane_images" />

              <p>
                Overnight <br />
                Shipping
              </p>
            </div>

            <div className="policy_type">
              <img
                src={badgess}
                alt="badgess_images"
                style={{ width: "50px", height: "50px" }}
              />
              <p>
                Lifetime <br></br>Warranty
              </p>
            </div>

            <div className="policy_type">
              <img src={moneyinhand} alt="moneyinhand_images" />
              <p>
                30 Days <br></br>Free Return
              </p>
            </div>

            <div className="policy_type">
              <img src={certifiedd} alt="certifiedd_images" />
              <p>
                Certificate<br></br>& Appraisal
              </p>
            </div>
          </div>

          <div className="setting_detail">
            <h4>Setting Details</h4>
            <div className="profile_div">
              <div className="profile_cont">
                <img src={pinkimg} alt="pinkimg" />
                <p>Profile</p>
              </div>
              <h4>Low</h4>
              <img src={modimg} alt="modimg" className="ring_img" />
              <p className="para">Only stacks with a chevron/curved band</p>
            </div>
          </div>

          <div>
            <RingShipReturn />
          </div>

          <div className="appointment">
            <h5>Virtual Appointment</h5>
            <p>
              <strong>See jewelry</strong> up close with a personal appointment.
              Explore engagement rings, diamonds, and fine jewelry in person
              through your device.
            </p>
            {/* <button>Book Appointment</button> */}
          </div>
        </div>
      </div>

      <div className="car_div">
        <h2>Match Made in Heaven</h2>
        <div className="slider-container">
          <Slider {...settings}>
            <div
              className="prod_sect"
              onClick={() => {
                navigate("/ringdetails");
              }}
            >
              {productDetail?.map((pro) => (
                <>
                  <div className="imags_div">
                    <Section4 pro={pro}/>
                  </div>

                  <div className="cont_div">
                    <h5 className="prd_name">{pro?.title}</h5>
                    <p className="prd_price pt-1 ">
                      {pro?.priceRange?.maxVariantPrice?.amount} {pro?.priceRange?.maxVariantPrice?.currencyCode}
                    </p>
                    <div className="color_div">
                      <span className="white_color"></span>
                      <span className="golden_color"></span>
                      <span className="red_color"></span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </Slider>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 20px;

  .main_div {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-between;
    .image_div {
      flex: 1;
      border: 1px solid #d3d3d3;
      padding: 20px 20px 0px 20px;
      height: 613px;
      border-radius: 20px;
      flex-direction: column;
      height: 630px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40vw;
      }

      button.button {
        background-color: white;
        border: none;
        border-top: 1px solid #d3d3d3;
        border-radius: 0 0 20px 20px;

        svg {
          width: 18px;
          margin-right: 5px;
        }
        span {
          font-size: 13px;
        }
      }
    }

    .des_div {
      flex: 1;
      padding: 10px 30px;
      margin: 0px 10px;
      .title {
        h2 {
          font-size: 23px;
          font-weight: 600;
          line-height: 1.2;
        }
        h4 {
          font-size: 21px;
          color: #666666;
          font-weight: 500;
          line-height: 1.25;
        }
        p {
          font-weight: 400;
          color: grey;
          margin-top: 20px;
          font-size: 13px;
        }
      }

      .stone_shape {
        margin-top: 20px;
        h4 {
          font-size: 16px;
          color: #000000;
          font-weight: 700;
        }
        .stone_images {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          img {
            width: 40px;
            cursor: pointer;
          }
        }
      }
    }

    .product_btn {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 50px;
      .btn {
        background-color: rgba(0, 0, 0);
        color: white;
        font-size: 17px;
        padding: 16px 0;
        font-weight: 600;
        border-radius: 50px;
        border: 1px solid transparent;
      }
    }

    .policy {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .policy_type {
        flex: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 35px;
          height: 35px;
        }
        p {
          font-size: 14px;
          color: #333;
          margin-top: 10px;
          text-align: center;
          line-height: 16px;
        }
      }
    }

    .setting_detail {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
      h4 {
        font-size: 16px;
        color: #000000;
        font-weight: 700;
      }
      .profile_div {
        width: 184px;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 12px;
        padding: 10px;
        display: flex;
        gap: 8px;
        flex-direction: column;
        .profile_cont {
          display: flex;
          gap: 10px;
          align-items: center;
          p {
            font-size: 12px;
            color: #bbb9b9;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        h4 {
          font-size: 24px;
        }
        .ring_img {
          width: 70px;
        }
        .para {
          font-size: 13px;
          color: #666666;
          margin-bottom: 0;
        }
      }
    }

    .appointment {
      background-color: rgba(247, 247, 247);
      padding: 20px;
      border-radius: 20px;
      margin-top: 25px;
      /* margin-bottom:40px; */
      h5 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        margin-bottom: 0;
      }
      button {
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.2;
        font-weight: 400;
        text-decoration: underline;
        border: 1px solid transparent;
        margin-top: 10px;
        padding: 0;
        color: #000000;
      }
    }
  }

  .slick-track {
    display: flex;
    gap: 20px;
  }

  .car_div {
    padding: 30px 50px 30px 30px;
    h2 {
      color: rgba(0, 0, 0);
      font-size: 28px;
      margin-bottom: 30px;
    }
    .prod_sect {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 350px;
      cursor: pointer;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      .imags_div {
        height: 70%;
        padding: 15px 0 0 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }

      .cont_div {
        padding: 10px;
        .prd_name {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 0;
        }
        .prd_price {
          font-size: 13px;
          margin-bottom: 0;
        }
        .color_div {
          display: flex;
          gap: 10px;
          margin-top: 8px;
          .white_color {
            background-color: #ebebeb;
            height: 18px;
            width: 18px;
            text-align: center;
            border-radius: 50px;
          }
          .golden_color {
            background-color: #ffdfb0;
            height: 18px;
            width: 18px;
            text-align: center;
            border-radius: 50px;
          }

          .red_color {
            background-color: #efcbcb;
            height: 18px;
            width: 18px;
            text-align: center;
            border-radius: 50px;
          }
        }
      }
    }
    .slick-prev {
      display: none !important;
    }

    .slick-next {
      display: none !important;
    }
  }

  @media (max-width: 567px) {
    padding: 10px 0px;
    .main_div {
      gap: 0px;
      margin: 20px;
    }

    .main_div .image_div {
      width: 100%;
      height: unset;
      padding: 5px;
      margin: 10px;
    }
    .main_div .des_div .prod_spec {
      display: none;
    }
    .main_div .des_div {
      width: 100%;
      margin-top: 20px;
      padding: 5px;
    }
    .main_div .setting_detail .setting_info .setting_div {
      flex: 1;
    }
    .main_div .des_div .prod_spec .spec {
      padding: 0px 14px;
    }
    .main_div .des_div .prod_spec {
      gap: 20px;
    }
    .main_div .des_div .title h2,
    .main_div .des_div .title h4 {
      font-size: 18px;
    }

    .main_div .product_btn {
      margin-top: 0;
    }

    .main_div .policy .policy_type p {
      font-size: 10px;
    }

    .main_div .image_div img {
      width: 75vw;
      padding-top: 15px;
    }

    .slick-track {
      display: flex;
      gap: 20px;
    }

    .car_div .prod_sect .imags_div {
      height: 190px;
      img {
        width: 100%;
        height: 140px;
        border-radius: 10px;
        object-fit: contain;
        aspect-ratio: 3/2;
      }
    }
    .car_div {
      padding-top: 10px;
      h2 {
        font-size: 24px;
      }
    }

    .car_div .prod_sect {
      height: 300px;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .main_div {
      display: unset;
    }

    .main_div .image_div {
      width: 100%;
      height: unset;
    }
    .main_div .des_div {
      width: 100%;
      margin-top: 20px;
    }
    .main_div .des_div h2 {
      font-size: 18px;
    }
    .main_div .setting_detail .setting_info .setting_div {
      flex: 1;
    }

    .main_div .image_div img {
      width: 90vw;
    }
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    .car_div .prod_sect .imags_div {
      height: 230px;
      border: 1px solid #e0e0e0;
      padding: 15px 0 0 0;
      border-radius: 10px;
    }
  }
`;
