import React from 'react'
import Section1 from './Section1'

export default function Checkout() {
  
  return (
    <div>
      <Section1/>
    </div>
  )
}
