import React from "react";
import styled from "styled-components";


export default function Section2() {
    return (
        <Root>
            <div className="KeyzarInfo__article-content-container">
                <div className="KeyzarInfo__article-content">
                    <div className="text-left fw-bolder ">THE PRECIOUS EARTH PRIVACY POLICY</div>
                    <div className="text-left ">
                        <em>Effective: 1 February&nbsp;</em>2023</div>
                    <p className="text-left">&nbsp;</p>
                    <p>
                        <span >BH The Precious Earth Inc. d/b/a The Precious Earth Jewelry, its parents, affiliates, and related companies (“The Precious Earth,” “we,” “us,” “our”) value transparency and respect your privacy. Please read this policy carefully. This Privacy Policy describes the ways we collect, use, and share the information you may provide to us through the The Precious Earth website The Precious Earth.com, its microsites, mobile websites, mobile applications, and your use of interactive features, widgets, plug-ins, content, products, assets, downloads and/or other online services that we own and control or that post a link to these Terms (collectively, the “Website”) and rights you may have relating to such information.&nbsp;</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p>
                        <span >This policy is effective as of the date stated at the top of this page and may be updated from time to time to keep with The Precious Earth developments or evolving legal or regulatory requirements, so please check this policy periodically for updates. By accessing the Website you acknowledge and agree to the practices described in this Privacy Policy. If you do not agree with this Privacy Policy you may not use our Website.</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p><b>Information we collect and how we use it.</b></p>
                    <p>
                        <span >This section describes the types of personal information we may collect about you. Such information is generally collected either (i) directly from your voluntary engagement with the Website or The Precious Earth services; or (ii) automatically from you when you interact with the Website.</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p>
                        <span >The following categories of personal information we may collect from you includes:</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <span >Contact and account information. </span>
                            <span >In order to set up an account we may collect your name, phone number, address, and email address.</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <span >Purchase information. </span>
                            <span >To complete a purchase from The Precious Earth, we may collect your credit card information, billing address, shipping address, or other payment information. If you need to apply for credit, we may collect further information such as social security number and employment-related information to facilitate connecting you with a credit vendor.&nbsp;</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <span >Feedback information.</span>
                            <span > To receive and respond to your customer service requests or comments on your experience with The Precious Earth, we may collect your name, phone number, address, email address, images, and any relevant history.&nbsp;</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <span >Personalization information. </span>
                            <span >We may collect information you provide regarding your purchasing interests, birth date, anniversary date, wedding date, demographics such as gender, occupation, income, and marital status to enhance your experience with us.</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <i>
                                <span>Device Information:</span>
                            </i>
                            <span > When you interact with our Website, we may automatically collect information about the computers, phones, and other devices you are using (e.g. IP address, WiFi information, device attributes, operating system version, etc.).</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <ul>
                        <li>
                            <span >Website Usage: </span>
                            <span >So that we may understand and improve on the user experience and functionality of the Website, we may collect information about how you interact with the Website such as mouse movements, clicks, keystrokes, the date of your visit, the time spent on pages of the Website, or other actions.</span>
                        </li>
                    </ul>
                    <p className="text-left">&nbsp;</p>
                    <p>
                        <span >Personal information that is automatically collected may be obtained through cookies and similar technologies. Cookies are small files that are stored on your computer or mobile device through a user ID or other preferences. We use cookies to personalize your experience when you use the Services (e.g. auto-populating your log-in credentials or settings). You are free to set your browser or operating system settings to limit certain tracking or to decline cookies, but by doing so, you may not be able to use certain features through our Website or take full advantage of all our services.&nbsp;</span>
                    </p>
                    <p>
                        <span >We currently do not honor Do Not Track signals from internet browsers; when a universal standard for processing them emerges, we will follow it.</span>
                    </p>
                    <p>
                        <span >Note that third-party sites that are linked to the Services may set cookies or other technologies to collect information about your use and interaction with their websites or content. We do not have access to, or control, over such third-party data collection.&nbsp;</span>
                    </p>
                    <p><b>What we do with your information.</b></p>
                    <p>
                        <span >We use and/or share your information to provide the The Precious Earth services, maintain your accounts, process and fulfill purchases and/or returns, provide customer service and support, policy updates, and chances for you to give us feedback.&nbsp; We also use your information to better understand and improve the customer experience when accessing or using the Website and The Precious Earth services.</span>
                    </p>
                    <p>
                        <span >We limit access to your personal information to our employees, contractors, and agents who have a legitimate need to use it such as payment processors to complete a purchase such as Klarna. In order to offer you Klarna’s payment methods, we might in the checkout pass your personal information in the form of contact and order details to Klarna, in order for Klarna to assess whether you qualify for their payment methods and to tailor those payment methods for you. Your personal data transferred is processed in line with Klarna’s own </span>
                        <a href="#" className="text-dark">
                            <span >privacy notice.</span>
                        </a>
                        <span > We may also share your information in order to comply with applicable law or respond to a valid legal process or to enforce or investigate potential violations of our Terms of Use or other policies. In some cases, we may work with third-party service providers to offer support, improvements, and technical infrastructure to the Website.&nbsp;</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p><b>Interest-Based advertising.</b></p>

                    <p>
                       
                        <span className="text-dark"> and the Digital Advertising Alliance  </span>
                        
                        <span >. To opt-out of some of our advertising partners’ use of cookies in this manner. </span>
                        
                    </p>
                    <p><b>Security and retention.</b></p>
                    <p>
                        <span >We value your trust in providing us with your personal information and strive to use commercially acceptable means to protect it. Please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</span>
                    </p>
                    <p>
                        <span >We will keep your personal information as long as reasonably necessary to fulfill the purposes for which it was collected. In some circumstances we may retain your personal information for longer if and when required (e.g. legal, tax, or accounting requirements).&nbsp;</span>
                    </p>
                    <p><b>Links to other sites.</b></p>
                    <p>
                        <span >The Website may contain links to other sites for your convenience only. If you click on a third party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the applicable privacy policy of these third party websites. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party websites or services.</span>
                    </p>
                    <p><b>Children.</b></p>
                    <p>
                        <span >The Website and The Precious Earth services are not intended for anyone under the age of 13. We do not knowingly collect personal information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we will immediately delete such information from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us at </span>
                        <a href="#" className="text-dark ">
                            privacy@xyz.com
                        </a>
                        <span >.&nbsp;</span>
                    </p>
                    <p className="text-left">&nbsp;</p>

                    <p><b>Data Privacy Rights Based on Location.</b></p>
                    <p className="text-left">&nbsp;</p>

                    <p><b>ADDITIONAL RIGHTS FOR EUROPEAN UNION AND UNITED KINGDOM RESIDENTS</b></p>
                    <p><b>If you are an EU or UK resident</b>
                        <span >, certain rights may be afforded to you under the General Data Protection Regulation (GDPR) and as incorporated and amended into UK domestic law (UK GDPR)(including access, rectification, erasure, restriction of processing, data portability, and the right to object and to complain). If applicable, in the event we transfer your personal data to countries that do not meet GDPR or UK GDPR requirements, we will ensure that the recipient provides adequate protection by entering into Standard Contractual Clauses approved by the European Commission. These rights include:</span>
                    </p>
                    <ul>
                        <ul>
                            <li>
                                <b>The Right to Access:</b>
                                <span > You have the right to learn if your personal information is being processed by The Precious Earth, including which categories and specific pieces of personal information we have.</span>
                            </li>
                            <li>
                                <b>The Right to Rectify:</b>
                                <span > You have the right to verify the accuracy of your personal information and ask for it to be updated or corrected.</span>
                            </li>
                            <li>
                                <b>The Right to Delete:</b>
                                <span > You have the right, under certain circumstances, that we erase or otherwise remove your personal information from The Precious Earth.</span>
                            </li>
                            <li>
                                <b>The Right to Transfer:</b>
                                <span > You have the right to receive your data in a structured, commonly used and machine-readable format and, if technically feasible, to have it transmitted to another processor without any hindrance. This right is applicable provided that the data is processed by automated means and the processing is based on your consent or a contract which The Precious Earth is a part of.</span>
                            </li>
                            <li>
                                <b>No Discrimination:</b>
                                <span> You have the right not to be discriminated against for exercising the rights described above.</span>
                            </li>
                        </ul>
                    </ul>
                    <b>
                        <li>Not be subject to automated decision-making, including profiling.</li>
                    </b>
                    <p>
                        <span >You may exercise your EU privacy rights by emailing </span>
                        <a href="#"  className="text-dark"><span >privacy@xyz.com</span>
                        </a>
                        <span >. We will handle verified requests to access, correct, or delete your information within thirty (30) days unless the request is unusually extensive or complex, in which case we will inform you of the expected timeline for handling your particular request.&nbsp;</span>
                    </p>
                    <p>
                        <span>You also have the right </span>
                        <b>to lodge a complaint</b>
                        <span > before a competent data protection authority in  the EU or the UK.</span>
                    </p>
                    <p><b>Legal Bases for Processing</b></p>
                    <p>
                        <span >We process your personal information based on one of the following legal conditions:</span>
                    </p>
                    <ul>
                        <li> <span>Your consent (e.g. to place cookies which process personal information, or marketing)</span></li>
                        <li ><span >To perform our agreement with you (e.g. to ship your order)</span></li>
                        <li ><span >To comply with a legal obligation to which we are subject; and</span></li>
                        <li><span >Our legitimate interests, provided they do not override your fundamental rights and freedoms (e.g. for direct or behavioral marketing purposes, to expand our business activities, to generate aggregated statistics about our customer’s data and our services, which you may opt-out at any time).</span></li>
                    </ul>
                    <p><b>ADDITIONAL RIGHTS FOR CALIFORNIA RESIDENTS</b></p>
                    <p><b>If you are a California resident</b><span >, certain rights may be accorded to you under the California Consumer Privacy Act (CCPA) and its amendments under the California Privacy Rights Act (CPRA), including:&nbsp;</span></p>
                    <b>
                        <li>The Right to Access: <span >You may request, up to 2 times each year, that we disclose to you the categories and specific pieces of information that we have collected about you.</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Delete: <span >You may request that we delete any personal information that we have collected from or about you.&nbsp;</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Correct Inaccurate Personal Information: <span>You may request, that we correct your incorrect information with us.</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Opt-Out of Sharing Your Personal Information.&nbsp;</li>
                    </b>
                    <p><span >While The Precious Earth does not generally “sell” information as the term is traditionally understood, sharing data with certain advertising technology may be interpreted under California law as a sale. IF YOU DO NOT WANT US TO SELL OR SHARE YOUR PERSONAL INFORMATION PLEASE FILL OUT THE REQUEST FORM </span>
                    <span >We have fifteen (15) calendar days to implement a request to opt-out.</span></p>
                    <p><span>Alternatively, opt-out preference signals are sent from a platform, technology, or mechanism, on a consumer’s behalf, to communicate to us, your wish to opt-out of selling or sharing of your personal information.&nbsp;</span></p>
                    <p><span >We are not required to process any opt-out preference signal, only those that meet the following requirements:</span></p>
                    <b>
                        <li><span >the signal shall be in a format commonly used and recognized by businesses, for example, an HTTP header field; AND</span></li>
                    </b>
                    <b>
                        <li><span >the platform, technology, or mechanism sending out the opt-out preference shall make clear to you that the use of the signal will have the effect of opting you out of the sale and sharing of your personal information.</span></li>
                    </b>
                    <b>
                        <li>The Right to Limit the Disclosure of Sensitive Personal Information</li>
                    </b>
                    <b>
                        <li>
                            <span >IF YOU WANT TO LIMIT OUR DISCLOSURE OF YOUR PERSONAL INFORMATION PLEASE FILL OUT THE REQUEST  </span>
                            
                        </li>
                    </b>
                    <p><span>In accordance with the CCPA, in addition to the details described above, we make the following disclosures:</span></p>
                    <ul>
                        <li ><span >We collect the following categories of personal information: identifiers/contact information, commercial information, internet or other electronic network activity information, location, visual and audio information, and any inferences drawn from the foregoing.</span></li>
                        <li ><span >We disclose the following categories of personal information for our business purposes: identifiers/contact information, financial information, internet or other electronic network activity information, location, visual and audio information, and any inferences drawn from the foregoing.&nbsp;</span></li>
                    </ul>
                    <p><span >You may exercise your California privacy rights by emailing </span>
                        <a href="#" className="text-dark">
                            <span>privacy@xyz.com</span>
                        </a>
                        <span >. We will handle verified requests to access, correct, or delete your information within forty-five (45) days unless the request is unusually extensive or complex, in which case we will inform you of the expected timeline for handling your particular request. We do not discriminate against any California residents who exercise any of their rights described in this Privacy Policy.</span>
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p><b>ADDITIONAL RIGHTS FOR NEVADA RESIDENTS</b></p>
                    <p>
                        <b>If you are a</b>
                        <b>Nevada resident</b>
                        <span >, certain rights may be afforded to you under the Nevada Privacy Act and its amendments under the Nevada Privacy Law and Revised Statutes SB 220 (“SB 220”). You may exercise your SB 220 rights (listed below) by emailing </span>
                        <a href="#"  className="text-dark">
                            <span >privacy@xyz.com</span>
                        </a>
                        <span >. We will confirm your request within sixty (60) calendar days unless the request is unusually extensive or complex, in which case we will inform you of the expected timeline for handling your particular request. We do not discriminate against any Nevada residents who exercise any of their rights described in this Privacy Policy.</span>
                    </p>
                    <b>
                        <li>The Right to Opt-Out of the Sale or Sharing of Personal Information.
                            <span >IF YOU DO NOT WANT US TO SELL OR SHARE YOUR PERSONAL INFORMATION PLEASE FILL OUT THE REQUEST  </span>
                            
                        </li>
                    </b>
                    <p><b>ADDITIONAL RIGHTS FOR VIRGINIA RESIDENTS</b></p>
                    <p>
                        <b>If you are a</b>
                        <b>Virginia resident</b>
                        <span>, certain rights may be afforded to you under the Virginia Consumer Data Protection Act (VCDP). You may exercise your Virginia privacy rights (listed below) by emailing privacy@xyz.com.</span>
                        <span>We will confirm your request to delete, request, to correct within and generally carry-out such requests within forty-five (45) calendar days unless the request is unusually extensive or complex, in which case we will inform you of the expected timeline for handling your particular request. We do not discriminate against any Virginia residents who exercise any of their rights described in this Privacy Policy. Please note that we may charge a reasonable fee for requests that are unfounded, excessive, or repetitive.&nbsp;</span>
                    </p>
                    <b>
                        <li>The Right to Access:
                            <span>You may request, up to 2 times per year, that we disclose to you the categories and specific pieces of information that we have collected about you and our sources for such information.</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Delete:
                            <span >You may request that we delete any personal information that we have collected from or about you.&nbsp;</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Correct Inaccurate Personal Information:
                            <span >You may request, that we provide you with the name of the </span>
                            <span >provider</span>
                            <span> that shared your incorrect information with us.</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Correct Inaccurate Personal Information:
                            <span>You may request, that we provide you with the name of the </span>
                            <span >provider</span>
                            <span > that shared your incorrect information with us.</span>
                        </li>
                    </b>
                    <b>
                        <li>The Right to Opt-Out of the Sale or Sharing of Personal Information.&nbsp;</li>
                    </b>
                    <p>
                        <span>IF YOU DO NOT WANT US TO SELL OR SHARE YOUR PERSONAL INFORMATION PLEASE FILL OUT THE REQUEST  </span>
                       
                        <span >We have forty-five (45) calendar days to implement a request to opt-out.</span>
                    </p>
                    <p>
                        <span>If we reasonably decline your request, you will be able to appeal our decision by emailing us at </span>
                        <a href="#" className="text-dark">
                            <span>privacy@xyz.com</span>
                        </a>
                        <span > and we will make a decision within sixty (60) days of your appeal.</span>
                    </p>
                    <p><b>Contact us.</b></p>
                    <p>
                        <span >If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us by email at </span>
                        <a href="#" className="text-dark">
                            <span >privacy@xyz.com</span>
                        </a>
                       
                    </p>
                    <p className="text-left">&nbsp;</p>
                    <p className="text-left">&nbsp;</p>
                    <p className="text-left">&nbsp;</p>
                    <p className="text-left">&nbsp;</p>
                </div>
            </div>
        </Root>
    )
}
const Root = styled.section`
  .KeyzarInfo__article-date{
  display: inline-block;
    font-size: .75rem;
    line-height: 1;
    color: #c5b2a7;
    background: #fff3ec;
    border-radius: 3px;
    padding: .3125rem .5rem;
    font-weight: 500;
    margin-bottom: .75rem;
    }
  .KeyzarInfo__article-content{
  font-size:12px
  }
   ul li::marker {
    color: #f38da4;
}
    .KeyzarInfo__article-content ul li {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 1em;
}
    .KeyzarInfo__article-content ul li:before {
    content: "";
    position: absolute;
    width: .375rem;
    height: .375rem;
    border-radius: 50%;
    background: #f38da4;
    top: .5em;
    left: -.75rem;
    transform: translate(-100%);
}
    ul li b, ul li span, b li {
    font-size:12px;
    }
    p{
    font-size:12px
    }
    `