import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Section1 from "./Section1";
import bannerImage from "../../Images/girlbanner.webp";
import Star from "../../Images/new-review-star-medium.png";

export default function Contact() {
    return (
        <Root>
            <div className="crpb-wrapper d-none d-md-block">
                <div className="crpb-image-container">
                    <img className="crpb-image img-fluid" src={bannerImage} alt="The Precious Earth CUSTOMER REVIEWS" />
                    <div className="crpb-text-container">
                        <div className="text-container d-flex flex-column px-3 px-md-5">
                            <h1 className="crpb-title text-dark">OUR CUSTOMER'S REVIEWS</h1>
                            <div className="crpb-text text-dark mt-3">
                                <p>Check out these first-hand reviews or visit us on 
                                    <a href="https://www.trustpilot.com/review/www.xyz.com" title="Trustpilot Review" className="text-dark"> Trustpilot</a>.
                                </p>
                            </div>
                            <div className="crpb-stars d-flex gap-1 mt-3">
                                <img src={Star} alt="review-star" className="crpb-star"/>
                                <img src={Star} alt="review-star" className="crpb-star"/>
                                <img src={Star} alt="review-star" className="crpb-star"/>
                                <img src={Star} alt="review-star" className="crpb-star"/>
                                <img src={Star} alt="review-star" className="crpb-star"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Section1 />
        </Root>
    );
}
const Root = styled.section`
    background-color: #f8f9fa;

    .crpb-wrapper {
        position: relative;
        overflow: hidden;
    }

    .crpb-image-container {
        position: relative;
        width: 100%;
    }

    .crpb-image {
        width: 100%;
        height: auto;
        display: block;
    }

    .crpb-text-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 60%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        
        @media (max-width: 767.98px) {
            width: 100%;
            padding: 1rem;
            background: none; 
        }
    }

    .text-container {
        max-width: 100%;
    }

    .crpb-title {
        font-family: 'Seasons', sans-serif;
        font-size: 2.5rem;
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: left;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    .crpb-text {
        font-weight: 500;
        margin-top: 1rem;
        text-align: left;
    }

    .crpb-stars {
        margin-top: 1.5rem;
    }

    .crpb-star {
        width: 2rem;
        height: auto;
        fill: #fff; 

        @media (min-width: 768px) {
            width: 2.5rem;
        }
    }
    
`;
