import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
import Section1 from "./Section1";
import bannerImage from "../../Images/vs.png"
import Section2 from "./Section2";



export default function Contact() {
    return (
        <Root>
            
            <div className="position-relative overflow-hidden m-0">
                <div className="position-relative d-flex align-items-start justify-content-center ">
                    <img
                        alt="Banner"
                        decoding="async"
                        src={bannerImage}
                        className="img-fluid w-100 ban_hght"
                    />
                </div>
            </div>

            <div className="container p-4 py-3">
                <Section1 />
                <Section2 />
            </div>
        </Root>
    );
}

const Root = styled.section`
    background-color: #f8f9fa;
    .ban_hght{
        height:250px;
    }
    

    .container {
        width:90%;
    }

    @media (max-width: 567px) {
        .ban_hght{
        height:150px;
    }
    .container {
        width:100%;
    }


    }
  
    @media (min-width: 567px) and (max-width:992px){
        .container {
        width:100%;
    }

    }
`;
