import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure Bootstrap JS is included
import styled from "styled-components";
import shipping from "../../Images/shipping-insurance.svg";
import money from "../../Images/money-back-warranty.svg";
import CardPayment from "../../Images/new-Card-payment .png";
import DiamondBag from "../../Images/new-diamond-bag.png";

const proContent = [
  {
    question: "How long does it take to make jewelry?",
    answer:
      "It usually takes 2-4 weeks depending on the complexity of the design.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer:
      "We use high-quality metals like gold, silver, and platinum, and ethically sourced gemstones.",
  },
  {
    question: "Do you offer customization options?",
    answer:
      "Yes, we offer a range of customization options including design modifications and engraving.",
  },
  {
    question: "What is the warranty period?",
    answer: "Our standard warranty period is 1 year from the date of purchase.",
  },
  {
    question: "How can I track my order?",
    answer:
      "You will receive a tracking number via email once your order has been shipped.",
  },
  {
    question: "What is your return policy?",
    answer:
      "We offer a 30-day return policy for unused items with the original packaging.",
  },
  {
    question: "How do I clean my jewelry?",
    answer:
      "You can clean your jewelry using a soft cloth and mild jewelry cleaner. Avoid harsh chemicals.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer:
      "Yes, all of our diamonds are conflict-free and come from reputable sources.",
  },
];

const conflictContent = [
  {
    question: "What is the warranty period?",
    answer: "The warranty period is 1 year, covering manufacturing defects.",
  },
  {
    question: "How can I track my order?",
    answer:
      "Tracking information will be provided once your order is dispatched.",
  },
  {
    question: "What is your return policy?",
    answer: "We accept returns within 30 days of purchase for unused items.",
  },
  {
    question: "How do I clean my jewelry?",
    answer:
      "Use a soft cloth and a gentle cleaning solution designed for jewelry.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "All our diamonds are verified to be conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "Production time typically ranges from 2 to 4 weeks.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer: "We use premium metals and ethically sourced gemstones.",
  },
  {
    question: "Do you offer customization options?",
    answer:
      "Yes, we offer a variety of customization options for our jewelry pieces.",
  },
];

const shippingContent = [
  {
    question: "What is your return policy?",
    answer:
      "Our return policy allows for returns within 30 days of receipt for unused items.",
  },
  {
    question: "How do I clean my jewelry?",
    answer:
      "Jewelry should be cleaned with a non-abrasive cleaner and a soft cloth.",
  },
  {
    question: "What is the warranty period?",
    answer: "The warranty period is 12 months from the purchase date.",
  },
  {
    question: "How can I track my order?",
    answer:
      "You can track your order using the tracking link provided in your shipment confirmation email.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "Yes, all diamonds are certified conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "Depending on the complexity, it can take 2-4 weeks to complete.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer:
      "Our jewelry is crafted from high-quality gold, silver, and gemstones.",
  },
  {
    question: "Do you offer customization options?",
    answer: "Customization is available for many of our jewelry pieces.",
  },
];

const warrantyContent = [
  {
    question: "How do I clean my jewelry?",
    answer: "Clean your jewelry with a mild soap solution and a soft brush.",
  },
  {
    question: "What is the warranty period?",
    answer:
      "Our warranty covers defects in materials and craftsmanship for 12 months.",
  },
  {
    question: "How can I track my order?",
    answer:
      "Tracking details will be sent to your email once your order ships.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer:
      "All our diamonds are conflict-free and comply with international standards.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer:
      "Jewelry production times vary but generally range from 2 to 4 weeks.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer:
      "We use only the finest materials including gold, platinum, and precious stones.",
  },
  {
    question: "What is your return policy?",
    answer: "Returns are accepted within 30 days of delivery for unused items.",
  },
  {
    question: "Do you offer customization options?",
    answer: "Yes, we offer a variety of customization options for our jewelry.",
  },
];

const diamonds = [
  {
    question: "How can I track my order?",
    answer: "Once shipped, you'll receive a tracking number via email.",
  },
  {
    question: "How do I clean my jewelry?",
    answer: "Clean with a soft cloth and mild soap. Avoid harsh chemicals.",
  },
  {
    question: "What is the warranty period?",
    answer:
      "Our jewelry comes with a 1-year warranty against manufacturing defects.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "Yes, our diamonds are sourced from conflict-free regions.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer:
      "Production times can range from 2 to 4 weeks depending on the piece.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer: "We use high-quality metals and ethically sourced diamonds.",
  },
  {
    question: "What is your return policy?",
    answer: "Returns are accepted within 30 days of purchase for unused items.",
  },
  {
    question: "Do you offer customization options?",
    answer: "Yes, many of our pieces can be customized to your preference.",
  },
];

const Gemstones = [
  {
    question: "What materials are used in your jewelry?",
    answer:
      "Our jewelry is made from high-grade metals and a variety of gemstones.",
  },
  {
    question: "How can I track my order?",
    answer: "A tracking link will be sent to you once your order is shipped.",
  },
  {
    question: "How do I clean my jewelry?",
    answer: "We recommend using a gentle jewelry cleaner and a soft cloth.",
  },
  {
    question: "What is the warranty period?",
    answer: "We offer a 1-year warranty on all of our jewelry.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "Yes, we ensure all our diamonds are conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "Typically, jewelry making takes about 2 to 4 weeks.",
  },
  {
    question: "What is your return policy?",
    answer: "You can return unused items within 30 days of purchase.",
  },
  {
    question: "Do you offer customization options?",
    answer: "Customization services are available for many pieces of jewelry.",
  },
];

const Care = [
  {
    question: "Do you offer customization options?",
    answer:
      "Yes, we offer various customization options including design changes and engraving.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer:
      "We use high-quality metals such as gold, platinum, and ethically sourced gemstones.",
  },
  {
    question: "How can I track my order?",
    answer:
      "You will receive an email with a tracking number once your order ships.",
  },
  {
    question: "How do I clean my jewelry?",
    answer: "Clean your jewelry with a soft cloth and mild jewelry cleaner.",
  },
  {
    question: "What is the warranty period?",
    answer:
      "Our jewelry comes with a 1-year warranty covering manufacturing defects.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "All of our diamonds are certified conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "Production time is generally 2 to 4 weeks.",
  },
  {
    question: "What is your return policy?",
    answer: "We accept returns within 30 days of purchase for unused items.",
  },
];

const Privacy = [
  {
    question: "Do you offer customization options?",
    answer:
      "Yes, we can customize many of our jewelry pieces according to your specifications.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer:
      "We use premium materials including gold, silver, and a variety of gemstones.",
  },
  {
    question: "How can I track my order?",
    answer:
      "Tracking information will be provided via email once your order is dispatched.",
  },
  {
    question: "How do I clean my jewelry?",
    answer:
      "Use a soft, non-abrasive cloth and a gentle cleaner for best results.",
  },
  {
    question: "What is the warranty period?",
    answer: "We provide a 1-year warranty on all our jewelry items.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "Yes, we guarantee all our diamonds are conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "Typically, it takes between 2 to 4 weeks depending on the design.",
  },
  {
    question: "What is your return policy?",
    answer:
      "You can return items within 30 days of purchase if they are unused and in their original packaging.",
  },
];

const Gifting = [
  {
    question: "What is your return policy?",
    answer:
      "Returns are accepted within 30 days of receipt for unused items with the original packaging.",
  },
  {
    question: "Do you offer customization options?",
    answer:
      "Yes, we offer various customization options including engravings and design alterations.",
  },
  {
    question: "What materials are used in your jewelry?",
    answer: "We use high-quality metals and ethically sourced gemstones.",
  },
  {
    question: "How can I track my order?",
    answer:
      "Tracking details will be sent to you once your order has been shipped.",
  },
  {
    question: "How do I clean my jewelry?",
    answer: "We recommend using a soft cloth and mild jewelry cleaner.",
  },
  {
    question: "What is the warranty period?",
    answer: "The warranty period is 1 year from the date of purchase.",
  },
  {
    question: "Are your diamonds conflict-free?",
    answer: "All our diamonds are certified to be conflict-free.",
  },
  {
    question: "How long does it take to make jewelry?",
    answer: "It generally takes 2 to 4 weeks to complete a jewelry piece.",
  },
];

const Section1 = () => {
  const [selectedButton, setSelectedButton] = useState(1);

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const renderContent = (content) => (
    <div className="accordion" id="faqAccordion">
      {content.map((item, index) => (
        <div className="accordion-item bg-transparent border-0" key={index}>
          <h2
            className="accordion-header"
            id={`heading${selectedButton}-${index}`}
          >
            <button
              className="accordion-button bg-transparent pb-1 px-0 fs-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${selectedButton}-${index}`}
              aria-expanded="true"
              aria-controls={`collapse${selectedButton}-${index}`}
            >
              {item.question}
            </button>
          </h2>
          <div
            id={`collapse${selectedButton}-${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${selectedButton}-${index}`}
            data-bs-parent="#faqAccordion"
          >
            <div className="accordion-body">{item.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );

  const contentMap = {
    1: proContent,
    2: conflictContent,
    3: shippingContent,
    4: warrantyContent,
    5: diamonds,
    6: Gemstones,
    7: Care,
    8: Privacy,
    9: Gifting,
  };
  return (
    <Root>
      <div className="container py-5">
        <div className="d-none d-md-block py-5">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
        </div>
        <div className="container">
          <div className="pb-4 pb-md-5">
            <ul className="d-flex flex-wrap justify-content-between gap-3 list-unstyled pb-4">
              <li className="d-flex align-items-center justify-content-center list-item">
                <button
                  onClick={() => handleButtonClick(1)}
                  className={`btn ${selectedButton === 1 ? "selected" : ""}`}
                  style={{ color: "#666" }}
                >
                  <div className="d-flex flex-column align-items-center gap-2 py-4 px-4 rounded-3 ">
                    <div className="d-flex justify-content-center align-items-center p-2">
                      <img
                        alt="Professional Appraisal"
                        src={DiamondBag}
                        width="30"
                        className="img-fluid"
                      />
                    </div>
                    <span className="text-center font-weight-bold text-dark">
                      Professional Appraisal
                    </span>
                  </div>
                </button>
              </li>
              <li className="d-flex align-items-center justify-content-center list-item">
                <button
                  onClick={() => handleButtonClick(2)}
                  className={`btn ${selectedButton === 2 ? "selected" : ""}`}
                  style={{ color: "#666" }}
                >
                  <div className="d-flex flex-column align-items-center gap-2 py-4 px-4 rounded-3 ">
                    <div className="d-flex justify-content-center align-items-center p-1">
                      <img
                        alt="Conflict Free Diamonds"
                        src={CardPayment}
                        width="45"
                        className="img-fluid"
                      />
                    </div>
                    <span className="text-center font-weight-bold text-dark">
                      Conflict Free Diamonds
                    </span>
                  </div>
                </button>
              </li>
              <li className="d-flex align-items-center justify-content-center list-item">
                <button
                  onClick={() => handleButtonClick(3)}
                  className={`btn ${selectedButton === 3 ? "selected" : ""}`}
                  style={{ color: "#666" }}
                >
                  <div className="d-flex flex-column align-items-center gap-2 py-4 px-4 rounded-3 ">
                    <div className="d-flex justify-content-center align-items-center p-1">
                      <img
                        alt="Shipping and Insurance"
                        src={shipping}
                        width="40"
                        className="img-fluid"
                      />
                    </div>
                    <span
                      className="text-center font-weight-bold text-dark"
                      style={{ color: "#666" }}
                    >
                      Shipping and Insurance
                    </span>
                  </div>
                </button>
              </li>
              <li className="d-flex align-items-center justify-content-center list-item">
                <button
                  onClick={() => handleButtonClick(4)}
                  className={`btn ${selectedButton === 4 ? "selected" : ""}`}
                  style={{ color: "#666" }}
                >
                  <div className="d-flex flex-column align-items-center gap-2 py-4 px-4 rounded-3  ">
                    <div className="d-flex justify-content-center align-items-center p-1">
                      <img
                        alt="Money Back and Warranty"
                        src={money}
                        width="40"
                        className="img-fluid"
                      />
                    </div>
                    <span className="text-center font-weight-bold text-dark">
                      Money Back and Warranty
                    </span>
                  </div>
                </button>
              </li>
            </ul>
            <div className="py-1 text-center position-relative my-5">
              <div className="divider"></div>
              <p className="position-absolute top-50 start-50 translate-middle bg-light px-1">
                More Topics
              </p>
            </div>
            <div className=" ">
              <ul className="d-flex flex-row flex-wrap  justify-content-center gap-2 my-lg-3 p-0">
                <li className="nav-item flex-shrink-0 d-flex justify-content-center align-items-center rounded-lg">
                  <button
                    onClick={() => handleButtonClick(5)}
                    className={`btn ${selectedButton === 5 ? "selected" : ""}`}
                    style={{ color: "#666" }}
                  >
                    Diamonds
                  </button>
                </li>
                <li className="nav-item flex-shrink-0 d-flex justify-content-center align-items-center rounded-lg">
                  <button
                    onClick={() => handleButtonClick(6)}
                    className={`btn ${selectedButton === 6 ? "selected" : ""}`}
                    style={{ color: "#666" }}
                  >
                    Gemstones
                  </button>
                </li>
                <li className="nav-item flex-shrink-0 d-flex justify-content-center align-items-center rounded-lg">
                  <button
                    onClick={() => handleButtonClick(7)}
                    className={`btn ${selectedButton === 7 ? "selected" : ""}`}
                    style={{ color: "#666" }}
                  >
                    Warranty & Care
                  </button>
                </li>
                <li className="nav-item flex-shrink-0 d-flex justify-content-center align-items-center rounded-lg">
                  <button
                    onClick={() => handleButtonClick(8)}
                    className={`btn ${selectedButton === 8 ? "selected" : ""}`}
                    style={{ color: "#666" }}
                  >
                    Privacy
                  </button>
                </li>
                <li className="nav-item flex-shrink-0 d-flex justify-content-center align-items-center rounded-lg">
                  <button
                    onClick={() => handleButtonClick(9)}
                    className={`btn ${selectedButton === 9 ? "selected" : ""}`}
                    style={{ color: "#666" }}
                  >
                    Gifting & Packaging
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div>{renderContent(contentMap[selectedButton])}</div>
        </div>
      </div>
    </Root>
  );
};

export default Section1;

const Root = styled.section`
  .faq-heading {
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .custom-border {
    border: 1px solid #dee2e6;
  }

  .custom-opacity {
    opacity: 0.7;
  }

  .btn.selected {
    border: 1px solid #000;
    color: #000;
  }

  .btn {
    border: 1px solid #d5cccc;
    color: #000;
    padding: 10px 20px;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
  }

  .divider {
    border-top: 1px solid #000;
    height: 1px;
  }

  .accordion-button {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000;
    background: transparent;
  }

  .accordion-button:not(.collapsed) {
    color: #000;
    background-color: transparent;
  }

  .accordion-body {
    font-size: 1rem;
    color: #333;
  }

  .list-item {
    width: 100%;
  }

  @media (max-width: 767px) {
    .btn {
      font-size: 0.9rem;
      padding: 8px 16px;
    }

    .faq-heading {
      font-size: 2rem;
    }

    .accordion-button {
      font-size: 1rem;
    }
  }

  @media (min-width: 768px) {
    .list-item {
      flex: 1 1 45%;
    }
  }

  @media (min-width: 992px) {
    .list-item {
      flex: 1 1 22%;
    }
  }
  .accordion-button:focus {
    box-shadow: none;
  }
`;
