import React from "react";
import styled from "styled-components";


export default function Section1() {
    return (
        <Root>
            <div class="container px-4 ">
                <div class="row text-center">
                    <div class="col-12 d-none d-md-block px-4 ">
                        <div class="p-4">
                            <p class="lead fw-semibold fs-6">Our goal is to simplify engagement ring shopping and make it a more enjoyable experience. We accomplish this by designing sleek, sophisticated pieces that emulate contemporary styles. Our website is interactive and easy-to-use, making the engagement ring shopping process seamless and fun! We never imagined The Precious Earth would grow so rapidly. Our innovative approach to engagement ring shopping has been a smashing hit with couples worldwide!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Root>
    )
}
const Root = styled.section`
  
  
`;