import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import bannerImage from "../../Images/girlbanner.webp";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

export default function Contact() {
  return (
    <Root>
      <div className="position-relative overflow-hidden m-0">
        <div className="position-relative d-flex align-items-end justify-content-center ">
          <img
            alt="Banner"
            decoding="async"
            src={bannerImage}
            className="img-fluid w-100"
          />
        </div>
        <div className="container p-4 py-3">
          <Section1 />
        </div>

        <div className=" py-5 my-4">
          <Section2 />
        </div>
        <div className="container py-3">
          <Section3 />
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  background-color: #f8f9fa;

  width: 100%;

  .img-fluid {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the area */
  }

  .container {
    width: 90%;
  }

  @media (max-width: 567px) {
    .container {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .container {
      width: 100%;
    }
  }
`;
