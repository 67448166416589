import React from "react";
import styled from "styled-components";


export default function Section2() {
    return (
        <Root>
            <div class="container text-center mb-3 mb-md-5 d-flex flex-column custom-gap-y-1-5">
                <span class="d-block custom-text-xl custom-font-ligh p-2">Our Values</span>
                <h1 class="custom-heading custom-font-medium">Working together for a meaningful purpose</h1>
                <p class="text-base text-md custom-text-xl-md">We value the trust given to us by our customers, employees and suppliers and enjoy every moment of growing together.</p>
            </div>
        </Root> 
    )
}
const Root = styled.section`

margin-left:30px;
margin-right:30px;
  
      .custom-text-center {
      text-align: center;
    }
    .custom-gap-y-1-5 {
      margin-bottom: 0.375rem; 
    }
    .custom-heading {
      font-size: 28px; 
    }
    @media (min-width: 768px) {
      .custom-heading {
        font-size: 35px; 
      }
    }
    .custom-text-xl {
      font-size: 1.25rem; 
    }
    @media (min-width: 768px) {
      .custom-text-xl-md {
        font-size: 15px; 
      }
    }
    .custom-font-light {
      font-weight: 300; 
    }
    .custom-font-medium {
      font-weight: 500; 
    }
`;