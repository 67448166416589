import React from "react";
import styled from "styled-components";


export default function Section1() {
    return (
        <Root>
            <div class="KeyzarInfo__article-header">
                <h1 class="KeyzarInfo__article-title font-seasons">Privacy Policy</h1>
                <div class="KeyzarInfo__article-date">February 1, 2023</div>
                </div>
        </Root>
    )
}
const Root = styled.section`
  .KeyzarInfo__article-date{
  display: inline-block;
    font-size: .75rem;
    line-height: 1;
    color: #c5b2a7;
    background: #fff3ec;
    border-radius: 3px;
    padding: .3125rem .5rem;
    font-weight: 500;
    margin-bottom: .75rem;
    }
    .KeyzarInfo__article-title {
    font-size: 1.25rem;
    line-height: 1.2;
    letter-spacing: .03em;
    margin-bottom: .75rem;
}
   
    `