import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { applyMiddleware, createStore } from "redux";
import storage from "redux-persist/lib/storage";
import rootReducer from "../reducers";
import { thunk } from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(thunk) // Apply thunk middleware
);

export const persistor = persistStore(store);
