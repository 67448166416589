import React from "react";
import styled from "styled-components";
import shipping from "../../Images/shipping-insurance.svg"
import money from "../../Images/money-back-warranty.svg"
import badge from "../../Images/conflict-free-diamonds.svg"
import professional from "../../Images/professional-appraisal.svg"

export default function Section1() {
    return (
        <Root>
            <div className=" text-black mx-auto">
                <div className="hidden md:block pb-5">
                    <h2 className="font-bold text-center uppercase">Buyer Information</h2>
                </div>
                <div >
                    <div >
                        <ul className="d-flex flex-row justify-content-between flex-wrap gap-2 list-unstyled pb-5 custome">
                            <li className="d-flex align-items-center justify-content-center custom-hover" style={{
                                maxWidth: '100%', height: 'auto',
                                width: '14rem', maxHeight: '14rem'
                            }}>
                                <a href="#free-shipping-worldwide" className="text-decoration-none">
                                    <div className="d-flex flex-column align-items-center gap-2 py-5 px-5 rounded-3 shadow-sm border border-dark ">
                                        <div className="d-flex justify-content-center align-items-center p-1">
                                            <img
                                                alt="Shipping and Insurance"
                                                src={shipping}
                                                width="40"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <span className="text-center font-weight-medium text-dark fw-bold" style={{ width: '7rem' }}>
                                            Shipping and Insurance
                                        </span>
                                    </div>
                                </a>
                            </li>
                            <li className="d-flex align-items-center justify-content-center custom-hover" style={{
                                maxWidth: '100%', height: 'auto',
                                width: '14rem', maxHeight: '14rem'
                            }}>
                                <a href="#unique-shopping-experience" className="text-decoration-none" >
                                    <div className="d-flex flex-column align-items-center gap-2 py px-5 rounded-3 shadow-sm border border-dark ">
                                        <div className="d-flex justify-content-center align-items-center p-1">
                                            <img
                                                alt="Shipping and Insurance"
                                                src={money}
                                                width="38"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <span className="text-center font-weight-medium text-dark fw-bold" style={{ width: '7rem' }}>
                                            Money Back and Warranty
                                        </span>
                                    </div>
                                </a>

                            </li>
                            <li className="d-flex align-items-center justify-content-center  custom-hover" style={{
                                maxWidth: '100%', height: 'auto',
                                width: '14rem', maxHeight: '14rem'
                            }}>
                                <a href="#conflict-free-policy" className="text-decoration-none" >
                                    <div className="d-flex flex-column align-items-center gap-2 py-5 px-5 rounded-3 shadow-sm border border-dark ">
                                        <div className="d-flex justify-content-center align-items-center p-1">
                                            <img
                                                alt="Shipping and Insurance"
                                                src={badge}
                                                width="30"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <span className="text-center font-weight-medium text-dark fw-bold" style={{ width: '7rem' }}>
                                            Conflict Free Diamonds                                                
                                            </span>
                                    </div>
                                </a>

                            </li>
                            <li className="d-flex align-items-center justify-content-center custom-hover" style={{
                                maxWidth: '100%', height: 'auto',
                                width: '14rem'
                            }}>
                                <a href="#professional-appraisal" className="text-decoration-none" style={{ color: '#666' }} >
                                    <div className="d-flex flex-column align-items-center gap-2 py-5 px-5 rounded-3 shadow-sm border border-dark ">
                                        <div className="d-flex justify-content-center align-items-center p-2">
                                            <img
                                                alt="Shipping and Insurance"
                                                src={professional}
                                                width="40"
                                                className="img-fluid"

                                            />
                                        </div>
                                        <span className="text-center font-weight-medium text-dark fw-bold" style={{ width: '7rem' }}>
                                            Professional Appraisal </span>
                                    </div>
                                </a>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Root>
    )
}
const Root = styled.section`
  font-family: ProximaNova, sans-serif;
  background-color: #f8f9fa;

  .hidden {
    h2 {
      font-weight: 500;
      font-size: 3rem;
      text-transform: uppercase;
      color: black;
    }
  }
  h2 {
    font-size: clamp(1rem, 4vw, 3rem);
    line-height: 1.375;
    font-weight: 500;
    color: black;
  }
  .container {
    max-width: 62rem;
    margin: auto;
  }
  a {
    text-decoration: none;
    color: #666;
  }
  .custom-hover {
    opacity: 0.5;
  }
  .py {
    padding: 39px 0 39px;
  }
  .custom-hover:hover {
    opacity: 4;
  }

  @media (max-width: 576px) {
    .custome {
      width: 100%;
      gap: 10px;
      display: flex !important;
      justify-content: center !important;
    }

    .text {
      font-size: 0.875rem;
    }

    .box_div {
    }
  }

  @media (max-width: 465px) {
    .custome {
      width: 100%;
    }
  }

 
`;