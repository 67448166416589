import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import backimg from "../../Images/bandimgs.png";
import SolidHer from "../../Images/SolidHer.png";
import NatureHer from "../../Images/NatureHer.png";
import ProngHer from "../../Images/ProngHer.png";
import CigarHer from "../../Images/CigarHer.png";
import PaveHer from "../../Images/PaveHer.png";
import ClassicHim from "../../Images/ClassicHim.png";
import DiamondHim from "../../Images/DiamondHim.png";
import DesignerHim from "../../Images/DesignerHim.png";
import gold2 from "../../Images/fourtwo.png";
import gold3 from "../../Images/fourthree.png";
import gold1 from "../../Images/fourone.png";
import gold4 from "../../Images/pt.png";

const ForHerList = [
  { id: 1, title: "Nature", label: "nature", imgUrl: NatureHer },
  { id: 2, title: "Pave", label: "pave", imgUrl: PaveHer },
  { id: 5, title: "Cigar Band", label: "cigarband", imgUrl: CigarHer },
  { id: 3, title: "Prong", label: "prong", imgUrl: ProngHer },
  { id: 4, title: "Solid", label: "solid", imgUrl: SolidHer },
];

const ForHimList = [
  { id: 1, title: "Classic", label: "classic", imgUrl: ClassicHim },
  { id: 2, title: "Diamond", label: "diamond", imgUrl: DiamondHim },
  { id: 3, title: "Designer", label: "designer", imgUrl: DesignerHim },
];

const metals = [
  { id: 432312975578, name: "14k White Gold", imgUrl: gold1 },
  { id: 432312910042, name: "14k Yellow Gold", imgUrl: gold2 },
  { id: 432312942810, name: "14k Rose Gold", imgUrl: gold3 },
  { id: 432313008346, name: "Platinum", imgUrl: gold4 },
];

export default function WeddingBandList() {
  const navigate = useNavigate();

  const handleNavigate = (targetPath, product, category) => {
    navigate(targetPath, {
      state: { product },
      search: `?for=${category}&productId=${product.id}&title=${product.title}`,
    });
  };

  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <b>For Her</b>
            <div className="ring_space">
              <ul>
                {ForHerList.map((item) => (
                  <li
                    key={item.id}
                    className="d-flex"
                    onClick={() =>
                      handleNavigate(
                        "/weddingbands",
                        { ...item, isForHer: true },
                        "her"
                      )
                    }
                  >
                    <img src={item.imgUrl} alt="img" />
                    {item.title}
                  </li>
                ))}
                <li
                  className="d-flex"
                  onClick={() => {
                    navigate("/eternity");
                  }}
                >
                  <img src={CigarHer} alt="img" />
                  Eternity
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 bord">
            <b>For Him</b>
            <div className="ring_space">
              <ul>
                {ForHimList.map((item) => (
                  <li
                    key={item.id}
                    onClick={() =>
                      handleNavigate(
                        "/weddingbands",
                        { ...item, isForHer: false },
                        "him"
                      )
                    }
                  >
                    <img src={item.imgUrl} alt="img" />
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 bord">
            <b>Shop By Metal</b>
            <div className="ring_space">
              <ul>
                {metals.map((metal) => (
                  <li
                    key={metal.id}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() =>
                      handleNavigate("/weddingbands", metal, "metal")
                    }
                  >
                    <img src={metal.imgUrl} alt="img" className="color_img" />
                    <p style={{ margin: "0", padding: "0px 4px" }}>
                      {metal.name}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 ">
            <img
              src={backimg}
              alt="img"
              className="imgh"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .col-lg-3 {
    margin: 20px 0px;
  }
  b {
    font-size: 13px;
    text-transform: uppercase;
    padding: 10px;
  }
  ul {
    padding: 0px 20px;
    list-style: none;
    li {
      font-weight: 550;
      font-size: 13px;
      margin: 10px 0px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      a {
        text-decoration: none;
        color: black;
        display: flex;
        flex-direction: row;
        &:hover {
          text-decoration: underline;
        }
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  svg,
  img {
    width: 30px;
    height: 30px;
    mix-blend-mode: multiply;
  }

  .ring_space {
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }

  .bord {
    border-left: 1px solid #ededed;
  }

  @media (max-width: 567px) {
    margin-left: 0;

    .col-lg-3 {
      margin: 0;
      padding: 0;
    }

    padding: 0px;
    .imgh {
      display: none;
    }
    .ring_space {
      padding: 0;
      gap: 10px;
    }

    ul li a {
      font-size: 12px;
    }

    .bord {
      border-top: 1px solid #ededed;
      border-left: unset;
      padding-top: 20px;
    }

    span {
      font-size: 12px;
    }

    ul li {
      font-size: 12px;
      gap: 6px;
      display: flex;
      align-items: center;
    }

    b {
      font-size: 13px;
    }
  }
`;
