import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  setHerHimProductIds,
  setSelectedHerImgTitle,
  setSelectedHimImgTitle,
  setSelectedMetalIds,
  setWeddingIds,
} from "../../redux/users/action";
import forHer from "../../Images/her.png";
import forHim from "../../Images/him.png";
import styled from "styled-components";
import WhiteGold14 from "../../Images/fourone.png";
import YellowGold14 from "../../Images/fourtwo.png";
import RoseGold14 from "../../Images/fourthree.png";
import WhiteGold18 from "../../Images/eightone.png";
import YellowGold18 from "../../Images/eighttwo.png";
import RoseGold18 from "../../Images/eightthree.png";
import Platinum from "../../Images/pt.png";
import natureForHer from "../../Images/NatureHer.png";
import paveForHer from "../../Images/PaveHer.png";
import prongForHer from "../../Images/ProngHer.png";
import solidForHer from "../../Images/SolidHer.png";
import cigarForHer from "../../Images/CigarHer.png";
import classicForHim from "../../Images/ClassicHim.png";
import diamondForHim from "../../Images/DiamondHim.png";
import designerForHim from "../../Images/DesignerHim.png";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { IoFilterOutline } from "react-icons/io5";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import { NoProduct } from "../NoProduct";
import { EXCHANGE_URLS_WEDDING } from "../URLS";
import axios from "axios";

const ForHerList = [
  { id: 1, title: "Nature", label: "nature", imgUrl: natureForHer },
  { id: 2, title: "Pave", label: "pave", imgUrl: paveForHer },
  { id: 3, title: "Prong", label: "prong", imgUrl: prongForHer },
  { id: 4, title: "Solid", label: "solid", imgUrl: solidForHer },
  { id: 5, title: "Cigar Band", label: "cigarband", imgUrl: cigarForHer },
];

const ForHimList = [
  { id: 1, title: "Classic", label: "classic", imgUrl: classicForHim },
  { id: 2, title: "Diamond", label: "diamond", imgUrl: diamondForHim },
  { id: 3, title: "Designer", label: "designer", imgUrl: designerForHim },
];

const metals = [
  { id: 432312975578, name: "14k White Gold", imgUrl: WhiteGold14 },
  { id: 432312910042, name: "14k Yellow Gold", imgUrl: YellowGold14 },
  { id: 432312942810, name: "14k Rose Gold", imgUrl: RoseGold14 },
  { id: 432847290586, name: "18k White Gold", imgUrl: WhiteGold18 },
  { id: 432847159514, name: "18k Yellow Gold", imgUrl: YellowGold18 },
  { id: 432847257818, name: "18k Rose Gold", imgUrl: RoseGold18 },
  { id: 432313008346, name: "Platinum", imgUrl: Platinum },
];

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function WeddingBands() {
  const [tags, setTags] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedMetal, setSelectedMetal] = useState(metals[0]);
  const [selectedDropButton, setSelectedDropButton] = useState(metals[0].name);
  const [isOpen, setIsOpen] = React.useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isForHer, setIsForHer] = useState(true);
  const [isForHim, setIsForHim] = useState(false);
  const [visibleProducts, setVisibleProducts] = useState(20);
  const [herHimBandProduct, setHerHimBandProduct] = useState();
  const [selectedHerHimProductId, setSelectedHerHimProductId] = useState(null);
  const [allProductIds, setAllProductIds] = useState([]);
  const selectedMetalIds = useSelector((state) => state.users.selectedMetalIds);
  console.log("iddd", selectedMetalIds);
  const location = useLocation();
  const { state } = location;
  const queryParams = new URLSearchParams(location.search);
  const selectedProduct = state?.product;
  const selectedProductId = queryParams.get("productId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleModalNavigate = (forHer, forHim) => {
    if (forHer) {
      setIsForHer(true);
      setIsForHim(false);
      setSelectedButton(1);
      dispatch(
        setSelectedHerImgTitle(ForHerList[0].title, ForHerList[0].imgUrl)
      );
    } else if (forHim) {
      setIsForHer(false);
      setIsForHim(true);
      setSelectedButton(1);
      dispatch(
        setSelectedHimImgTitle(ForHimList[0].title, ForHimList[0].imgUrl)
      );
    }
  };

  const handleButtonDropClick = (selectedLabel) => {
    setSelectedDropButton(selectedLabel);
    const selectedMetal = metals.find((metal) => metal.name === selectedLabel);
    setSelectedMetal(selectedMetal);
    if (selectedMetal) {
      dispatch(setSelectedMetalIds([selectedMetal.id]));
      setTags((prevTags) => [, selectedLabel]);
    }
  };
  function toggleShowName() {
    setShow((prevState) => !prevState);
  }
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleButtonHerClick = (buttonIndex, selectedTitle, selectedImg) => {
    setSelectedButton(buttonIndex);
    dispatch(setSelectedHerImgTitle(selectedTitle, selectedImg));
  };

  const handleButtonHimClick = (buttonIndex, selectedTitle, selectedImg) => {
    setSelectedButton(buttonIndex);
    dispatch(setSelectedHimImgTitle(selectedTitle, selectedImg));
  };

  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 20);
  };

  useEffect(() => {
    setSelectedButton(1);
    setIsForHer(true);
    setIsForHim(false);
    dispatch(setSelectedHerImgTitle(ForHerList[0].title, ForHerList[0].imgUrl));
  }, [dispatch, selectedProductId]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Default to "14k White Gold" if no metal is selected
    if (!selectedMetal) {
      const defaultMetal = metals.find(
        (metal) => metal.name === "14k White Gold"
      );
      setSelectedMetal(defaultMetal);
      setSelectedDropButton(defaultMetal.name);
      dispatch(setSelectedMetalIds([defaultMetal.id]));
    }
  }, [selectedMetal, dispatch]);

  const handleHerHimBandClick = (product) => {
    if (!product || !product.id) {
      console.error("Product or Product ID is missing.");
      return;
    }

    // Set the selected product ID in state or store
    setSelectedHerHimProductId(product.id);
    dispatch(setHerHimProductIds(product.id));

    // Determine the target path based on whether it's for "her" or "him"
    const targetPath = isForHer ? "/moreforher" : isForHim ? "/moreforhim" : "";

    // Navigate to the target path, passing product via state and adding productId to the query string
    navigate(targetPath, {
      state: { product }, // Pass the entire product object as state
      search: `?for=${isForHer ? "her" : isForHim ? "him" : ""}&productId=${
        product.id
      }`, // Include productId in the query string
    });
  };

  const handleNavigateDetail = async () => {
    try {
      const response = await axios.get(
        `${EXCHANGE_URLS_WEDDING}/weddingcollection`
      );
      if (response?.status === 200) {
        const productData = response?.data?.data;
        // Shuffle the products randomly
        const shuffledProducts = productData.sort(() => 0.5 - Math.random());
        setHerHimBandProduct(shuffledProducts);
        const weddingCollectionIds = shuffledProducts.map((item) => item.id);
        dispatch(setWeddingIds(weddingCollectionIds));
        setAllProductIds(weddingCollectionIds);
      }
    } catch (error) {
      console.error("Error fetching", error);
    }
  };

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const tags = [];
        if (isForHim && !isForHer) {
          tags.push("forhim");
        }
        if (isForHer && !isForHim) {
          tags.push("forher");
        }

        const selectedItem =
          isForHim && !isForHer
            ? ForHimList[selectedButton - 1]
            : isForHer && !isForHim
            ? ForHerList[selectedButton - 1]
            : null;
        if (selectedItem) {
          tags.push(selectedItem.label.toLowerCase());
        }

        const queryString = tags.length ? tags.join(",") : "";
        const metalIdString = selectedMetalIds.length
          ? selectedMetalIds.join(",")
          : "";

        const response = await axios.get(
          `${EXCHANGE_URLS_WEDDING}/weddingdata?collectionId=${metalIdString}&tags=${queryString}`
        );

        if (response?.status === 200) {
          const herHimBandProduct = response?.data?.products;
          console.log("Fetched products", response);
          setHerHimBandProduct(herHimBandProduct);
        }
      } catch (error) {
        console.error("Error fetching diamond details:", error);
      }
    };

    fetchCollections();
    handleNavigateDetail();
  }, [isForHer, isForHim, selectedButton, selectedMetalIds, selectedMetal]);

  const filteredProductIds =
    selectedMetal && Array.isArray(herHimBandProduct)
      ? herHimBandProduct
          .filter((product) => product.metal === selectedMetal.name)
          .map((product) => product.id)
      : [];

  useEffect(() => {
    if (herHimBandProduct) {
      const productIds = herHimBandProduct.map((product) => product.id);
      setAllProductIds(productIds);
    }
  }, [herHimBandProduct]);

  const drawerContent = (
    <>
      <>
        <div className="ring_types">
          {isForHer &&
            !isForHim &&
            ForHerList.map((her, index) => (
              <button
                key={index}
                className={selectedButton === index + 1 ? "selected" : ""}
                onClick={() =>
                  handleButtonHerClick(index + 1, her.title, her.imgUrl)
                }
              >
                <img
                  src={her.imgUrl}
                  alt={`img of ring ${her.title}`}
                  style={{ width: "52px" }}
                />
                <span>{her.title}</span>
              </button>
            ))}

          {isForHim &&
            !isForHer &&
            ForHimList.map((him, index) => (
              <button
                key={index}
                className={selectedButton === index + 1 ? "selected" : ""}
                onClick={() =>
                  handleButtonHimClick(index + 1, him.title, him.imgUrl)
                }
              >
                <img
                  src={him.imgUrl || "No Product Available"}
                  alt={`img of ring ${him.title}`}
                  style={{ width: "52px" }}
                />
                <span>{him.title}</span>
              </button>
            ))}
        </div>
      </>

      <>
        <StyledSection>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="select_div">
                  <div className="select_opt">
                    <div className="head_icon" onClick={toggleShowName}>
                      <h3>Select Metal</h3>
                      <IoIosArrowDown />
                    </div>
                    {show && (
                      <div className="select_metal">
                        <div className="first_row">
                          <div className="d-flex align-items-center">
                            <h5>Metal : {selectedDropButton}</h5>
                          </div>
                          <RxCross1
                            className="icon"
                            onClick={() => setShow(false)}
                          />
                        </div>

                        <div className="btn_row">
                          {metals.map((metal) => (
                            <button
                              key={metal.name}
                              className={
                                selectedDropButton === metal.name
                                  ? "selected"
                                  : ""
                              }
                              onClick={() => handleButtonDropClick(metal.name)}
                            >
                              <img
                                style={{ width: "50px", height: "50px" }}
                                src={metal.imgUrl || "No Products Available"}
                                alt={metal.name}
                              />
                              <h5>{metal.name}</h5>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
            <div className="col-lg-12">
              <TagsInput value={tags} onChange={setTags} />
            </div>
          </div>
          </div>
        </StyledSection>
      </>
    </>
  );
  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="heading">
              <h3>Select your ring style and color</h3>
              <p>
                To design your ring for your stone use the buttons below to
                choose your setting and metal
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="two_btn">
            <div className="col-lg-2"></div>
            <div className="col-lg-2 col-md-3 col-1"></div>
            <div className="col-lg-2 col-md-3 col-5">
              <button
                className={isForHer && !isForHim ? "selected" : ""}
                onClick={() => handleModalNavigate(true, false)}
              >
                <img src={forHer} alt="img for her" />
                <h5>FOR HER</h5>
              </button>
            </div>
            <div className="col-lg-2 col-md-3 col-5">
              <button
                className={isForHim && !isForHer ? "selected" : ""}
                onClick={() => handleModalNavigate(false, true)}
              >
                <img src={forHim} alt="img for him" />
                <h5>FOR HIM</h5>
              </button>
            </div>

            <div className="col-lg-2 col-md-3 col-1"></div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
      <>
        <div>
          <button className="drawer-toggle-button" onClick={toggleDrawer}>
            <IoFilterOutline /> Filter
          </button>
          <div
            className={`drawer-content ${
              isOpen && screenWidth <= 567 ? "open" : ""
            }`}
          >
            {screenWidth > 567 ? (
              drawerContent
            ) : (
              <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction="bottom"
                className="bla"
              >
                {drawerContent}
              </Drawer>
            )}
          </div>
        </div>
      </>
      <>
        <div className="main_div">
          {herHimBandProduct && herHimBandProduct.length > 0 ? (
            herHimBandProduct
              .slice(0, visibleProducts)
              .map((product, index) => (
                <div key={index} className="subdiv">
                  <>
                    <Slider {...settings}>
                      {product?.images?.edges?.length > 0 ? (
                        product.images.edges.map((image, imageIndex) => (
                          <img
                            key={imageIndex}
                            src={image?.node?.originalSrc}
                            alt={image?.node?.altText || "Product Image"}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "https://via.placeholder.com/283";
                            }}
                          />
                        ))
                      ) : (
                        <div
                          style={{ backgroundColor: "#ccc", height: "200px" }}
                        >
                          "No Image"
                        </div>
                      )}
                    </Slider>
                  </>

                  <div className="hov_content">
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <h5 className="prd_name">{product?.title}</h5>
                        <div className="d-flex">
                          <span className="white_color"></span>
                          <span className="golden_color"></span>
                          <span className="red_color"></span>
                        </div>
                      </div>
                      <p className="prd_price pt-1 pb-1">
                        max- ${product?.priceRange?.maxVariantPrice?.amount}{" "}
                        min- ${product?.priceRange?.minVariantPrice?.amount}
                      </p>
                    </div>

                    <div className="btn_div">
                      <button
                        className="info_btn"
                        onClick={() => handleHerHimBandClick(product)}
                      >
                        More Info
                      </button>
                      <button
                        className="add_btn"
                        onClick={() => handleHerHimBandClick(product)}
                      >
                        Add to Cart
                      </button>
                    </div>

                    <div className="note">
                      <p className="note">
                        Pay in 12 interest-free installments of $
                        <span>Learn more</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <NoProduct />
          )}
        </div>
      </>
      <div className="load_btn">
        {visibleProducts < herHimBandProduct && herHimBandProduct.length && (
          <button onClick={handleLoadMore}>Load More</button>
        )}
      </div>
    </Root>
  );
}

const Root = styled.section`

input.react-tagsinput-input {
    display: none;
  }

  .react-tagsinput {
    background-color: #fff;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
    border-right:none;
    border-left:none;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
   padding-bottom:20px;
   @media (max-width: 567px){
    display:flex;
    justify-content:center;

   }
  }

  .react-tagsinput-tag {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 1px solid #f7f7f7;
    color: #212529;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right:5px;
    padding:4px 15px;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    margin-left: 20px;
    font-size: 25px;
    text-decoration: none;
    color: #999;
    font-weight:400;
  }

  .main_div .subdiv .slick-prev {
    left: -2px;
    z-index: 111;
  }

  .main_div .subdiv .slick-next {
    right: -2px;
    z-index: 111;
  }

  .main_div .subdiv .slick-prev:before,
  .main_div .subdiv .slick-next:before {
    background: rgb(185 179 179);
    border-radius: 50px;
  }
  .heading {
    text-align: center;
    margin-top: 20px;
    color: #000;
    h3 {
      color: rgba(0, 0, 0);
      padding: 0px 20px;
      line-height: 2.25rem;
    }
    p {
      font-size: large;
      font-weight: 500;
      word-spacing: 1.5px;
    }
  }
  .two_btn {
    display: flex;
    justify-content: center;
    margin: 20px 0px;

    button {
      display: flex;
      width: 100%;
      height: 8vh;
      border: 1px solid #d1d1d1;
      align-items: center;
      justify-content: center;
      padding: 10px;
      gap: 10px;
      border-radius: 4px;
      &.selected {
        border: 2px solid black;
        font-weight: 600;
        position: relative;
        z-index: 1;
      }
    }
    img {
      width: 30px;
      height: 30px;
    }

    h5 {
      font-size: 13px;
      padding-top: 7px;
    }
  }
  .ring_types {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    overflow-x: auto;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px;
    button {
      width: 93px;
      border: 2px solid transparent;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      align-items: center;
      padding: 12px 0;
      font-size: 12px;
      line-height: 25px;
      font-weight: 500;
      &.selected {
        border: 2px solid black;
        border-radius: 10px;
      }

      &:hover {
        background-color: rgba(247, 247, 247);
      }

      img,
      svg {
        height: 50px;
        width: 62px;
      }
    }
  }
  .drawer-content {
    padding: 20px 0px;
    width: 100%;
  }
  .drawer-toggle-button {
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
  }
  .EZDrawer__container {
    overflow-y: scroll !important;
    height: 67vh !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    padding-bottom: 40px;
  }
  .main_div {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    gap: 4px;
    .subdiv {
      width: 24vw;
      height: 48vh;
      border: 3px solid #f7f7f7;
      border-radius: 20px;
      padding: 5px;
      overflow: hidden;
      position: relative;
      margin-bottom: 20px;
      padding: 15px;
      img {
        width: 100%;
        max-height: 246px;
        object-fit: cover;
      }
      &:hover {
        border: 3px solid black;
        overflow: unset;

        padding: 15px;
      }
      .hov_content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .flex-column {
          display: flex;
          width: 100%;
        }
      }

      &:hover .hov_content {
        width: 24vw;
        z-index: 1;
        position: absolute;
        background-color: white;
        border: 3px solid black;
        padding: 0 20px 0;
        left: -3px;
        overflow: hidden;
        border-top: none;
        border-radius: 0 0 20px 20px;
        .prd_name {
          font-size: 14px;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
        }
      }

      .prd_name {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .prd_price {
        font-size: 13px;
        width: 100%;
      }
      .white_color,
      .golden_color,
      .red_color {
        height: 18px;
        width: 18px;
        text-align: center;
        border-radius: 50px;
        margin-left: 10px;
      }

      .white_color {
        background-color: #ebebeb;
      }

      .golden_color {
        background-color: #ffdfb0;
      }

      .red_color {
        background-color: #efcbcb;
      }

      .btn_div {
        display: flex;
        justify-content: space-between;
        padding: 0;
        width: 100%;
        gap: 10px;
        .info_btn {
          flex: 1;
          padding: 12px 21px;
          border-radius: 25px;
          font-size: 13px;
          background-color: #fff;
          border: 2px solid black;
        }
        .add_btn {
          background-color: black;
          border: 2px solid black;
          color: white;
          padding: 5px 17px;
          border-radius: 25px;
          font-size: 13px;
          flex: 1;
        }
      }

      .note {
        display: flex;
        justify-content: center;
        width: 100%;
        p {
          font-size: 10px;
          margin-top: 20px;
          text-align: center;
          margin-top: 8px;
          margin-bottom: 10px;
        }
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .load_btn {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    button {
      border: 2px solid black;
      background-color: transparent;
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      padding: 10px 30px;
      border-radius: 50px;
    }
  }
  @media (max-width: 567px) {
    padding: 0px 10px;

    .main_div {
      gap: 15px;
      margin-top: 0;
      z-index: 1;
      .subdiv {
        width: 45vw;
        max-height: 200px;
        &:hover .hov_content {
          width: 45vw;
          padding: 0px 10px;
        }
        .prd_name {
          font-size: 12px;
          margin-bottom: 9px;
        }
        .prd_price {
          font-size: 11px;
          margin-bottom: 10px;
        }
        .white_color,
        .golden_color,
        .red_color {
          height: 10px;
          width: 10px;
        }
        .btn_div .info_btn,
        .btn_div .add_btn {
          flex: 1;
          font-size: 11px;
          padding: 3px 10px;
          border-radius: 15px;
        }
      }
    }

    .main_div .subdiv img {
      width: 100%;
      height: 133px;
      object-fit: unset;
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0px 10px;

    .main_div {
      padding: 0 20px;
      gap: 15px;
      .subdiv {
        width: 45vw;
        max-height: 313px;
        &:hover .hov_content {
          width: 45vw;
          padding: 0px 10px;
        }
        .prd_name {
          font-size: 12px;
          margin-bottom: 9px;
        }
        .prd_price {
          font-size: 11px;
          margin-bottom: 10px;
        }
        .white_color,
        .golden_color,
        .red_color {
          height: 10px;
          width: 10px;
        }
        .btn_div .info_btn,
        .btn_div .add_btn {
          flex: 1;
          font-size: 11px;
          padding: 10px 10px;
          border-radius: 15px;
        }
      }
    }
    /* .main_div .subdiv img {
      width: 100%;
      height: 92%;
    } */

    .main_div .subdiv img {
      width: 100%;
      height: 340px;
      object-fit: unset;
    }
  }

  @media (min-width: 992px) and (max-width: 1024px) {
    .main_div {
      display: flex;
      justify-content: center;
      .subdiv {
        width: 24vw;
        max-height: 235px;
      }
    }

    .main_div .subdiv img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
  @media (min-width: 877px) {
    .drawer-toggle-button {
      display: none;
    }
    .drawer-content {
      display: block;
    }
  }
  @media (max-width: 567px) {
    .drawer-toggle-button {
      display: block;
    }
    .select_div .select_opt .select_metal .btn_row {
      display: flex;
      justify-content: center;
    }
    .drawer-content {
      .ring_types {
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;
        margin: 20px 0px 0px;
        width: 100%;
        justify-content: center;
        button {
          width: 85px;
          border: 1px solid #d1d1d1;
          background-color: rgba(247, 247, 247);
          padding: 0px 3px;
          &.selected {
            border: 2px solid black;
            border-radius: 10px;
          }
        }
      }
    }

    .heading {
      padding: 10px 0px;
      h2 {
        font-size: 18px;
      }
      p {
        font-size: 12px;
        line-height: 1rem;
      }
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    .drawer-toggle-button {
      display: none;
    }
  }
  @media (max-width: 987px) {
    .two_btn {
      button {
        height: 5vh;
        padding: 5px;
        &.selected {
          padding: 5px;
          border: 1px solid black;
          border-radius: 4px;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    .heading h3 {
      font-size: 18px;
      line-height: 20px;
    }
    .heading p {
      font-size: 12px;
    }
  }

  @media (max-width: 467px) {
    .container-fluid {
      margin: 0px;
    }
  }
`;

const StyledSection = styled.section`
  padding: 20px;
  @media (max-width: 567px) {
    padding: 0px;
  }
  .select_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 567px) {
      margin-top: 10px;
      gap: 30px;
      justify-content: space-around;
    }
    .select_opt {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .head_icon {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(247, 247, 247);
        border-radius: 0.375rem;
        padding: 10px 10px;
        border: 1px solid transparent;
        width: 150px;
        position: relative;
        h3 {
          font-size: 14px;
        }
      }

      .select_metal {
        position: absolute;
        left: 3%; /*tags*/
        bottom: -37%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 8px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 11px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 567px) {
          left: 7%;
          bottom: 15%;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        }

        /* @media (min-width: 567px) and (max-width: 1000px){
          left: 7%;
          bottom: 32%;
          border: 1px solid #fff;
          width: 51%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        } */

        @media (min-width: 567px) and (max-width: 1000px) {
          position: absolute;
          left: unset;
          bottom: unset;
          width: 55%;
          z-index: 11111;
          top: 50%;
        }
        /* @media (max-width: 1000px) {
          width: 90%;
          left: 3%;
          bottom: 6%;
        } */
      }

      /* .select_shape{
        position:absolute;
        left:6%; 
        bottom:-46%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 8px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 11px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 567px) {
          left: 7%;
          bottom: 0;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button{
            width:84px;
          }
        }

        @media  (min-width: 567px) and (max-width: 1000px) {
            position: absolute;
            left: unset;
            bottom: unset;
            width: 55%;
            z-index: 11111;
            top: 58%; 
        }
      } */
    }

    select {
      background-color: rgba(247, 247, 247);
      border-radius: 0.375rem;
      font-size: 14px;
      padding: 10px 10px;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
`;
