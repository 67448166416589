import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "react-modern-drawer/dist/index.css";
import Drawer from "react-modern-drawer";
import nopro from "../../../Images/product-not-found.jpg";
import { useDispatch, useSelector } from "react-redux";
import { IoFilterOutline } from "react-icons/io5";
import { setSelectedShape, setUniqueData } from "../../../redux/users/action";
import ROUND from "../../../Images/round-removebg-preview.png";
import EMERALD from "../../../Images/emerald-removebg-preview.png";
import HEART from "../../../Images/heart-removebg-preview.png";
import MARQUISE from "../../../Images/Marquise-removebg-preview.png";
import OVAL from "../../../Images/oval-removebg-preview.png";
import PEAR from "../../../Images/Pear-removebg-preview.png";
import PRINCESS from "../../../Images/Princess-removebg-preview.png";
import RADIANT from "../../../Images/Radiant-removebg-preview.png";
import CUSHION from "../../../Images/cushionremovebg.png";
import ECUSHION from "../../../Images/ECusion-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import WhiteGold14 from "../../../Images/fourone.png";
import YellowGold14 from "../../../Images/fourtwo.png";
import RoseGold14 from "../../../Images/fourthree.png";
import WhiteGold18 from "../../../Images/eightone.png";
import YellowGold18 from "../../../Images/eighttwo.png";
import RoseGold18 from "../../../Images/eightthree.png";
import Platinum from "../../../Images/pt.png";
import { EXCHANGE_URLS } from "../../URLS";
import { useLoading } from "../../LoadingContext";
import { IoIosArrowDown } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { NoProduct } from "../../NoProduct";
import Slider from "react-slick";
import { setUniqueProduct } from "../../../redux/users/action";

const shapesList = [
  { id: 1, name: "Round", label: "PREROUND", imgUrl: ROUND },
  { id: 2, name: "Emerald", label: "PREEMERALD", imgUrl: EMERALD },
  { id: 3, name: "Heart", label: "PREHEART", imgUrl: HEART },
  { id: 4, name: "Marquise", label: "PREMARQUISE", imgUrl: MARQUISE },
  { id: 5, name: "Oval", label: "PREOVAL", imgUrl: OVAL },
  { id: 6, name: "Pear", label: "PREPEAR", imgUrl: PEAR },
  { id: 7, name: "Princess", label: "PREPRINCESS", imgUrl: PRINCESS },
  { id: 8, name: "Radiant", label: "PRERADIANT", imgUrl: RADIANT },
  { id: 9, name: "Cushion", label: "PRECUSHION", imgUrl: CUSHION },
  { id: 10, name: "E.Cushion", label: "PREECUSION", imgUrl: ECUSHION },
];

const caratList = [
  { id: 1, type: "1" },
  { id: 2, type: "1.5" },
  { id: 3, type: "2" },
  { id: 4, type: "2.5" },
  { id: 5, type: "3" },
];

const materialList = [
  { id: 1, label: "White Gold", name: "PRE14KWHITE", imgUrl: WhiteGold14 },
  { id: 2, label: "Yellow Gold", name: "PRE14KYELLOW", imgUrl: YellowGold14 },
  { id: 3, label: "Rose Gold", name: "PRE14KROSE", imgUrl: RoseGold14 },
  { id: 4, label: "White Gold.", name: "PRE18KWHITE", imgUrl: WhiteGold18 },
  { id: 5, label: "Yellow Gold.", name: "PRE18KYELLOW", imgUrl: YellowGold18 },
  { id: 6, label: "Rose Gold.", name: "PRE18KROSE", imgUrl: RoseGold18 },
  { id: 7, label: "Platinum", name: "PREPLATINUM", imgUrl: Platinum },
];

export default function Section2() {
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tags, setTags] = useState([]);

  const selectedShape = useSelector((state) => state.users.selectedShape);
  const [selectedShapes, setSelectedShapes] = useState(
    shapesList[0].label || selectedShape
  );
  const [selectedOption, setSelectedOption] = useState(caratList[0].type);
  const [selectedDropButton, setSelectedDropButton] = useState(
    materialList[0].name
  );

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [shape, setShape] = useState(false);
  const { setLoading } = useLoading();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchDiamondData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${EXCHANGE_URLS}/tagdata?tags=${selectedShapes},${selectedDropButton},${selectedOption}`
      );
      if (response.status === 200) {
        setData(response.data.products);
        dispatch(setUniqueData(response?.data?.products));

        console.log("response", response.data.products);
      }
    } catch (error) {
      console.error("Error fetching collections:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const shape =
      selectedShape ||
      (selectedShapes.length > 0 ? selectedShapes[0] : "PREROUND");
    fetchDiamondData(shape);
  }, [
    selectedShape,
    selectedShapes,
    selectedDropButton,
    selectedOption,
    dispatch,
    setLoading,
  ]);

  const handleShapeClick = (shapeLabel, shapeName) => {
    setSelectedShapes([shapeLabel]);
    dispatch(setSelectedShape(shapeName));
    navigate("/uniquering", { state: { selectedShape: shapeLabel } });
  };
 

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function toggleShowName() {
    setShow((prevState) => !prevState);
  }

  const handleButtonDropClick = (
    buttonIndex,
    label,
    name,
    selectedDropButton
  ) => {
    setSelectedDropButton(buttonIndex, name);
    setTags([selectedDropButton, label]);
  };

  const handleButtonOption = (buttonIndex, type, selectedOption) => {
    setSelectedOption(buttonIndex, type);
    setTags([type, selectedOption]);
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  function toggleShapeName() {
    setShape((prevState) => !prevState);
  }

  const [visibleProducts, setVisibleProducts] = useState(20);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleUniqueRingDetail = (product) => {
    dispatch(setUniqueProduct(product));
    console.log("Navigating with product:", product);
    navigate("/uniqueringdetails", { state: { product } });
  };

  const handleLoadMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 20);
  };

  const drawerContent = (
    <>
      <div className="ring_types mt-4">
        {shapesList.map((shape) => (
          <button
            key={shape.label}
            className={`btn_shapes ${
              (selectedShape || selectedShapes).includes(shape.label)
                ? "selected"
                : ""
            }`}
            onClick={() => handleShapeClick(shape.label, shape.name)}
          >
            <img src={shape.imgUrl} alt={shape.label} />
            {shape.name}
          </button>
        ))}
      </div>
      <StyledSection>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="select_div">
                <div className="select_opt">
                  <div className="head_icon" onClick={toggleShowName}>
                    <h3>Select Metal</h3>
                    <IoIosArrowDown />
                  </div>

                  {show && (
                    <div className="select_metal">
                      <div className="first_row">
                        <div className="d-flex align-items-center">
                          <h5>Metal : {selectedDropButton}</h5>
                        </div>
                        <RxCross1
                          className="icon"
                          onClick={() => setShow(false)}
                        />
                      </div>

                      <div className="btn_row">
                        {materialList.map((metal) => (
                          <button
                            key={metal.name}
                            className={
                              selectedDropButton === metal.name
                                ? "selected"
                                : ""
                            }
                            onClick={() => handleButtonDropClick(metal.name)}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={metal.imgUrl}
                              alt={metal.label}
                            />
                            <h5>{metal.label}</h5>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="head_icon" onClick={toggleShapeName}>
                    <h3>Select Carat Weight</h3>
                    <IoIosArrowDown />
                  </div>

                  {shape && (
                    <div className="select_shape">
                      <div className="first_row">
                        <div className="d-flex align-items-center">
                          <h5>Carat Weight : {selectedOption}</h5>
                        </div>
                        <RxCross1
                          className="icon"
                          onClick={() => setShape(false)}
                        />
                      </div>

                      <div className="btn_row">
                        {caratList.map((carat) => (
                          <button
                            key={carat.type}
                            className={
                              selectedOption === carat.type ? "selected" : ""
                            }
                            onClick={() => handleButtonOption(carat.type)}
                          >
                            <h5>{carat.type}</h5>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                {/* <select>
                  <option value="low-high">Pricing (low-to-high)</option>
                  <option value="high-low">Pricing (high-to-low)</option>
                  <option value="best-selling">Best Selling</option>
                </select> */}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <TagsInput
                value={[selectedDropButton, selectedOption]}
                onChange={setTags}
              />
            </div>
          </div>
        </div>
      </StyledSection>
    </>
  );

  return (
    <Root>
      <button className="drawer-toggle-button" onClick={toggleDrawer}>
        <IoFilterOutline /> Filter
      </button>
      <div
        className={`drawer-content ${
          isOpen && screenWidth <= 567 ? "open" : ""
        }`}
      >
        {screenWidth > 567 ? (
          drawerContent
        ) : (
          <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction="bottom"
            className="bla"
          >
            {drawerContent}
          </Drawer>
        )}
      </div>

      {/* <Section4 data={data} /> */}
      <div className="sectionfour">
        <div className="main_div">
          {data && data?.length > 0 ? (
            data.slice(0, visibleProducts).map((i, index) => {
              return (
                <div key={index} className="subdiv">
                  <div className="slider-container">
                    <Slider {...settings}>
                      {i?.images?.edges?.map((edge, index) => (
                        <div key={index}>
                          {edge.node.originalSrc ? (
                            <img
                              src={edge.node.originalSrc}
                              alt={`img-${index}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://via.placeholder.com/283";
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                backgroundColor: "#ccc",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={nopro}
                                alt={`img-placeholder-${index}`}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div className="hov_content">
                    <div className="d-flex   flex-column">
                      <div className="d-flex flex justify-content-between">
                        <h5 className="prd_name">{i?.title}</h5>
                      </div>
                      <>
                        <p className="prd_price pt-1 pb-1">
                          {i?.priceRange?.minVariantPrice?.amount}{i?.priceRange?.minVariantPrice?.currencyCode}
                        </p>
                      </>
                    </div>

                    <div className="btn_div">
                      <button
                        className="info_btn"
                        onClick={() => handleUniqueRingDetail(i.id)}
                        style={{ fontSize: "10px" }}
                      >
                        More Info
                      </button>
                      <button
                        className="add_btn"
                        onClick={() => handleUniqueRingDetail(i.id)}
                      >
                        Add To Cart
                      </button>
                    </div>

                    <div className="note">
                      <p className="note">
                        Pay in 12 interest-free installments of $
                        <span>Learn more</span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ width: "100vw", height: "80vh" }}>
              <NoProduct />
            </div>
          )}
        </div>

        <div className="load_btn">
          {visibleProducts < data?.length && (
            <button onClick={handleLoadMore}>Load More</button>
          )}
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 0 0 20px;
  input.react-tagsinput-input {
    display: none;
  }

  .react-tagsinput {
    background-color: #fff;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
    border-right: none;
    border-left: none;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 20px;
    @media (max-width: 567px) {
      display: flex;
      justify-content: center;
    }
  }

  .react-tagsinput-tag {
    background-color: #f7f7f7;
    border-radius: 2px;
    border: 1px solid #f7f7f7;
    color: #212529;
    display: inline-block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 4px 15px;
  }

  .react-tagsinput-remove {
    cursor: pointer;
    margin-left: 20px;
    font-size: 25px;
    text-decoration: none;
    color: #999;
    font-weight: 400;
  }
  .column {
    border: 1px solid rgba(247, 247, 247);
    background-color: rgba(247, 247, 247);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-radius: 10px;
    h2 {
      font-size: 40px;
      font-weight: 400;
      margin-right: 10px;
    }
    span {
      color: rgba(128, 128, 128);
      font-size: 13px;
      font-weight: 600;
    }
    h6 {
      color: rgba(0, 0, 0);
      font-size: 18px;
      text-transform: uppercase;
    }
    img,
    svg {
      vertical-align: middle;
      width: 35px;
      height: 35px;
    }
    a {
      color: rgba(128, 128, 128);
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .heading {
    padding: 10px 24px;
    h2 {
      color: rgba(0, 0, 0);
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.75rem;
    }
  }

  .ring_types {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    button {
      width: 93px !important;
      border: 2px solid transparent;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;
      align-items: center;
      padding: 12px 0;
      font-size: 12px;
      line-height: 25px;
      font-weight: 550;
      &.selected {
        border: 2px solid black;
        border-radius: 10px;
      }

      &:hover {
        background-color: rgba(247, 247, 247);
      }

      img,
      svg {
        height: 50px;
        width: 62px;
      }
    }
  }

  .drawer-content {
    padding: 0px 20px 10px;
    width: 100%;
  }
  .drawer-toggle-button {
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
  }
  @media (min-width: 568px) {
    .drawer-toggle-button {
      display: none;
    }
    .drawer-content {
      display: block;
    }
  }
  .EZDrawer__container {
    overflow-y: scroll !important;
    height: 450px !important;
    border-top-right-radius: 25px !important;
    border-top-left-radius: 25px !important;
    padding-bottom: 40px;
  }
  @media (max-width: 567px) {
    .drawer-toggle-button {
      display: block;
      margin-left: 20px;
    }

    .select_div .select_opt .select_metal .btn_row {
      display: flex;
      justify-content: center;
    }

    .select_div .select_opt .select_shape .btn_row {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }

    .drawer-content {
      .ring_types {
        justify-content: center;
        gap: 4px;
        margin: 0;
        width: 100%;
        .btn_shapes.selected {
          border: 2px solid black;
          border-radius: 10px;
        }
        .btn_shapes {
          width: 85px !important;
          border: 1px solid #d1d1d1;
          background-color: rgba(247, 247, 247);
          padding: 12px 42px;
          &.selected {
            border: 2px solid black;
            border-radius: 10px;
          }
        }
      }
    }

    .ring_types button img,
    .ring_types button svg {
      height: 40px;
      width: 40px;
    }
  }
  .sectionfour {
    padding: 0 20px;

    .main_div {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      gap: 4px;
      margin-top: 20px;
      .subdiv {
        width: 24vw;
        height: 52vh;
        border: 3px solid #f7f7f7;
        border-radius: 20px;
        padding: 15px;
        overflow: hidden;
        position: relative;
        margin-bottom: 20px;

        /* slider changes */
        .slick-prev {
          left: -2px;
          z-index: 111;
        }

        .slick-next {
          right: -2px;
          z-index: 111;
        }

        /* slider changes */
        .slick-prev:before,
        .slick-next:before {
          background: rgb(185 179 179);
          border-radius: 50px;
        }
        img {
          width: 95%;
          height: 86%;
        }
        &:hover {
          border: 3px solid black;
          overflow: unset;
          transform: scale(1.1);
          z-index: 1;
        }
        .hov_content {
          display: flex;
          flex-wrap: wrap;
          .flex-column {
            display: flex;
            width: 100%;
          }
        }

        &:hover .hov_content {
          width: 24vw;
          z-index: 1;
          position: absolute;
          background-color: white;
          border: 3px solid black;
          padding: 0 20px 0;
          left: -3px;
          overflow: hidden;
          border-top: none;
          border-radius: 0 0 20px 20px;
          .prd_name {
            font-size: 14px;
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
          }
        }

        .prd_name {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .prd_price {
          font-size: 13px;
          width: 100%;
        }
        .white_color,
        .golden_color,
        .red_color {
          height: 18px;
          width: 18px;
          text-align: center;
          border-radius: 50px;
          margin-left: 10px;
        }

        .white_color {
          background-color: #ebebeb;
        }

        .golden_color {
          background-color: #ffdfb0;
        }

        .red_color {
          background-color: #efcbcb;
        }

        .btn_div {
          display: flex;
          justify-content: space-between;
          padding: 0;
          width: 100%;
          gap: 10px;
          .info_btn {
            flex: 1;
            padding: 12px 21px;
            border-radius: 25px;
            font-size: 13px;
            background-color: #fff;
            border: 2px solid black;
            cursor: pointer;
          }
          .add_btn {
            flex: 1;
            background-color: black;
            border: 2px solid black;
            color: white;
            padding: 5px 17px;
            border-radius: 25px;
            font-size: 13px;
            cursor: pointer;
          }
        }

        .note {
          p {
            font-size: 10px;
            margin-top: 20px;
            text-align: center;
            margin-top: 8px;
          }
          span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    .slick-slide img {
      width: 95%;
      height: 86%;
    }

    .slick-slider.slick-initialized {
      z-index: 0;
    }

    .load_btn {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      button {
        border: 2px solid black;
        background-color: transparent;
        font-size: 16px;
        color: #000000;
        font-weight: 500;
        padding: 10px 30px;
        border-radius: 50px;
      }
    }

    @media (max-width: 567px) {
      padding: 0px 10px;
      .main_div {
        display: flex;
        justify-content: center;
        .subdiv {
          width: 45vw;
          max-height: 188px;
          &:hover .hov_content {
            width: 45vw;
            padding: 0px 10px;
          }
          .prd_name {
            font-size: 12px;
            margin-bottom: 9px;
          }
          .prd_price {
            font-size: 11px;
            /* margin-bottom: 10px; */
          }
          .white_color,
          .golden_color,
          .red_color {
            height: 10px;
            width: 10px;
          }
          .btn_div .info_btn,
          .btn_div .add_btn {
            font-size: 11px;
            padding: 10px 10px;
            border-radius: 15px;
          }
        }
      }

      .main_div .subdiv img {
        width: 100%;
        height: 133px;
        object-fit: unset;
      }
    }

    @media (min-width: 567px) and (max-width: 992px) {
      padding: 0px 10px;
      .main_div {
        display: flex;
        justify-content: center;
        .subdiv {
          width: 45vw;
          max-height: 390px;
          &:hover .hov_content {
            width: 45vw;
            padding: 0px 10px;
          }
          .prd_name {
            font-size: 12px;
            margin-bottom: 9px;
          }
          .prd_price {
            font-size: 11px;
            margin-bottom: 10px;
          }
          .white_color,
          .golden_color,
          .red_color {
            height: 10px;
            width: 10px;
          }
          .btn_div .info_btn,
          .btn_div .add_btn {
            font-size: 11px;
            padding: 10px 10px;
            border-radius: 15px;
          }
        }
      }

      .main_div .subdiv img {
        width: 100%;
        height: 340px;
        object-fit: unset;
      }
    }

    @media (min-width: 992px) and (max-width: 1024px) {
      .main_div {
        display: flex;
        justify-content: center;
        .subdiv {
          width: 24vw;
          max-height: 210px;
        }
      }

      .main_div .subdiv img {
        width: 100%;
        height: 160px;
        object-fit: cover;
      }
    }
  }
`;

const StyledSection = styled.section`
  padding: 20px;
  @media (max-width: 567px) {
    padding: 0px;
  }
  .select_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 567px) {
      margin-top: 10px;
      gap: 30px;
      justify-content: space-around;
    }
    .select_opt {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .head_icon {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(247, 247, 247);
        border-radius: 0.375rem;
        padding: 10px 10px;
        border: 1px solid transparent;
        width: 190px;
        position: relative;
        h3 {
          font-size: 14px;
        }
        @media (max-width: 567px) {
          width: 155px;
        }
      }

      .select_metal {
        position: absolute;
        left: 3%; /*tags*/
        bottom: -20%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 8px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 11px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 567px) {
          left: 7%;
          bottom: 15%;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        }

        /* @media (min-width: 567px) and (max-width: 1000px){
          left: 7%;
          bottom: 32%;
          border: 1px solid #fff;
          width: 51%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }
        } */
        @media (min-width: 567px) and (max-width: 1000px) {
          position: absolute;
          left: unset;
          bottom: unset;
          width: 55%;
          z-index: 11111;
          top: 58%;
        }
        /* @media (max-width: 1000px) {
          width: 90%;
          left: 3%;
          bottom: 6%;
        } */
      }

      .select_shape {
        position: absolute;
        left: 6%; /*tags*/
        bottom: -15%;
        border: 1px solid #fff;
        box-shadow: 1px 3px 25px 1px #cbced0;
        width: 32%;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        z-index: 11111;

        .first_row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h5 {
            font-size: 15px;
            font-weight: 700;
            padding-top: 8px;
          }
          span {
            color: rgba(102, 102, 102);
            font-size: 15px;
            font-weight: 600;
            margin-left: 5px;
          }
          .icon {
            cursor: pointer;
          }
        }

        .btn_row {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;
          button {
            border-radius: 10px;
            padding: 25px;
            background-color: #fff;
            border: 1px solid rgba(221, 211, 211);
            width: 87px;
            cursor: pointer;
            &.selected {
              border: 2px solid black;
            }
            &:hover {
              background-color: rgba(245, 245, 245);
            }
            svg {
              height: 50px;
              cursor: pointer;
            }

            h5 {
              color: rgb(46 44 44);
              font-size: 14px;
              margin: 0;
            }
            span {
              font-size: 11px;
            }
          }
        }
        @media (max-width: 567px) {
          left: 7%;
          bottom: 22%;
          border: 1px solid #fff;
          width: 85%;
          padding: 10px;
          .btn_row button {
            width: 84px;
          }

          /* .select_div .select_opt .head_icon {
            width: 160px;
          } */
        }

        @media (min-width: 567px) and (max-width: 1000px) {
          position: absolute;
          left: unset;
          bottom: unset;
          width: 55%;
          z-index: 11111;
          top: 58%;
        }
      }
    }

    select {
      background-color: rgba(247, 247, 247);
      border-radius: 0.375rem;
      font-size: 14px;
      padding: 10px 10px;
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
`;
