import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components";
// import { Card } from "react-bootstrap";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

export default function ShippingInfo(){
    return (
        <Root>
            <div className="container " id="shipping-info">
                <Section1 />
                <div className="d-flex flex-column gap-3 gap-md-4">
                    <Section2 />
                    <Section3 />
                    <Section4 />
                    <Section5 />
                </div>
            </div>
        </Root>
    );
}

const Root = styled.section`
  background-color: #f8f9fa;
  padding: 5rem 0 8rem;
  
 
  .container{
  max-width:60rem;
width:90%;  
}
 `;
