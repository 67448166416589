import React from "react";
import styled from "styled-components";

export default function Section4() {
  return (
    <Root>
        <div id="conflict-free-policy" className="d-flex flex-column gap-8 pb-5 pt-5 border-b border-borders">
          <h2>Conflict Free Diamonds</h2>
          <div>
            <p>At The Precious Earth, we are committed to stopping the conflict diamond trade. Together with our vendors, we work to the standards laid out in the Kimberley Process and US law.</p>
          </div>
          <div  className="d-flex flex-column gap-8">
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Our Policy on Diamonds</h3>
              <p>We make sure that all the diamonds we buy and use are certified under the Kimberley Process. So far, we have had no cases of conflict diamonds being used in our jewelry. If such a case were to happen, we would immediately stop collaborating with the vendor and look to pursue legal action.</p>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Our Policy on Gold</h3>
              <p>We work with certified, ethical mines and gold resellers. We do not do business with organizations that do not meet our social, environmental, and human rights standards. Where possible, we always strive to use recycled gold.</p>
            </div>
            <div className="d-flex flex-column gap-8">
              <h3 className="fs-6 font-weight-semibold">Our Screening Process</h3>
              <span>
              <p>We screen our suppliers to make sure they offer no conflict diamonds or dirty gold. We do this by looking at their past purchase and sale histories, as well as carrying out our own primary research.</p>
              <p>If you want to let us know about a diamond or metal seller who works with unethical suppliers, 
                <a href="#">please use this form</a> immediately.</p>
                </span>
            </div>
          </div>
        </div>
    </Root>
  )
}

const Root = styled.section`
  font-family: ProximaNova, sans-serif;
  background-color: #f8f9fa;

  h2 {
    font-size: 36px;
    line-height: 1.375;
    font-weight: 500;
    color: black;
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: black;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  p {
    color: black;
    font-size: 14px;
    font-weight:500;
  }
    p,h2,h3{
     margin:0;
    }

  .border-b {
    border-bottom: 1px solid #ddd;
  }

  .border-borders {
    border-color: #ddd;
  }
      .gap-8{
    gap:2rem;
    }
`;
